<template>
  <v-container grid-list-md text-xs-center px-0>
    <v-layout row wrap>
      <v-flex xs12 sm3>
        <v-text-field
          class="mt-2"
          small
          dense
          outline
          single-line
          v-model="rh"
          label="Prontuário para entrada"
          append-icon="fas fa-plus"
          @click:append="receivedByRh()"
        ></v-text-field>
      </v-flex>
      <v-flex xs12 sm4 offset-sm1>
        <v-text-field
          v-model.lazy="search"
          append-icon="search"
          label="Buscar"
          single-line
          hide-details
        ></v-text-field>
      </v-flex>
      <v-flex xs12 sm3 offset-sm1>
        <v-text-field
          class="mt-2"
          small
          dense
          outline
          single-line
          v-model="rhauto"
          label="Prontuário com leitor de código"
        ></v-text-field>
      </v-flex>

      <v-flex xs12 sm3>
        <v-text-field class="mt-2" box small dense single-line v-model="box" label="Caixa"></v-text-field>
      </v-flex>
    </v-layout>
    <v-layout row wrap>
      <v-flex xs12>
        <v-toolbar flat color="white" class="elevation-1">
          <v-toolbar-title>Newspace</v-toolbar-title>
          <v-divider class="mx-2" inset vertical></v-divider>
        </v-toolbar>

        <v-data-table
          :headers="columns"
          :items="items"
          :pagination.sync="pagination"
          :total-items="pagination.totalItems"
          :loading="loading"
          hide-actions
          class="elevation-1"
        >
          <template slot="items" slot-scope="props">
            <tr>
              <td>{{ props.item.user && props.item.user.name }}</td>
              <td>{{ props.item.horary | formatDateTimeShort}}</td>
              <td>{{ props.item.patient && props.item.patient.name }}</td>
              <td>{{ props.item.patient && props.item.patient.rh }}</td>
              <td>{{ props.item.patient && props.item.patient.death ? 'SIM' : ''}}</td>
              <td>{{ props.item.patient && props.item.patient.same_local }}</td>
              <td>{{ props.item.patient && props.item.patient.same }}</td>
              <td class="text-xs-center px-0">
                <v-icon small @click="openRevert(props.item)">delete</v-icon>
              </td>
            </tr>
          </template>

          <template slot="no-data">Desculpe, nenhum dado para ser exibido!</template>
        </v-data-table>
        <div class="text-xs-center pt-2">
          <v-pagination v-model="pagination.page" :length="pages"></v-pagination>
        </div>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import _ from "lodash";
import moment from "moment";
export default {
  data() {
    return {
      valid: false,
      search: "",
      box: null,
      pagination: {
        totalItems: 0,
        rowsPerPage: 10,
        descending: false,
        page: 1,
        sortBy: "id"
      },
      items: [],
      columns: [
        { value: "user", text: "Solicitado", sortable: false },
        { value: "horary", text: "Data e Hora" },
        { value: "patient.name", text: "Paciente", sortable: false },
        { value: "patient.rh", text: "Prontuário", sortable: false },
        { value: "patient.death", text: "Óbito", sortable: false },
        { value: "patient.same_local", text: "Arquivo", sortable: false },
        { value: "patient.same", text: "Atualmente", sortable: false },
        { text: "", value: "", sortable: false }
      ],
      menu1: false,
      loading: false,
      rh: "",
      rhauto: ""
    };
  },
  computed: {
    pages() {
      if (
        this.pagination.rowsPerPage == null ||
        this.pagination.totalItems == null
      )
        return 0;

      return Math.ceil(
        this.pagination.totalItems / this.pagination.rowsPerPage
      );
    }
  },
  watch: {
    "pagination.page": {
      handler() {
        this.getList();
      },
      deep: true
    },
    search: function() {
      this.debounceInput();
    },
    rhauto: function(n, o) {
      this.debounceInputRh();
    }
  },
  created() {
    this.initialize();
  },
  methods: {
    initialize() {},
    getList() {
      this.loading = true;
      return new Promise((resolve, reject) => {
        const params = {
          ...this.pagination,
          totalItems: undefined,
          search: this.search,
          newspace: 1
        };
        this.$http
          .get(`/same/list`, { params })
          .then(response => response.data)
          .then(json => {
            setTimeout(() => {
              this.loading = false;

              this.items = json.items;
              this.pagination.totalItems = json.total;
            }, 1000);
          })
          .catch(() => (this.loading = false));
      });
    },
    async receivedByRh() {
      if (!this.rh && !this.rhauto) return;
      if (!this.box) {
        this.$notify({
          title: "Preencha o campo Caixa",
          group: "notifications",
          type: "error"
        });
        return;
      }
      try {
        let rh = this.rh;
        if (this.rhauto) rh = this.rhauto;
        const json = await this.$http
          .put(`/same/donebyrh/${rh}`, {
            newspace: true,
            box: this.box
          })
          .then(r => r.data);
        this.$notify({
          title: `${json.name}, salvo com sucesso!`,
          type: "success",
          group: "notifications",
          duration: 15000
        });
        this.rh = null;
        this.rhauto = "";
        this.getList();
      } catch (err) {
        let msg = `Não foi encontrado este Prontuário`;
        if (err.response && err.response.data && err.response.data.message)
          msg = err.response.data.message;
        this.$notify({
          title: msg,
          group: "notifications",
          type: "error"
        });
      }
    },
    openRevert(item) {
      if (confirm(`Deseja realmente reverter?`)) {
        this.$http.put(`/same/${item.id}/revert`, item).then(response => {
          this.$notify({
            title: `${item.patient.name}, salvo com sucesso!`,
            type: "success",
            group: "notifications",
            duration: 15000
          });
          this.getList();
        });
      }
    },
    debounceInputRh: _.debounce(function() {
      setTimeout(() => {
        if (this.rhauto.length > 0) {
          this.receivedByRh();
        }
      }, 200);
    }, 500),
    debounceInput: _.debounce(function() {
      setTimeout(() => {
        this.getList();
      }, 200);
    }, 400)
  }
};
</script>
