<template>
  <v-container grid-list-md text-xs-center px-0>
    <v-layout row wrap>
      <v-flex xs12 sm6>
        <v-toolbar flat color="white" class="elevation-1">
          <v-toolbar-title>Relatório Radiologia</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-menu
            v-model="menuFilter.menu"
            bottom
            left
            :close-on-content-click="false"
            max-width="400"
            min-width="400"
            offset-x
          >
            <v-btn outline slot="activator" color="primary">OPÇÕES</v-btn>

            <v-card>
              <v-card-title class="px-1 py-1">
                OPÇÕES
                <v-spacer></v-spacer>
                <v-btn
                  outline
                  light
                  small
                  color="primary"
                  @click="menuFilter.menu = true; filtro = {};"
                >Limpar</v-btn>
              </v-card-title>

              <v-layout row wrap>
                <v-flex xs12 class="px-1">
                  <v-text-field v-model="filtro.start" type="date" label="De"></v-text-field>
                </v-flex>
                <v-flex xs12 class="px-1">
                  <v-text-field v-model="filtro.end" type="date" label="Até"></v-text-field>
                </v-flex>
                <!--<v-flex xs12 class="px-1">
                  <v-select
                    v-model="filtro.used"
                    :items="[{value: null, text: 'Selecionar'}, {value: 1, text: 'SIM'}, {value: 0, text: 'NÃO'}]"
                    label="Feito procedimento"
                  ></v-select>
                </v-flex>-->
              </v-layout>

              <v-card-actions>
                <v-spacer></v-spacer>

                <v-btn flat @click="menuFilter.menu = false">Fechar</v-btn>
                <v-btn color="primary" @click="filtrar()">FILTRAR</v-btn>
              </v-card-actions>
            </v-card>
          </v-menu>
        </v-toolbar>

        <v-data-table
          :headers="columns"
          :items="items"
          :loading="loading"
          hide-actions
          class="elevation-1"
        >
          <template slot="items" slot-scope="props">
            <td>{{ props.item.type }}</td>
            <td>{{ props.item.qty }}</td>
          </template>

          <template slot="no-data">Desculpe, nenhum dado para ser exibido!</template>
        </v-data-table>
        <div class="mt-2 text-xs-left">
          <b>TOTAL {{total}}</b>
        </div>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import moment from "moment";
export default {
  data() {
    return {
      valid: false,
      user: USER,
      items: [],
      total: 0,
      columns: [
        { value: "type", text: "Tipo" },
        { value: "qty", text: "Quantidade" }
      ],
      loading: false,
      menuFilter: { menu: false },
      filtro: {
        start: moment()
          .startOf("month")
          .format("YYYY-MM-DD"),
        end: moment()
          .endOf("month")
          .format("YYYY-MM-DD"),
        used: null
      }
    };
  },
  created() {
    this.initialize();
  },
  methods: {
    initialize() {
      this.getList();
    },
    getList() {
      this.loading = true;
      const params = { ...this.filtro };
      this.$http
        .get(`/exams/reports_radiology_types`, { params })
        .then(response => response.data)
        .then(json => {
          setTimeout(() => {
            this.loading = false;

            this.items = json.items;
            this.total = json.total;
          }, 1000);
        })
        .catch(() => (this.loading = false));
    },
    goExternal(url) {
      var win = window.open(url, "_blank");
      win.focus();
    },
    filtrar() {
      this.menuFilter.menu = false;
      this.getList();
    }
  }
};
</script>
