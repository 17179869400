<template>
  <div>
    <v-layout
      row
      wrap
    >
      <v-flex xs12>
        <h2>
          <v-btn
            fab
            dark
            small
            color="primary"
            class="no-print"
            @click="$router.go(-1)"
          >
            <v-icon dark>fas fa-arrow-left</v-icon>
          </v-btn>

          Relátorio {{typeDisplay}} -
          <small v-if="formatDate(range.start) == formatDate(range.end)">{{formatDate(range.start)}}</small>
          <small v-else>{{formatDate(range.start)}} até {{formatDate(range.end)}}</small>
        </h2>
        <v-layout
          row
          wrap
        >
          <v-flex xs12>
            <span v-if="filtro.provider_id">
              <b>Fornecedor: </b> {{providers.find(o => o.value == filtro.provider_id).text}}
            </span>
            <span v-if="filtro.bill_type_id">
              <b>Tipo: </b> {{billTypes.find(o => o.value == filtro.bill_type_id).name}}
            </span>
          </v-flex>
        </v-layout>
        <v-layout
          row
          wrap
          class="no-print"
          align-center
          justify-center
        >
          <v-flex xs4>
            {{formatDate(range.start)}} - {{formatDate(range.end)}}
            <v-icon
              class="mr-2"
              @click="range.modal = true"
            >edit</v-icon>
          </v-flex>

          <v-flex
            xs8
            class="text-xs-right"
          >
            <v-menu
              v-model="menuFilter.menu"
              bottom
              left
              :close-on-content-click="false"
              max-width="400"
              min-width="400"
              offset-x
            >
              <v-btn
                slot="activator"
                color="primary"
              > OPÇÕES </v-btn>

              <v-card>
                <v-card-title class="px-1 py-1">FILTRO
                  <v-spacer></v-spacer>
                  <v-btn
                    outline
                    light
                    small
                    color="primary"
                    @click="menuFilter.menu = true; filtro = {};"
                  >Limpar</v-btn>
                </v-card-title>

                <v-layout
                  row
                  wrap
                >
                  <v-flex
                    xs12
                    class="px-1"
                  >
                    <v-autocomplete
                      v-model="filtro.contract_id"
                      :items="contracts"
                      label="Contrato"
                    ></v-autocomplete>
                  </v-flex>
                  <v-flex
                    xs12
                    class="px-1"
                  >
                    <v-autocomplete
                      v-model="filtro.provider_id"
                      :items="providers"
                      label="Fornecedor"
                    ></v-autocomplete>
                  </v-flex>

                  <v-flex
                    xs12
                    class="px-1"
                  >
                    <v-autocomplete
                      v-model="filtro.bill_type_id"
                      :items="billTypes"
                      label="Tipo"
                    ></v-autocomplete>
                  </v-flex>

                  <v-flex
                    xs12
                    class="px-1"
                  >
                    <v-autocomplete
                      v-model="filtro.bank_id"
                      :items="banks"
                      label="Banco"
                    ></v-autocomplete>
                  </v-flex>

                  <v-flex
                    xs12
                    class="px-1"
                  >
                    <v-select
                      v-model="filtro.paid"
                      :items="[{value: null, text: 'Todos'}, {value: 1, text: 'Pago'}, {value: 0, text: 'Pendente'}]"
                      label="Status"
                    ></v-select>
                  </v-flex>
                </v-layout>

                <v-card-actions>
                  <v-spacer></v-spacer>

                  <v-btn
                    flat
                    @click="menuFilter.menu = false"
                  >Fechar</v-btn>
                  <v-btn
                    color="primary"
                    flat
                    @click="filtrar()"
                  >Filtrar</v-btn>
                </v-card-actions>
              </v-card>
            </v-menu>
          </v-flex>
        </v-layout>

        <v-data-table
          :headers="columns"
          :items="items"
          :loading="loading"
          hide-actions
          disable-initial-sort
          class="elevation-1"
        >
          <template
            slot="items"
            slot-scope="props"
          >
            <tr>
              <td>{{ formatDate(props.item.issue_date)}}</td>
              <td>{{ formatDate(props.item.due_date)}}</td>
              <td>
                {{ props.item.provider && props.item.provider.name }}
              </td>
              <td>{{ props.item.description }}</td>
              <td>{{ props.item.bill_type ? props.item.bill_type.code + ' - ' + props.item.bill_type.name : '' }}</td>
              <td>{{ props.item.price | formatMoney}}</td>
              <td>
                {{ props.item.paid ? 'Pago em ' + formatDate(props.item.paid_date) : 'Pendente' }}
              </td>
            </tr>
          </template>

          <template slot="no-data">Desculpe, nenhum dado para ser exibido!</template>
        </v-data-table>
      </v-flex>
    </v-layout>
    <v-layout
      row
      wrap
      v-if="items.length"
      class="py-2 px-2"
    >
      <v-flex xs6>
        <b>TOTAL</b>
      </v-flex>

      <v-flex
        xs6
        class="text-xs-right"
      >
        <b>{{total | formatMoney}}</b>
      </v-flex>
    </v-layout>

    <v-dialog
      v-model="range.modal"
      scrollable
      max-width="350px"
    >
      <v-card>
        <v-card-title>
          <span class="headline">Período</span>
        </v-card-title>
        <v-card-text>
          <v-layout
            row
            wrap
          >
            <v-flex xs12>
              <v-menu
                ref="menuStart"
                :close-on-content-click="false"
                v-model="range.menuStart"
                :nudge-right="40"
                :return-value.sync="range.start"
                lazy
                transition="scale-transition"
                offset-y
                full-width
                min-width="290px"
              >
                <v-text-field
                  slot="activator"
                  v-model="computedStart"
                  label="Data inicial"
                  prepend-icon="event"
                  readonly
                ></v-text-field>
                <v-date-picker
                  v-model="range.start"
                  no-title
                  scrollable
                  locale="pt-br"
                >
                  <v-spacer></v-spacer>
                  <v-btn
                    flat
                    color="grey"
                    @click="range.menuStart = false"
                  >Fechar</v-btn>
                  <v-btn
                    color="primary"
                    @click="$refs.menuStart.save(range.start)"
                  >OK</v-btn>
                </v-date-picker>
              </v-menu>

              <v-menu
                ref="menuEnd"
                :close-on-content-click="false"
                v-model="range.menuEnd"
                :nudge-right="40"
                :return-value.sync="range.end"
                lazy
                transition="scale-transition"
                offset-y
                full-width
                min-width="290px"
              >
                <v-text-field
                  slot="activator"
                  v-model="computedEnd"
                  label="Data Final"
                  prepend-icon="event"
                  readonly
                ></v-text-field>
                <v-date-picker
                  v-model="range.end"
                  no-title
                  scrollable
                  locale="pt-br"
                >
                  <v-spacer></v-spacer>
                  <v-btn
                    flat
                    color="grey"
                    @click="range.menuEnd = false"
                  >Fechar</v-btn>
                  <v-btn
                    color="primary"
                    @click="$refs.menuEnd.save(range.end)"
                  >OK</v-btn>
                </v-date-picker>
              </v-menu>
            </v-flex>
          </v-layout>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="grey"
            flat
            @click="range.modal = false"
          >Fechar</v-btn>
          <v-btn
            color="primary"
            @click="range.modal = false; getList()"
          >OK</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import _ from "lodash";
import moment from "moment";
export default {
  data () {
    return {
      type: this.$route.params.type,
      menuFilter: { menu: false },
      range: {
        start: moment().startOf('month').format('YYYY-MM-DD'),
        end: moment().endOf('month').format('YYYY-MM-DD'),
        modal: false
      },
      items: [],
      total: 0,
      columns: [
        { value: "issue_date", text: "Data Emissão" },
        { value: "due_date", text: "Vencimento" },
        {
          value: "provider_id",
          text: "Fornecedor",
          sortable: false
        },
        { value: "description", text: "Descrição" },
        { value: "type", text: "Tipo" },
        { value: "price", text: "Valor" },
        { value: "status", text: "Status" }
      ],
      filtro: {
        provider_id: null,
        bill_type_id: null,
        bank_id: null,
        contract_id: null,
      },
      providers: [],
      billTypes: [],
      banks: [],
      contracts: [],
      loading: false
    };
  },
  computed: {
    computedStart () {
      return this.formatDate(this.range.start);
    },
    computedEnd () {
      return this.formatDate(this.range.end);
    },
    typeDisplay () {
      return this.type + "s";
    }
  },
  watch: {
    dialog (val) {
      val || this.close();
    }
  },
  created () {
    this.initialize();
  },
  methods: {
    initialize () {
      this.getList();
      this.getProviders();
      this.getBillTypes();
      this.getBanks();
      this.getContracts();
    },
    getList () {
      this.loading = true;
      return new Promise((resolve, reject) => {
        const params = {
          ...this.range,
          type: this.$route.params.type,
          ...this.filtro,
          modal: undefined,
          menuStart: undefined
        };
        this.$http
          .get(`/bills/list`, { params })
          .then(response => response.data)
          .then(json => {
            setTimeout(() => {
              this.loading = false;

              this.items = json.items;
              this.total = 0;

              this.items.forEach(item => {
                this.total += parseFloat(item.price);
              });

            }, 1000);
          })
          .catch(() => (this.loading = false));
      });
    },
    getProviders () {
      this.$http.get(`/providers/list?sortBy=name`)
        .then(response => response.data)
        .then(json => {
          if (json && json.items) {
            const arr = [];
            json.items.forEach(obj => arr.push({ text: obj.name, value: obj.id }));
            this.providers = arr;
          }
        });
    },
    getBillTypes () {
      const t = this.type == "Receita" ? 2 : 1;
      this.$http.get(`/bill_types/list?type=${t}`)
        .then(response => response.data)
        .then(json => {
          if (json && json.items) {
            const arr = [];
            json.items.forEach(obj => arr.push({
              text: this.getTree(obj.code) + obj.code + ' - ' + obj.name,
              name: obj.code + ' - ' + obj.name,
              id: obj.id,
              value: obj.code
            }));
            this.billTypes = arr;
          }
        });
    },
    getBanks () {
      this.$http.get(`/banks/list`)
        .then(response => response.data)
        .then(json => {
          if (json && json.items) {
            const arr = [];
            json.items.forEach(obj => arr.push({ text: obj.name, value: obj.id }));
            this.banks = arr;
          }
        });
    },
    getContracts () {
      this.$http
        .get(`/contracts/list`)
        .then(response => response.data)
        .then(json => {
          if (json && json.items) {
            const arr = [];
            json.items.forEach(obj =>
              arr.push({ text: obj.name + ' Ex. ' + obj.exercise, value: obj.id })
            );
            this.contracts = arr;
          }
        });
    },
    getTree (code) {
      if (code.length < 2) { return ''; }
      return '└─';
    },
    filtrar () {
      this.menuFilter.menu = false;
      this.getList();
    },
    formatDate: date => (date ? moment(date).format("DD/MM/YYYY") : ""),
    dateNow: () => moment().format("YYYY-MM-DD"),
    dataDiff: date => moment(date).diff(moment(), "days")
  }
};
</script>


<style>
.application {
  background: #ffffff !important;
}
</style>

<style scoped>
.application {
  background: #ffffff !important;
}

table.v-table tbody td,
table.v-table tbody th {
  height: 35px;
}
table.v-table thead tr {
  height: 40px;
}
</style>
