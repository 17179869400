<template>
  <v-container grid-list-md text-xs-center px-0>
    <v-layout row wrap>
      <v-flex xs12>
        <v-toolbar flat color="white" class="elevation-1 mb-4">
          <v-toolbar-title>Relatório de Consumo</v-toolbar-title>
          <v-divider class="mx-2" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <v-text-field
            v-model.lazy="search"
            append-icon="search"
            label="Buscar"
            single-line
            hide-details
            class="hidden-md-and-down"
            @click:append="getList()"
              v-on:keyup.enter="getList"
          ></v-text-field>
          <v-spacer></v-spacer>
          <v-menu
            v-model="menuFilter.menu"
            bottom
            left
            :close-on-content-click="false"
            max-width="400"
            min-width="400"
            offset-x
          >
            <v-btn slot="activator" color="primary" outline>OPÇÕES</v-btn>

            <v-card>
              <v-card-title class="px-1 py-1">
                FILTRO
                <v-spacer></v-spacer>

                <v-btn
                  outline
                  light
                  small
                  color="primary"
                  @click="menuFilter.menu = true; filtro = {};"
                >Limpar</v-btn>
              </v-card-title>

              <v-layout row wrap>
                <v-flex xs12 class="px-1">
                  <v-checkbox v-model="horary" label="Horário"></v-checkbox>
                </v-flex>
                <v-flex xs12 class="px-1">
                  <v-text-field
                    v-model="filtro.start"
                    :type="horary ? 'datetime-local' : 'date'"
                    label="De"
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 class="px-1">
                  <v-text-field
                    v-model="filtro.end"
                    :type="horary ? 'datetime-local' : 'date'"
                    label="Até"
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 class="px-1">
                  <v-autocomplete
                    label="Setor de destino"
                    v-model="filtro.sector_destiny"
                    :items="sector_destinys"
                  ></v-autocomplete>
                </v-flex>
                <v-flex xs12 class="px-1">
                  <select-custom
                    :url="'/categories/list?sortBy=name&office=' + $route.params.type"
                    v-model="filtro.category_id"
                    label="Categoria"
                  />
                </v-flex>

                <v-flex xs12 class="px-1">
                  <select-custom
                    :url="'/subcategories/list?sortBy=name&office=' + $route.params.type"
                    v-model="filtro.subcategory_id"
                    label="SubCategoria"
                  />
                </v-flex>

                <v-flex xs12 class="px-1">
                  <select-custom
                    :url="`/sectors/listall?sortBy=name&office=${$route.params.office}`"
                    v-model="filtro.sector_id"
                    label="Estoque"
                  />
                </v-flex>
              </v-layout>

              <v-card-actions>
                <v-spacer></v-spacer>

                <v-btn flat @click="menuFilter.menu = false">Fechar</v-btn>
                <v-btn color="primary" flat @click="filtrar()">Filtrar</v-btn>
              </v-card-actions>
            </v-card>
          </v-menu>

          <v-btn dark color="primary" @click="goReport()">RELATÓRIO</v-btn>
        </v-toolbar>

        <v-data-table
          :headers="columns"
          :items="itemsFilter"
          :loading="loading"
          hide-actions
          disable-initial-sort
          class="elevation-1"
        >
          <template slot="items" slot-scope="props">
            <tr>
              <td>{{ props.item.code}}</td>
              <td>{{ props.item.name}}</td>
              <td>{{ props.item.measurement}}</td>
              <td></td>
              <td>{{ props.item.used}}</td>
              <td>{{ props.item.used_estoque}}</td>
              <td>{{ props.item.used_cc}}</td>
              <td>{{ props.item.used_ps}}</td>
              <td>{{ props.item.average_price | formatMoney}}</td>
              <td>{{ props.item.total_price | formatMoney}}</td>
            </tr>
          </template>

          <template slot="no-data">Desculpe, nenhum dado para ser exibido!</template>
        </v-data-table>
      </v-flex>
      <v-flex xs12 class="text-xs-right">
        <h4>Valor total {{total() | formatMoney}}</h4>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import moment from "moment";
import _ from "lodash";
export default {
  data() {
    return {
      search: "",
      items: [],
      horary: false,
      itemsFilter: [],
      menuFilter: { menu: false },
      filtro: {
        start: moment().startOf("month").format("YYYY-MM-DD"),
        end: moment().endOf("month").format("YYYY-MM-DD"),
        category_id: null,
        subcategory_id: null,
        sector_destiny: null,
        sector_id: null,
      },
      columns: [
        { value: "code", text: "Código" },
        { value: "name", text: "Descrição" },
        { value: "measurement", text: "Unidade" },
        { value: "", text: "QTD Requisitada", sortable: false },
        { value: "used", text: "QTD Entregue" },
        { value: "used_estoque", text: "QTD Estoque" },
        { value: "used_cc", text: "QTD Farmácia CC" },
        { value: "used_ps", text: "QTD Farmácia PS" },
        { value: "average_price", text: "Valor unitário" },
        { value: "total_price", text: "Valor total do produto" },
      ],
      sector_destinys: [],
      loading: false,
    };
  },
  watch: {
    "$route.params.type": {
      handler() {
        this.getList();
      },
    },
  },
  created() {
    this.initialize();
  },
  methods: {
    initialize() {
      this.getList();
      this.getSectorDestinys();
    },
    getList() {
      this.loading = true;
      return new Promise((resolve, reject) => {
        const params = {
          ...this.filtro,
        };
        this.$http
          .get(`/retiradas/new_report_consumo/${this.$route.params.type}`, {
            params,
          })
          .then((response) => response.data)
          .then((json) => {
            setTimeout(() => {
              this.loading = false;
              const aux = json.items.map((o) => {
                return { ...o, total_price: o.average_price * o.used };
              });
              this.items = aux;
              this.itemsFilter = aux;
              //console.log(this.items);
            }, 1000);
          })
          .catch(() => (this.loading = false));
      });
    },
    getSectorDestinys() {
      this.$http
        .get(`/destinies/list_names`)
        .then((response) => response.data)
        .then((json) => {
          this.sector_destinys = json;
        });
    },
    goExternal(url) {
      var win = window.open(url, "_blank");
      win.focus();
    },
    filtrar() {
      this.menuFilter.menu = false;
      this.getList();
    },
    normalized(string) {
      string = string + "";
      string = string.trim().toLowerCase();
      const map = {
        a: /[\xE0-\xE6]/g,
        A: /[\xC0-\xC6]/g,
        e: /[\xE8-\xEB]/g,
        E: /[\xC8-\xCB]/g,
        i: /[\xEC-\xEF]/g,
        I: /[\xCC-\xCF]/g,
        o: /[\xF2-\xF6]/g,
        O: /[\xD2-\xD6]/g,
        u: /[\xF9-\xFC]/g,
        U: /[\xD9-\xDC]/g,
        c: /\xE7/g,
        C: /\xC7/g,
        n: /\xF1/g,
        N: /\xD1/g,
      };

      for (const key in map) {
        if (map.hasOwnProperty(key)) {
          string = string.replace(map[key], map);
        }
      }

      return string;
    },
    total() {
      return _.sumBy(this.itemsFilter, function (o) {
        return o.total_price;
      });
    },
    goReport() {
      this.menuFilter.menu = false;
      let category_id = "";
      let subcategory_id = "";
      let sector_destiny = "";
      let sector_id = "";
      if (this.filtro.category_id != null)
        category_id = `&category_id=${this.filtro.category_id}`;
      if (this.filtro.subcategory_id)
        subcategory_id = `&subcategory_id=${this.filtro.subcategory_id}`;
      if (this.filtro.sector_destiny)
        sector_destiny = `&sector_destiny=${this.filtro.sector_destiny}`;
      if (this.filtro.sector_id != null)
        sector_id = `&sector_id=${this.filtro.sector_id}`;

      let url = `/retiradas/new_report_consumo/${this.$route.params.type}?print=1&start=${this.filtro.start}
        &end=${this.filtro.end}${category_id}${subcategory_id}${sector_destiny}${sector_id}`;
      window.open(url, "_blank");
    },
    debounceInput: _.debounce(function () {
      setTimeout(() => {
        const q = this.normalized(this.search);
        this.itemsFilter = this.items.filter((item) => {
          return this.normalized(item.name).match(q);
        });
      }, 200);
    }, 400),
  },
};
</script>
