<template>
  <v-container grid-list-md text-xs-center px-0>
    <v-layout row wrap>
      <v-flex xs12>
        <v-card>
          <v-card-title>
            FILTRO
          </v-card-title>

          <v-card-text class="pt-0">
            <v-container grid-list-md px-0 py-0>
              <v-layout row wrap>
                <v-flex xs12 sm4 class="px-1">
                  <v-text-field v-model="filtro.start" type="date" label="De"></v-text-field>
                </v-flex>
                <v-flex xs12 sm4 class="px-1">
                  <v-text-field v-model="filtro.end" type="date" label="Até"></v-text-field>
                </v-flex>
                <!--<v-flex xs12 sm4 class="px-1">
                  <select-custom
                    :url="'/products/list?sortBy=name&office=' + $route.params.office"
                    v-model="filtro.product_id"
                    label="Produto"
                  />
                </v-flex>-->
                <v-flex xs12 sm4 class="px-1">
                  <select-custom
                    :url="`/sectors/listall?sortBy=name&office=${$route.params.office}`"
                    v-model="filtro.sector_id"
                    label="Estoque"
                  />
                </v-flex>

                <v-flex xs12 sm4 class="px-1">
                  <v-select
                    v-model="filtro.operatiton"
                    label="Operação"
                    :items="[{text: 'TODAS', value: null},'ENTRADA', 'SAIDA']"
                  />
                </v-flex>
              </v-layout>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn color="primary" flat @click="filtrar()">Filtrar</v-btn>
          </v-card-actions>
        </v-card>
      </v-flex>
      <v-flex xs12>
        <v-toolbar flat color="white" class="elevation-1 mb-4">
          <v-toolbar-title>Relatório de movimentação de estoque por item</v-toolbar-title>
          <v-divider class="mx-2" inset vertical></v-divider>
          <span v-if="item">{{item.code}} - {{item.name}}</span>

          <v-spacer></v-spacer>

          <v-btn dark color="primary" @click="goReport()">RELATÓRIO</v-btn>
        </v-toolbar>

        <v-data-table
          :headers="columns"
          :items="items"
          :loading="loading"
          hide-actions
          disable-initial-sort
          class="elevation-1"
        >
          <template slot="items" slot-scope="props">
            <tr>
              <td>{{ props.item.user && props.item.user.name}}</td>
              <td>{{ props.item.created_at | formatDate}}</td>
              <td>{{ props.item.product && props.item.product.code}}</td>
              <td>{{ props.item.product && props.item.product.name}}</td>
              <td>{{ props.item.product && props.item.product.measurement}}</td>
              <td>
                <b v-if="props.item.type == 'SALDO'">{{props.item.type}}</b>
                <span v-else>{{props.item.type}}</span>
              </td>
              <td>{{props.item.qty}}</td>
              <td>{{props.item.movement}}</td>
              <td>{{ props.item.sector && props.item.sector.name}}</td>
              <td>{{props.item.id}}</td>
            </tr>
          </template>

          <template slot="no-data">Desculpe, nenhum dado para ser exibido!</template>
        </v-data-table>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import moment from "moment";
import _ from "lodash";
export default {
  data() {
    return {
      search: "",
      item: null,
      items: [],
      menuFilter: { menu: false },
      filtro: {
        start: moment().startOf("month").format("YYYY-MM-DD"),
        end: moment().endOf("month").format("YYYY-MM-DD"),
        product_id: this.$route.params.product_id,
        operatiton: null,
        sector_id: null,
      },
      columns: [
        { value: "user", text: "OPERADOR", sortable: false },
        { value: "created_at", text: "DATA", sortable: false },
        { value: "product.code", text: "CÓDIGO", sortable: false },
        { value: "product.name", text: "DESCRIÇÃO", sortable: false },
        { value: "product.measurement", text: "UND", sortable: false },
        { value: "type", text: "TIPO", sortable: false },
        { value: "qty", text: "QUANT.", sortable: false },
        { value: "movement", text: "MOVIMENTAÇÃO", sortable: false },
        { value: "sector.name", text: "SUB-ESTOQUE", sortable: false },
        { value: "id", text: "ID", sortable: false },
      ],
      loading: false,
    };
  },
  watch: {
    search: function () {
      this.debounceInput();
    },
  },
  created() {
    this.initialize();
  },
  methods: {
    initialize() {
      this.getItem();
    },
    getItem() {
      if (!this.filtro.product_id) return;
      return new Promise((resolve, reject) => {
        this.$http
          .get(`/products/show/${this.filtro.product_id}`)
          .then((response) => response.data)
          .then((json) => {
            setTimeout(() => {
              this.item = json.product;
            }, 1000);
          })
          .catch(() => (this.loading = false));
      });
    },
    getList() {
      if (!this.filtro.product_id) return;
      this.loading = true;
      return new Promise((resolve, reject) => {
        const params = {
          ...this.filtro,
        };
        this.$http
          .get(`/single_products/report_movement/${this.filtro.product_id}`, {
            params,
          })
          .then((response) => response.data)
          .then((json) => {
            setTimeout(() => {
              this.loading = false;
              this.items = json.items;
            }, 1000);
          })
          .catch(() => (this.loading = false));
      });
    },
    goExternal(url) {
      var win = window.open(url, "_blank");
      win.focus();
    },
    filtrar() {
      this.menuFilter.menu = false;
      this.getList();
    },
    goReport() {
      if (!this.filtro.product_id) return;
      this.menuFilter.menu = false;
      let sector_id = "";
      let operatiton = "";
      if (this.filtro.sector_id != null)
        sector_id = `&sector_id=${this.filtro.sector_id}`;
      if (this.filtro.operatiton)
        operatiton = `&operatiton=${this.filtro.operatiton}`;

      let url = `/single_products/report_movement/${this.filtro.product_id}?print=1&start=${this.filtro.start}
        &end=${this.filtro.end}${sector_id}${operatiton}`;
      window.open(url, "_blank");
    },
    debounceInput: _.debounce(function () {
      setTimeout(() => {
        const q = this.normalized(this.search);
        this.itemsFilter = this.items.filter((item) => {
          return this.normalized(item.name).match(q);
        });
      }, 200);
    }, 400),
  },
};
</script>
