<template>
  <v-container grid-list-md px-0>
    <!--<v-layout row wrap class="no-print">
        <v-flex xs12 sm4>
          <v-menu
            v-model="menuReport.menu"
            bottom
            right
            :close-on-content-click="false"
            max-width="400"
            min-width="400"
            offset-x
          >
            <v-btn outline slot="activator" color="primary">Relatórios</v-btn>

            <v-card>
              <v-card-title class="px-1 py-1">RELATÓRIO</v-card-title>

              <v-layout row wrap>
                <v-flex xs12 class="px-1">
                  <v-text-field v-model="menuReport.start" type="date" label="De"></v-text-field>
                </v-flex>
                <v-flex xs12 class="px-1">
                  <v-text-field v-model="menuReport.end" type="date" label="Até"></v-text-field>
                </v-flex>
                <v-flex xs12 class="px-1">
                  <v-select
                    v-model="menuReport.type"
                    :items="['TODOS','ALTAS','OBITOS']"
                    label="Tipo"
                  ></v-select>
                </v-flex>
              </v-layout>

              <v-card-actions>
                <v-spacer></v-spacer>

                <v-btn class="mr-1" flat @click="menuReport.menu = false">Fechar</v-btn>
                <v-btn class="mr-1" color="blue" flat @click="goReport('reports')">PLANILHA</v-btn>
                <v-btn color="primary" @click="goReport('reports')">IMPRIMIR</v-btn>
              </v-card-actions>
            </v-card>
          </v-menu>
        </v-flex>
    </v-layout>-->
    <v-layout row wrap>
      <v-flex xs12>
        <v-toolbar flat color="white" class="elevation-1">
          <v-toolbar-title>Recepção de Visitas</v-toolbar-title>
          <v-divider class="mx-2" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <v-text-field
            v-model.lazy="search"
            append-icon="search"
            label="Buscar"
            single-line
            hide-details
            class="hidden-sm-and-down no-print"
            @click:append="getList()"
              v-on:keyup.enter="getList"
          ></v-text-field>
          <v-spacer></v-spacer>
          <!--<v-btn color="primary" dark class="mb-2 no-print" @click="openVisitationForm({})">Novo</v-btn>-->
        </v-toolbar>

        <v-data-table
          :headers="columns"
          :items="items"
          :pagination.sync="pagination"
          :total-items="pagination.totalItems"
          :loading="loading"
          hide-actions
          class="elevation-1"
        >
          <template slot="items" slot-scope="props">
            <tr>
              <td>{{ props.item.created_at | formatDateTimeShort }}</td>
              <td>{{ props.item.patient && props.item.patient.name}}</td>
              <td>{{ props.item.patient && props.item.patient.rh}}</td>
              <td>{{ props.item.name}}</td>
              <td>{{ props.item.rg}}</td>
              <td>{{ props.item.clinic && props.item.clinic.name }}</td>
              <td>{{ props.item.bed && props.item.bed.code }}</td>
              <td>{{ props.item.user && props.item.user.name }}</td>
              <td class="text-xs-center">
                <v-menu offset-y>
                  <template v-slot:activator="{ on }">
                    <v-btn icon v-on="on">
                      <v-icon small>fas fa-ellipsis-v</v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-tile v-if="!props.item.done" @click="openVisitationDone(props.item)">
                      <v-list-tile-avatar>
                        <v-icon>fas fa-check</v-icon>
                      </v-list-tile-avatar>
                      <v-list-tile-title>Finalizar Visita</v-list-tile-title>
                    </v-list-tile>

                    <v-list-tile
                      v-if="!props.item.done || user.type == 1"
                      @click="openVisitationForm(props.item)"
                    >
                      <v-list-tile-avatar>
                        <v-icon>edit</v-icon>
                      </v-list-tile-avatar>
                      <v-list-tile-title>Editar</v-list-tile-title>
                    </v-list-tile>
                  </v-list>
                </v-menu>
              </td>
            </tr>
          </template>

          <template slot="no-data">Desculpe, nenhum dado para ser exibido!</template>
        </v-data-table>
        <div class="text-xs-center pt-2">
          <v-pagination v-model="pagination.page" :length="pages"></v-pagination>
        </div>
      </v-flex>
    </v-layout>

    <div v-if="visitation.open">
      <visitation-form
        :active="visitation.open"
        :item="visitation.item"
        :finish="onCloseVisitationForm"
      />
    </div>
  </v-container>
</template>
<script lang="js">
import dayjs from 'dayjs';
export default {
  data() {
    return {
      visitation: { open: false },
      loading: false,
      search: "",
      user: USER,
      pagination: {
        totalItems: 0,
        rowsPerPage: 50,
        descending: false,
        page: 1,
        sortBy: "id"
      },
      items: [],
      columns: [
        { value: "created_at", text: "Data e Hora", sortable: false },
        { value: "patient.name", text: "Paciente", sortable: false },
        { value: "patient.rh", text: "Prontuário", sortable: false },
        { value: "name", text: "Visitante", sortable: false },
        { value: "rg", text: "Vis. RG", sortable: false },
        { value: "clinic", text: "Clinica", sortable: false },
        { value: "bed", text: "Leito", sortable: false },
        { value: "user", text: "Operador", sortable: false },
        { text: "Opções", value: "", sortable: false }
      ],
      filtro: {
        done: false
      },
      menuReport: { menu: false },
    };
  },
  watch: {
    "pagination.page": {
      handler() {
        this.getList();
      },
      deep: true
    },
    "filtro.done":  {
      handler(n, o) {
        this.getList();
      },
      deep: true
    },
  },
  computed: {
    pages() {
      if (
        this.pagination.rowsPerPage == null ||
        this.pagination.totalItems == null
      )
        return 0;
      return Math.ceil(
        this.pagination.totalItems / this.pagination.rowsPerPage
      );
    }
  },
  created() {
    this.initialize();
  },
  methods: {
    initialize() {
      //this.getList();
    },
    getList() {
      this.loading = true;
      return new Promise((resolve, reject) => {
        const params = {
          ...this.pagination,
          totalItems: undefined,
          ...this.filtro,
          search: this.search,
          done: 0
        };
        this.$http
          .get(`/visitations/list`, { params })
          .then(response => response.data)
          .then(json => {
            setTimeout(() => {
              this.loading = false;

              this.items = json.items;
              this.pagination.totalItems = json.total;
            }, 1000);
          })
          .catch(() => (this.loading = false));
      });
    },
    openVisitationForm(item) {
      this.visitation.open = true;
      this.visitation.item = item;
    },
    onCloseVisitationForm() {
      this.visitation.open = false;
      this.getList();
    },
    openVisitationDone(item) {
      if (confirm("Deseja realmente encerrar está visita?")) {
        this.$http
          .put(`/visitations/${item.id}/done`, {})
          .then(response => {
            this.$notify({
              title: `salvo com sucesso!`,
              group: "notifications",
              type: "success"
            });
            this.getList();
          })
          .catch(err => {
            this.$reqError(`Erro ao salvar!`, err, this);
          });
      }
    },
    goExternal(url) {
      var win = window.open(url, "_blank");
      win.focus();
    },
    goReport(action) {
      if(!this.menuReport.start || !this.menuReport.end || !this.menuReport.type) return;
      this.menuReport.menu = false;
      let url = `/visitations/${action}/?start=${this.menuReport.start}&end=${this.menuReport.end}&type=${this.menuReport.type}`;
      window.open(url, "_blank");
    },
    debounceInput: _.debounce(function() {
      setTimeout(() => {
        this.getList();
      }, 200);
    }, 400),
  }
};
</script>
<style scoped>
table.v-table tbody td,
table.v-table tbody th {
  height: 30px;
}
table.v-table thead tr {
  height: 35px;
}

table td {
  font-size: 12px !important;
}
</style>

