<template>
<v-layout row wrap>
  <v-flex xs8 align-center>
    <v-layout align-center justify-center row fill-height>

      <div class="text-xs-center">
        <!-- <h1 class="display-4" v-if="item.id != 3">SENHA</h1> -->
        <h1 class="display-4">PACIENTE</h1>
        <!-- v-if="item.id == 3" -->
        <h1 class="display-4 font-weight-bold" style="font-size: 250px !important;" v-if="item.id != 3">
          {{item.number}}
        </h1>
        <h1 class="display-4 font-weight-bold" style="font-size: 100px !important;" v-if="item.id == 3">
          {{ item.call.patient.name }}
        </h1>
        <h1 class="display-4 font-weight-bold">{{item.place}}</h1>

        <div style="display: none;">
          <audio id="myAudio">  
            <source src="/sounds/ATone.mp3" type="audio/mpeg">
            Your browser does not support the audio element.
          </audio>
        </div>
      </div>
    </v-layout>
  </v-flex>
  <v-flex xs4>
    <div style="height: 100vh; overflow: auto;" class="white lighten-3" >
      <v-toolbar dark>
        <v-spacer></v-spacer>
        <v-toolbar-title class="display-1">ÚLTIMAS SENHAS</v-toolbar-title>
        <v-spacer></v-spacer>
      </v-toolbar>

      <v-layout row wrap class="pt-3">
        <v-flex
          xs12
          class="text-xs-center py-2"
          v-for="(item, i) in lastItems"
          :key="i"
        >
          <span class="display-1" style="font-size: 50px !important;" v-if="item.id != 3"><b>{{item.number}}</b> - {{item.place}}</span>
          <span class="display-1" style="font-size: 30px !important;" v-if="item.id == 3"><b>{{item.number}}</b> - {{item.place}}</span>
        </v-flex>
      </v-layout>
    </div>
  </v-flex>
</v-layout>
</template>

<script>
import io from 'socket.io-client';
export default {

  data() {
		return {
      item: {},
      lastItems: [

      ]
		}
	},
	created () {
		this.initialize();
	},
	methods: {
		initialize () {
      this.getItem();

      this.listenTime()

      console.log('iniciando o painel')

      let port = ":4747";
      let host = window.location.hostname;

        // if (host == "hmca.hoomweb.com") port = ":4949";
        if (host == "127.0.0.1") host = "localhost";

      const socket = io(host + port);
      socket.on('error', (data) => {
        console.log('error socket', data);
      });

      // socket.emit('teste', 'Ola, vamos começar a brincadeira')

      socket.on('connect_failed', (data) => {
        console.log('connect_failed', data);
      });

      socket.on('connect', () => {
        console.log('connect');
      });

      socket.on('update', (data) => {
        console.log('retorno do socket.io', data);
        console.log('dados vindos via Socket', data);
        if(this.item.type == data.type){
          this.getItem(data);
        }
      });
      
      socket.on('teste', data => {
        console.log('recebido no painel de senha', data)
      })

     
		},
    listenTime() {
      setInterval(() => {

        const now = new Date()
        console.log(now.getHours(), now.getMinutes(), now.getSeconds())

        if (now.getHours() == 0 && now.getMinutes() == 0 && now.getSeconds() == 0) {
          console.log('zerar painel de senha')
          this.lastItems = []
          this.item = {}
        }

      }, 1000)
    },
		getItem() {
			this.loading = true
			return new Promise((resolve, reject) => {

        console.log('parametros da rota', this.$route.params)

				this.$http.get(`/passwords/${this.$route.params.id}`)
				// this.$http.get(`/passwords/${data.id}`)
				.then(response => response.data).then(json => {
          console.log('JSON', json)
          if(this.item.number && json.number != this.item.number){
            this.lastItems.unshift({number: this.item.call.patient.name, place: this.item.place});
          }

          // conta quantos tem, se tiver mais de 3 remove o ultimo
          if (this.lastItems.length > 3) {
            this.lastItems.slice(-1)
          }

          this.item = json;
          this.voice();
				}).catch(() => this.loading = false);
			});
    },
    voice() {
      var x = document.getElementById("myAudio");
      if(!x) return;
      x.play();
      setTimeout(() => {
        if ('speechSynthesis' in window) {
          try {
            /*speechSynthesis.onvoiceschanged = function() {
              speechSynthesis.getVoices().forEach(function(voice, index) {
                  console.log(voice);
              });
            }*/

            var msg = new SpeechSynthesisUtterance();
            //var voices = window.speechSynthesis.getVoices();
            //msg.voice = voices[5];
            //msg.rate = $('#rate').val() / 10;
            msg.text = `PACIENTE, ${this.item.call.patient.name}, ${this.item.place}`;
            msg.onend = function(e) {console.log('Finished');};

            speechSynthesis.speak(msg);
          } catch (e) {
            console.log(e);
          }
        }
      }, 1000);
    }
	}
}
</script>
