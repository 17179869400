<template>
<div>
    <v-container grid-list-md text-xs-center px-0>
		<notifications position="bottom center" />
		<v-layout row wrap>
			<v-flex xs12>
				<v-toolbar flat color="white" class="elevation-1">
					<v-toolbar-title>Internados</v-toolbar-title>
					<v-divider class="mx-2" inset vertical ></v-divider>
					<v-spacer></v-spacer>
					<v-btn color="primary" dark class="mb-2" @click="openHospitalization({})">Novo</v-btn>
				</v-toolbar>

				<v-data-table
					:headers="columns"
					:items="items"
      		:pagination.sync="pagination"
					:total-items="pagination.totalItems"
      		:loading="loading"
					hide-actions
					class="elevation-1"
				>
					<template slot="items" slot-scope="props">
						<tr>
							<td>{{ props.item.id }}</td>
							<td>{{ props.item.patient &&  props.item.patient.name}}</td>
							<td>{{ props.item.register }}</td>
							<td class="text-xs-center px-0">
								<v-tooltip top>
									<v-icon
										slot="activator"
										small
										class="mr-2"
										@click="openHospitalizationDetails(props.item)"
									>
										fas fa-notes-medical
									</v-icon>
									<span>Prescrição, Hipótese, Evolução</span>
								</v-tooltip>
								<v-tooltip top>
									<v-icon
										slot="activator"
										small
										class="mr-2"
										@click="doneHospitalization(props.item)"
									>
										fas fa-check
									</v-icon>
									<span>Finalizar Internação</span>
								</v-tooltip>

								<v-icon small @click="openHospitalization(props.item)" >
									edit
								</v-icon>
							</td>
						</tr>
					</template>

					<template slot="no-data">
						Desculpe, nenhum dado para ser exibido!
					</template>
				</v-data-table>
				<div class="text-xs-center pt-2">
					<v-pagination v-model="pagination.page" :length="pages"></v-pagination>
				</div>

			</v-flex>
		</v-layout>

		<div v-if="declarePrint.open">
			<declare-print :active="declarePrint.open" :item="declarePrint.item" :finish="onCloseDeclarePrint"></declare-print>
		</div>

		<div v-if="hospitalization.open">
			<hospitalization :active="hospitalization.open" :item="hospitalization.item" :finish="onCloseHospitalization"></hospitalization>
		</div>

		<div v-if="hospitalizationDetails.open">
			<hospitalization-details :active="hospitalizationDetails.open" :item_id="hospitalizationDetails.item.id" :finish="onCloseHospitalizationDetails"></hospitalization-details>
		</div>
    </v-container>
</div>
</template>

<script>
import moment from 'moment';
export default {
  data() {
		return {
			declarePrint: {open: false},
			hospitalization: {open: false},
			hospitalizationDetails: {open: false},
			loading: false,
      		pagination: {
				totalItems: 0,
				rowsPerPage: 30,
  			descending: false,
  			page: 1,
  			sortBy: 'id'
			},
			items: [],
			columns: [
				{ value: 'id', text: 'ID', sortable: false },
				{ value: 'name', text: 'Nome'},
				{ value: 'register', text: 'Registro'},
				{ text: 'Opções', value: '', sortable: false }
			],
		}
	},
	watch: {
		"pagination.page": {
			handler () {
				this.getList();
			},
			deep: true
		}
	},
	computed: {
		pages () {
			if (this.pagination.rowsPerPage == null || this.pagination.totalItems == null) return 0;
			return Math.ceil(this.pagination.totalItems / this.pagination.rowsPerPage)
		}
	},
	created () {
		this.initialize();
	},
	methods: {
		initialize () {
			//this.getList();
		},
		getList() {
			this.loading = true
			return new Promise((resolve, reject) => {
				const params = {
					...this.pagination,
					totalItems: undefined
				};
				this.$http.get(`/hospitalizations/list`, {params})
				.then(response => response.data).then(json => {
					setTimeout(() => {
            this.loading = false;

						this.items = json.items;
						this.pagination.totalItems = json.total;
          }, 1000);
				}).catch(() => this.loading = false);
			});
		},
		formatDate: date => moment(date).format('DD/MM/YYYY'),
		openDeclarePrint (item) {
			window.scrollTo(0, 0);
			this.declarePrint.open = true;
			this.declarePrint.item = item;
		},
		onCloseDeclarePrint () {
			this.declarePrint.open = false;
		},
		openHospitalization (item) {
			this.hospitalization.open = true;
			this.hospitalization.item = item;
		},
		onCloseHospitalization () {
			this.hospitalization.open = false;
			this.getList();
		},
		openHospitalizationDetails (item) {
			this.hospitalizationDetails.open = true;
			this.hospitalizationDetails.item = item;
		},
		onCloseHospitalizationDetails () {
			this.hospitalizationDetails.open = false;
			this.getList();
		},
		formatHorary (date) {
			if(date) {
				return moment(date).format('HH:mm')
			} else return "";
		},
		doneHospitalization(item){
			if (confirm('Deseja realmente finalizar esta internação?')) {
				item.done = true;
				this.$http.put(`/hospitalizations/${item.id}`, item)
				.then(response => {
					this.$notify({
						title: `Internação finalizada com sucesso!`,
						type: 'success',
					});
					this.getList();
				});
			}
		}
	}
}
</script>
