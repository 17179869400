<template>
  <div>
    <!--<header-page />-->
    <v-container grid-list-md text-xs-center px-0>
      <v-layout row wrap>
        <v-flex xs12>
          <v-toolbar flat color="white" class="elevation-1">
            <v-toolbar-title>Plantão</v-toolbar-title>
            <v-divider class="mx-2" inset vertical></v-divider>
            <v-spacer></v-spacer>
            <v-btn color="primary" dark class="mb-2" @click="openWorkShiftForm({})">Novo</v-btn>
          </v-toolbar>

          <v-data-table
            :headers="columns"
            :items="items"
            :pagination.sync="pagination"
            :total-items="pagination.totalItems"
            :loading="loading"
            hide-actions
            class="elevation-1"
          >
            <template slot="items" slot-scope="props">
              <tr>
                <td>{{ props.item.id }}</td>
                <td>{{ props.item.date | formatDate }}</td>
                <td>{{ props.item.shift }}</td>
                <td>{{ props.item.doctors_count }}</td>
                <td class="text-xs-center px-0">
                  <v-icon class="mr-2" small @click="goPrint(props.item.id)">fas fa-print</v-icon>
                  <v-icon class="mr-2" small @click="workShiftDoctorsOpen(props.item)">far fa-list-alt</v-icon>
                  <v-icon small @click="openWorkShiftForm(props.item)">edit</v-icon>
                </td>
              </tr>
            </template>

            <template slot="no-data">Desculpe, nenhum dado para ser exibido!</template>
          </v-data-table>
          <div class="text-xs-center pt-2">
            <v-pagination v-model="pagination.page" :length="pages"></v-pagination>
          </div>
        </v-flex>
      </v-layout>

      <div v-if="workShiftFormActive.open">
        <work-shift-form
          :active="workShiftFormActive.open"
          :item="workShiftFormActive.item"
          :finish="onCloseWorkShiftForm"
        />
      </div>

      <div v-if="workShiftDoctorsActive.open">
        <work-shift-doctors
          :active="workShiftDoctorsActive.open"
          :item="workShiftDoctorsActive.item"
          :finish="workShiftDoctorsClose"
        />
      </div>

    </v-container>
  </div>
</template>

<script>
import _ from "lodash";
export default {
  data() {
    return {
      valid: false,
      search: "",
      pagination: {
        totalItems: 0,
        rowsPerPage: 10,
        descending: false,
        page: 1,
        sortBy: "id"
      },
      items: [],
      columns: [
        { value: "id", text: "ID" },
        { value: "date", text: "Data" },
        { value: "shift", text: "Turno" },
        { value: "doctors_count", text: "QTD Médicos", sortable: false },
        { text: "", value: "", sortable: false }
      ],
      workShiftFormActive: {open: false, item: {}},
      workShiftDoctorsActive: {open: false, item: {}},
      loading: false
    };
  },
  computed: {
    pages() {
      if (
        this.pagination.rowsPerPage == null ||
        this.pagination.totalItems == null
      )
        return 0;

      return Math.ceil(
        this.pagination.totalItems / this.pagination.rowsPerPage
      );
    }
  },
  watch: {
    "pagination.page": {
      handler() {
        this.getList();
      },
      deep: true
    },
  },
  created() {
    this.initialize();
  },
  methods: {
    initialize() {},
    getList() {
      this.loading = true;
      return new Promise((resolve, reject) => {
        const params = {
          ...this.pagination,
          totalItems: undefined,
          search: this.search,
          office: this.$route.params.office
        };
        this.$http
          .get(`/work_shifts/list`, { params })
          .then(response => response.data)
          .then(json => {
            setTimeout(() => {
              this.loading = false;

              this.items = json.items;
              this.pagination.totalItems = json.total;
            }, 1000);
          })
          .catch(() => (this.loading = false));
      });
    },
    del(id) {
      if (confirm("Deseja realmente excluir esta Plantão?")) {
        this.$http.delete(`/work_shifts/${id}`).then(response => {
          this.$notify({
            title: `Plantão Excluido!`,
            group: "notifications",
            type: "success"
          });
          this.getList();
        });
      }
    },
    debounceInput: _.debounce(function() {
      setTimeout(() => {
        this.getList();
      }, 200);
    }, 400),
    openWorkShiftForm(item) {
      this.workShiftFormActive.open = true;
      this.workShiftFormActive.item = item;
    },
    onCloseWorkShiftForm(category) {
      this.workShiftFormActive.open = false;
      this.getList();
    },
    workShiftDoctorsOpen(item) {
      this.workShiftDoctorsActive.open = true;
      this.workShiftDoctorsActive.item = item;
    },
    workShiftDoctorsClose(category) {
      this.workShiftDoctorsActive.open = false;
      this.getList();
    },
    goPrint(id) {
      window.open(`/work_shifts/${id}/print`);
    }
  }
};
</script>
