<template>
  <v-container grid-list-md text-xs-center px-0>
    <v-layout row wrap>
      <v-flex xs12>
        <v-toolbar flat color="white" class="elevation-1">
          <v-toolbar-title>
            <v-btn icon flat small color="primary" dark class="mb-2" @click="getList()">
              <v-icon small>fas fa-sync</v-icon>
            </v-btn>Lista de Leitos
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-select
            :items="clinics"
            v-model="clinic_id"
            label="Clinica"
            single-line
            hide-details
            class="hidden-md-and-down"
          />
          <v-spacer></v-spacer>
        </v-toolbar>

        <v-data-table
          :headers="columns"
          :items="items"
          :loading="loading"
          hide-actions
          class="elevation-1"
        >
          <template slot="items" slot-scope="props">
            <tr
              :class="{
								'red lighten-1': getStatus(props.item) == 'Aguardando Alta',
								'green lighten-1': getStatus(props.item) == 'Disponível',
                'orange lighten-1': getStatus(props.item) == 'Ocupado',
							}"
            >
              <td>{{ props.item.code }}</td>
              <td>{{ props.item.clinic && props.item.clinic.name}}</td>
              <td>
                <span
                  v-if="props.item.hospitalization && props.item.hospitalization.patient"
                >{{props.item.hospitalization.patient.name}}</span>
              </td>
              <td>{{ getStatus(props.item)}}</td>
            </tr>
          </template>

          <template slot="no-data">Desculpe, nenhum dado para ser exibido!</template>
        </v-data-table>
      </v-flex>
    </v-layout>

    <dialog-loader :active="loading" />
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      items: [],
      columns: [
        { value: "name", text: "Nome" },
        { value: "clinic.name", text: "Clínica" },
        { value: "hospitalization.patient", text: "Paciente", sortable: false },
        { value: "status", text: "Status", sortable: false },
      ],
      clinic_id: this.$route.query.clinic_id
        ? parseInt(this.$route.query.clinic_id)
        : null,
      clinics: [],
    };
  },
  watch: {
    clinic_id: function () {
      this.getItems();
    },
  },
  created() {
    this.initialize();
  },
  methods: {
    initialize() {
      this.getListClinics();
      this.getItems(true);
    },
    getItems(first) {
      this.loading = true;
      let params = { clinic_id: this.clinic_id };
      if (!first) this.$router.push({ query: params });
      this.$http
        .get(`/beds/list`, { params })
        .then((response) => response.data)
        .then((json) => (this.items = json.items))
        .finally(() => (this.loading = false));
    },
    getListClinics() {
      this.$http
        .get(`/clinics/list`)
        .then((response) => response.data)
        .then((json) => {
          if (json && json.items) {
            this.clinics = json.items.map((o) => {
              return { ...o, text: o.name, value: o.id };
            });

            this.clinics.unshift({ text: "TODAS CLÍNICAS", value: null });
          }
        });
    },
    getStatus(item) {
      const { hospitalization } = item;
      if (!hospitalization) return "Disponível";

      if (hospitalization.medical_release) return "Aguardando Alta";

      return "Ocupado";
    },
  },
};
</script>
