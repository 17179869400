<template>
  <v-container fluid text-xs-center px-0>
    <v-layout row wrap>
      <v-flex xs12>
        <v-toolbar flat color="white" class="elevation-1" dense>
          <v-toolbar-title>Atendimentos - Relatório</v-toolbar-title>
          <v-divider class="mx-2" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <div>
            {{formatDate(range.start)}} - {{formatDate(range.end)}}
            <v-icon class="mr-2" @click="range.modal = true">edit</v-icon>
          </div>
        </v-toolbar>
      </v-flex>

      <v-flex xs12>
        <v-data-table
          :headers="columns"
          :items="items"
          :loading="loading"
          hide-actions
          class="elevation-1"
          dense
          sort-by="name"
        >
          <template slot="items" slot-scope="props">
            <tr>
              <td>{{ props.item.name }}</td>
              <td>{{ props.item.qty}}</td>
            </tr>
          </template>

          <template slot="no-data">Desculpe, nenhum dado para ser exibido!</template>
        </v-data-table>
      </v-flex>
    </v-layout>

    <v-dialog v-model="range.modal" scrollable max-width="350px">
      <v-card>
        <v-card-title>
          <span class="headline">Período</span>
        </v-card-title>
        <v-card-text>
          <v-layout row wrap>
            <v-flex xs12>
              <v-menu
                ref="menuStart"
                :close-on-content-click="false"
                v-model="range.menuStart"
                :nudge-right="40"
                :return-value.sync="range.start"
                lazy
                transition="scale-transition"
                offset-y
                full-width
                min-width="290px"
              >
                <v-text-field
                  slot="activator"
                  v-model="computedStart"
                  label="Data inicial"
                  prepend-icon="event"
                  readonly
                ></v-text-field>
                <v-date-picker v-model="range.start" no-title scrollable locale="pt-br">
                  <v-spacer></v-spacer>
                  <v-btn flat color="grey" @click="range.menuStart = false">Fechar</v-btn>
                  <v-btn color="primary" @click="$refs.menuStart.save(range.start)">OK</v-btn>
                </v-date-picker>
              </v-menu>

              <v-menu
                ref="menuEnd"
                :close-on-content-click="false"
                v-model="range.menuEnd"
                :nudge-right="40"
                :return-value.sync="range.end"
                lazy
                transition="scale-transition"
                offset-y
                full-width
                min-width="290px"
              >
                <v-text-field
                  slot="activator"
                  v-model="computedEnd"
                  label="Data Final"
                  prepend-icon="event"
                  readonly
                ></v-text-field>
                <v-date-picker v-model="range.end" no-title scrollable locale="pt-br">
                  <v-spacer></v-spacer>
                  <v-btn flat color="grey" @click="range.menuEnd = false">Fechar</v-btn>
                  <v-btn color="primary" @click="$refs.menuEnd.save(range.end)">OK</v-btn>
                </v-date-picker>
              </v-menu>
            </v-flex>
          </v-layout>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="grey" flat @click="range.modal = false">Fechar</v-btn>
          <v-btn color="primary" @click="range.modal = false; getList()">OK</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import _ from "lodash";
import moment from "moment";
export default {
  data() {
    return {
      valid: false,
      search: "",
      items: [],
      range: {
        start: moment()
          .startOf("month")
          .format("YYYY-MM-DD"),
        end: moment()
          .endOf("month")
          .format("YYYY-MM-DD"),
        modal: false
      },
      columns: [
        { value: "name", text: "Usuário" },
        { value: "qty", text: "QTD" }
      ],
      menu1: false,
      loading: false
    };
  },
  computed: {
    computedStart() {
      return this.formatDate(this.range.start);
    },
    computedEnd() {
      return this.formatDate(this.range.end);
    }
  },
  watch: {
    search: function() {
      this.debounceInput();
    }
  },
  created() {
    this.initialize();
  },
  methods: {
    initialize() {
      this.getList();
    },
    getList() {
      this.loading = true;
      const params = {
        ...this.range,
        modal: undefined
      };
      this.$http
        .get(`/calls/operators_report`, { params })
        .then(response => response.data)
        .then(json => {
          setTimeout(() => {
            this.loading = false;

            this.items = json.items;
          }, 1000);
        })
        .catch(() => (this.loading = false));
    },
    formatDate: date => (date ? moment(date).format("DD/MM/YYYY") : ""),
    debounceInput: _.debounce(function() {
      setTimeout(() => {
        this.getList();
      }, 200);
    }, 400)
  }
};
</script>
<style scoped>
table.v-table tbody td,
table.v-table tbody th {
  height: 35px;
}
table.v-table thead tr {
  height: 40px;
}

table td {
  font-size: 11px !important;
}
</style>

