<template>
  <v-container grid-list-md text-xs-center px-0>
    <v-layout row wrap>
      <v-flex xs12>
        <v-toolbar flat color="white" class="elevation-1">
          <v-toolbar-title>Transferencias</v-toolbar-title>
          <v-divider class="mx-2" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <v-text-field
            v-model.lazy="search"
            append-icon="search"
            label="Buscar"
            single-line
            hide-details
            class="hidden-sm-and-down"
            @click:append="getList()"
            v-on:keyup.enter="getList"
          ></v-text-field>
          <v-spacer></v-spacer>
          <v-btn
            @click="transferPackFormOpen({ office: $route.params.office })"
            color="primary"
            dark
            class="mb-2"
            >Novo</v-btn
          >
        </v-toolbar>

        <v-data-table
          :headers="columns"
          :items="items"
          :pagination.sync="pagination"
          :total-items="pagination.totalItems"
          :loading="loading"
          hide-actions
          class="elevation-1"
        >
          <template slot="items" slot-scope="props">
            <td>{{ props.item.id }}</td>
            <td>{{ props.item.created_at | formatDate }}</td>
            <td>{{ props.item.user && props.item.user.name }}</td>
            <td>
              {{
                props.item.transfers_count > 0
                  ? props.item.transfers_count
                  : "-"
              }}
            </td>
            <td>{{ props.item.origin && props.item.origin.name }}</td>
            <td>{{ props.item.destiny && props.item.destiny.name }}</td>
            <td>
              <span v-if="props.item.active"> Transferido </span>
              <span v-else-if="props.item.error">
                Erro no processamento.
                <small>({{ props.item.error_msg }})</small>
              </span>
              <span v-else-if="props.item.processing"> Processando </span>
              <span v-else> Pendente </span>
            </td>
            <td>{{ props.item.up_user && props.item.up_user.name }}</td>
            <td class="text-xs-center px-0">
              <v-menu offset-y v-if="!props.item.processing">
                <template v-slot:activator="{ on }">
                  <v-btn icon v-on="on">
                    <v-icon small>fas fa-ellipsis-v</v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-tile
                    v-if="props.item.active"
                    @click="
                      goExternal(`/transfer_packs/${props.item.id}/print`)
                    "
                  >
                    <v-list-tile-avatar>
                      <v-icon>print</v-icon>
                    </v-list-tile-avatar>
                    <v-list-tile-title>Imprimir</v-list-tile-title>
                  </v-list-tile>

                  <v-list-tile
                    v-if="!props.item.active"
                    @click="transferPackDoneOpen(props.item)"
                  >
                    <v-list-tile-avatar>
                      <v-icon>fas fa-clipboard-check</v-icon>
                    </v-list-tile-avatar>
                    <v-list-tile-title
                      >Finalizar Transferência</v-list-tile-title
                    >
                  </v-list-tile>

                  <v-list-tile
                    v-if="!props.item.active"
                    @click="transferPackFormOpen(props.item)"
                  >
                    <v-list-tile-avatar>
                      <v-icon>edit</v-icon>
                    </v-list-tile-avatar>
                    <v-list-tile-title>Editar</v-list-tile-title>
                  </v-list-tile>
                  <v-list-tile
                    v-if="!props.item.active"
                    small
                    icon
                    @click="del(props.item.id)"
                  >
                    <v-list-tile-avatar>
                      <v-icon>delete</v-icon>
                    </v-list-tile-avatar>
                    <v-list-tile-title>Excluir</v-list-tile-title>
                  </v-list-tile>
                </v-list>
              </v-menu>
            </td>
          </template>

          <template slot="no-data"
            >Desculpe, nenhum dado para ser exibido!</template
          >
        </v-data-table>
        <div class="text-xs-center pt-2">
          <v-pagination
            v-model="pagination.page"
            :length="pages"
          ></v-pagination>
        </div>
      </v-flex>
    </v-layout>
    <div v-if="transferPackFormActive.open">
      <transfer-pack-temp
        :active="transferPackFormActive.open"
        :item="transferPackFormActive.item"
        :finish="transferPackFormClose"
      />
    </div>
    <div v-if="transferPackDoneActive.open">
      <transfer-pack-done
        :active="transferPackDoneActive.open"
        :item="transferPackDoneActive.item"
        :finish="transferPackDoneClose"
      />
    </div>
  </v-container>
</template>

<script>
import _ from "lodash";
export default {
  data() {
    return {
      valid: false,
      dialog: false,
      search: "",
      pagination: {
        totalItems: 0,
        rowsPerPage: 10,
        descending: true,
        page: 1,
        sortBy: "id",
      },
      items: [],
      columns: [
        { value: "id", text: "ID" },
        { value: "created_at", text: "Data" },
        { value: "user.name", text: "Adicionado por", sortable: false },
        { value: "transfers_count", text: "QTD", sortable: false },
        { value: "origin.name", text: "Origem", sortable: false },
        { value: "destiny.name", text: "Destino", sortable: false },
        { value: "active", text: "Status" },
        { value: "up_user.name", text: "Finalizado por", sortable: false },
        { text: "", value: "", sortable: false },
      ],
      transferPackFormActive: { open: false, item: {} },
      transferPackDoneActive: { open: false, item: {} },
      loading: false,
    };
  },
  computed: {
    pages() {
      if (
        this.pagination.rowsPerPage == null ||
        this.pagination.totalItems == null
      )
        return 0;
      return Math.ceil(
        this.pagination.totalItems / this.pagination.rowsPerPage
      );
    },
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
    "pagination.page": {
      handler() {
        this.getList();
      },
      deep: true,
    },
  },
  created() {
    this.initialize();
  },
  methods: {
    initialize() {},
    getList() {
      this.loading = true;
      return new Promise((resolve, reject) => {
        const params = {
          ...this.pagination,
          totalItems: undefined,
          search: this.search,
          office: this.$route.params.office,
        };
        this.$http
          .get(`/transfer_packs/list`, { params })
          .then((response) => response.data)
          .then((json) => {
            setTimeout(() => {
              this.loading = false;

              this.items = json.items;
              this.pagination.totalItems = json.total;
            }, 1000);
          })
          .catch(() => (this.loading = false));
      });
    },
    goExternal(url) {
      var win = window.open(url, "_blank");
      win.focus();
    },
    transferPackFormOpen(item) {
      this.transferPackFormActive.open = true;
      this.transferPackFormActive.item = item;
    },
    transferPackFormClose() {
      this.transferPackFormActive.open = false;
      this.getList();
    },
    transferPackDoneOpen(item) {
      this.transferPackDoneActive.open = true;
      this.transferPackDoneActive.item = item;
    },
    transferPackDoneClose() {
      this.transferPackDoneActive.open = false;
      this.getList();
    },
    del(id) {
      if (confirm("Deseja realmente excluir esta Transferência?")) {
        this.$http.delete(`/transfer_packs/${id}`).then((response) => {
          this.$notify({ title: `Transferência Excluido!`, group: "notifications", type: "success" });
          this.getList();
        });
      }
    },
    debounceInput: _.debounce(function () {
      setTimeout(() => {
        this.getList();
      }, 200);
    }, 400),
  },
};
</script>
