import dayjs from 'dayjs';
import { maskJs } from 'mask-js';

const months = [
  "Janeiro",
  "Fevereiro",
  "Março",
  "Abril",
  "Maio",
  "Junho",
  "Julho",
  "Agosto",
  "Setembro",
  "Outubro",
  "Novembro",
  "Dezembro"
]

export const FormatDate = date => date ? dayjs(date).format('DD/MM/YYYY') : "";
export const FormatDateTime = date => date ? dayjs(date).format('DD/MM/YYYY HH:mm') : "";
export const FormatDateTimeShort = date => date ? dayjs(date).format('DD/MM HH:mm') : "";
export const FormatDateLong = date => date ? dayjs(date).format('DD [de '+months[dayjs(date).month()]+ ' de ] YYYY ') : "";
export const FormatMonth = date => date ? months[dayjs(date).month()] : "";
export const FormatCnpj = cnpj => cnpj ? maskJs('99.999.999/9999-99', cnpj) : '';
export const FormatPhone = phone => phone ? maskJs('(99) 9999?9-9999', phone) : '';

export const FormatMoney = v => {
  let val = (v / 1).toFixed(2).replace('.', ',')
  return 'R$ ' + val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
}
