<template>
  <v-container grid-list-md text-xs-center px-0>
    <v-layout row wrap>
      <v-flex xs12>
        <v-toolbar flat color="white" class="elevation-1">
          <v-toolbar-title>Entrada</v-toolbar-title>
          <v-divider class="mx-2" inset vertical></v-divider>
          <v-text-field
            v-model.lazy="search"
            append-icon="search"
            label="Buscar"
            single-line
            hide-details
            class="hidden-sm-and-down"
            @click:append="getList()"
            v-on:keyup.enter="getList"
          ></v-text-field>
          <v-spacer></v-spacer>
          <v-menu
            v-model="filtro.menu"
            bottom
            left
            :close-on-content-click="false"
            max-width="400"
            min-width="400"
            offset-x
          >
            <v-btn outline slot="activator" color="primary">OPÇÕES</v-btn>

            <v-card>
              <v-card-title class="px-1 py-1">
                OPÇÕES
                <v-spacer></v-spacer>
                <v-btn
                  outline
                  light
                  small
                  color="primary"
                  @click="
                    filtro.menu = true;
                    filtro = {};
                  "
                  >Limpar</v-btn
                >
              </v-card-title>

              <v-layout row wrap>
                <v-flex xs12 class="px-1">
                  <v-text-field
                    v-model="filtro.start"
                    type="date"
                    label="De"
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 class="px-1">
                  <v-text-field
                    v-model="filtro.end"
                    type="date"
                    label="Até"
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 class="px-1">
                  <v-select
                    v-model="filtro.invoice_pending"
                    :items="[
                      { text: 'TODOS', value: null },
                      { text: 'NF Pendente', value: 1 },
                      { text: 'Com NF', value: 0 }
                    ]"
                  />
                </v-flex>
                <v-flex xs12 class="px-1">
                  <v-text-field
                    v-model="filtro.invoice"
                    label="NF"
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 class="px-1">
                  <select-custom
                    :url="`/providers/list?sortBy=name`"
                    v-model="filtro.provider_id"
                    label="Fornecedor"
                  />
                </v-flex>
              </v-layout>

              <v-card-actions>
                <v-spacer></v-spacer>

                <v-btn flat @click="filtro.menu = false">Fechar</v-btn>
                <v-btn color="primary" @click="filtrar()">FILTRAR</v-btn>
              </v-card-actions>
            </v-card>
          </v-menu>

          <v-btn
            color="orange"
            dark
            class="mb-2"
            @click="openLoanForm({ office: $route.params.office, donation: 1 })"
            >Doação</v-btn
          >

          <v-btn
            color="blue"
            dark
            class="mb-2"
            @click="openLoanForm({ office: $route.params.office })"
            >Emprestimo</v-btn
          >
          <v-btn
            color="primary"
            dark
            class="mb-2"
            @click="openPurchaseForm({ office: $route.params.office })"
            >Novo</v-btn
          >
        </v-toolbar>

        <v-data-table
          :headers="columns"
          :items="items"
          :pagination.sync="pagination"
          :total-items="pagination.totalItems"
          :loading="loading"
          hide-actions
          class="elevation-1"
        >
          <template slot="items" slot-scope="props">
            <tr
              :class="{
                'orange lighten-1': props.item.invoice_pending,
                'red lighten-1': props.item.removed
              }"
            >
              <td>{{ props.item.id }}</td>
              <td>{{ props.item.created_at | formatDate }}</td>
              <td>
                <span v-for="(item, i) in props.item.lotes" :key="i"
                  >{{ item.product && item.product.name }}({{
                    item.qty
                  }});</span
                >
              </td>
              <td>{{ props.item.invoice }}</td>
              <td>
                {{ props.item.provider && props.item.provider.name }}
                {{ props.item.partner && props.item.partner.name }}
              </td>
              <td>{{ props.item.issue_date | formatDate }}</td>
              <td>{{ props.item.price | formatMoney }}</td>
              <td>{{ props.item.loan ? "SIM" : "NÃO" }}</td>
              <td>{{ props.item.donation ? "SIM" : "NÃO" }}</td>
              <td>{{ props.item.active ? "SIM" : "NÃO" }}</td>
              <td>
                <span v-if="props.item.removed">
                  Excluido por
                  {{
                    props.item.removed_user && props.item.removed_user.name
                  }}</span
                >
                <span v-else>{{
                  props.item.done_user && props.item.done_user.name
                }}</span>
              </td>
              <td class="text-xs-center">
                <v-menu offset-y>
                  <template v-slot:activator="{ on }">
                    <v-btn icon v-on="on">
                      <v-icon small>fas fa-ellipsis-v</v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-tile
                      v-if="!props.item.active"
                      @click="openPurchaseForm(props.item)"
                    >
                      <v-list-tile-title>EDITAR</v-list-tile-title>
                    </v-list-tile>
                    <v-list-tile @click="openPurchaseView(props.item)">
                      <v-list-tile-title>VER NOTA</v-list-tile-title>
                    </v-list-tile>
                    <v-list-tile
                      v-if="
                        !props.item.removed &&
                          props.item.active &&
                          !props.item.invoice_pending
                      "
                      @click="
                        goExternal('/purchases/' + props.item.id + '/print')
                      "
                    >
                      <v-list-tile-title>IMPRIMIR</v-list-tile-title>
                    </v-list-tile>
                    <v-list-tile
                      v-if="!props.item.active"
                      @click="
                        goExternal(
                          '/purchases/' + props.item.id + '/print_mirror'
                        )
                      "
                    >
                      <v-list-tile-title>IMPRIMIR ESPELHO</v-list-tile-title>
                    </v-list-tile>
                    <v-list-tile
                      v-if="!props.item.active"
                      @click="purchaseCheckupOpen(props.item)"
                    >
                      <v-list-tile-title
                        >CONFIMAR ENTRADA DOS PRODUTOS</v-list-tile-title
                      >
                    </v-list-tile>
                    <v-list-tile
                      v-if="props.item.invoice_pending"
                      @click="purchaseInvoiceOpen(props.item)"
                    >
                      <v-list-tile-title
                        >ADICIONAR NOTA FISCAL</v-list-tile-title
                      >
                    </v-list-tile>
                    <v-list-tile
                      v-if="
                        !props.item.removed &&
                          (user.type == 1 ||
                            user.modules.includes('EXCLUIR_NOTA_FISCAL'))
                      "
                      @click="openDialogDelete(props.item)"
                    >
                      <v-list-tile-title class="red--text"
                        >EXCLUIR</v-list-tile-title
                      >
                    </v-list-tile>
                  </v-list>
                </v-menu>
                <!--<v-icon
                    small
                    class="mr-2"
                    v-if="props.item.bill && !props.item.bill.paid"
                    @click="openPurchaseForm(props.item)"
                  >edit</v-icon>
                  <v-icon
                    v-if="user.type == 1 && props.item.bill && !props.item.bill.paid"
                    small
                    @click="del(props.item.id)"
                  >delete</v-icon>-->
              </td>
            </tr>
          </template>

          <template slot="no-data"
            >Desculpe, nenhum dado para ser exibido!</template
          >
        </v-data-table>
        <div class="text-xs-center pt-2">
          <v-pagination
            v-model="pagination.page"
            :length="pages"
          ></v-pagination>
        </div>
      </v-flex>
    </v-layout>

    <div v-if="purchaseFormActive.open">
      <purchase-form
        :active="purchaseFormActive.open"
        :item="purchaseFormActive.item"
        :finish="onClosePurchaseForm"
      ></purchase-form>
    </div>

    <div v-if="loanFormActive.open">
      <loan-form
        :active="loanFormActive.open"
        :item="loanFormActive.item"
        :finish="onCloseLoanForm"
      />
    </div>

    <div v-if="purchaseViewActive.open">
      <purchase-view
        :active="purchaseViewActive.open"
        :item="purchaseViewActive.item"
        :finish="onClosePurchaseView"
      ></purchase-view>
    </div>

    <div v-if="gradeLoanViewActive.open">
      <grade-loan-view
        :active="gradeLoanViewActive.open"
        :item="gradeLoanViewActive.item"
        :finish="onCloseGradeLoanView"
      ></grade-loan-view>
    </div>

    <div v-if="purchaseCheckupActive.open">
      <purchase-checkup
        :active="purchaseCheckupActive.open"
        :item="purchaseCheckupActive.item"
        :finish="purchaseCheckupClose"
      />
    </div>
    <div v-if="purchaseInvoiceActive.open">
      <purchase-invoice
        :active="purchaseInvoiceActive.open"
        :item="purchaseInvoiceActive.item"
        :finish="purchaseInvoiceClose"
      />
    </div>

    <v-dialog v-model="dialogDelete.open" scrollable max-width="400px">
      <v-card v-if="dialogDelete.item">
        <v-card-title>
          <div>
            <div class="headline">Excluir Nota Fiscal</div>
            <span class="red--text">Deseja realmente excluir?</span>
          </div>
        </v-card-title>
        <v-card-text>
          <v-form v-model="dialogDelete.valid" xs12>
            <v-layout row wrap>
              <v-flex xs12>
                <v-text-field
                  v-model="dialogDelete.item.removed_obs"
                  label="Motivo da Exclusão"
                  :rules="[v => !!v || 'Motivo da Exclusão é obrigatório!']"
                  required
                ></v-text-field>
              </v-flex>
            </v-layout>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn flat color="grey" @click="dialogDelete.open = false"
            >CANCELAR</v-btn
          >
          <v-btn
            color="red"
            dark
            @click="del(dialogDelete.item.id)"
            :disabled="!dialogDelete.valid"
            >EXCLUIR</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import moment from "moment";
import _ from "lodash";
export default {
  data() {
    return {
      search: "",
      user: USER,
      purchaseViewActive: { open: false, item: {} },
      gradeLoanViewActive: { open: false, item: {} },
      purchaseCheckupActive: { open: false, item: {} },
      purchaseInvoiceActive: { open: false, item: {} },
      dialogDelete: { open: false, item: {}, valid: false },
      loading: false,
      dialogGradeTo: false,
      purchaseFormActive: {
        open: false,
        item: {}
      },
      loanFormActive: {
        open: false,
        item: {}
      },
      pagination: {
        totalItems: 0,
        rowsPerPage: 10,
        descending: false,
        page: 1,
        sortBy: "id"
      },
      items: [],
      columns: [
        { value: "id", text: "ID" },
        { value: "created_at", text: "Data" },
        { value: "lotes", text: "Produtos", sortable: false },
        { value: "invoice", text: "Nota Fiscal" },
        { value: "provider", text: "Fornecedor", sortable: false },
        { value: "issue_date", text: "Data Emissão" },
        { value: "price", text: "Valor" },
        { value: "loan", text: "Emprestimo" },
        { value: "donation", text: "Doação" },
        { value: "active", text: "Finalizado" },
        { value: "done_user", text: "Finalizado por", sortable: false },
        { text: "", value: "", sortable: false }
      ],
      filtro: {
        menu: false,
        invoice_pending: null,
        start: moment()
          .startOf("month")
          .format("YYYY-MM-DD"),
        end: moment()
          .endOf("month")
          .format("YYYY-MM-DD"),
        invoice: null,
        provider_id: null
      }
    };
  },
  computed: {
    pages() {
      if (
        this.pagination.rowsPerPage == null ||
        this.pagination.totalItems == null
      )
        return 0;

      return Math.ceil(
        this.pagination.totalItems / this.pagination.rowsPerPage
      );
    }
  },
  watch: {
    "pagination.page": {
      handler() {
        this.getList();
      },
      deep: true
    },
    "$route.params.office": {
      handler() {
        this.getList();
      }
    }
  },
  created() {
    this.initialize();
  },
  methods: {
    initialize() {},
    getList() {
      this.loading = true;
      return new Promise((resolve, reject) => {
        const params = {
          ...this.pagination,
          ...this.filtro,
          totalItems: undefined,
          search: this.search,
          office: this.$route.params.office
        };
        this.$http
          .get(`/purchases/list`, { params })
          .then(response => response.data)
          .then(json => {
            setTimeout(() => {
              this.loading = false;
              this.items = json.items;
              this.pagination.totalItems = json.total;
            }, 1000);
          })
          .catch(() => (this.loading = false));
      });
    },
    del(id) {
      this.$http
        .delete(
          `/purchases/${id}?removed_obs=${this.dialogDelete.item.removed_obs}`
        )
        .then(() => {
          this.dialogDelete.open = false;
          this.$notify({
            title: `compra Excluida!`,
            group: "notifications",
            type: "success"
          });
          this.getList();
        })
        .catch(err => {
          this.$reqError(`Erro ao excluir!`, err, this);
        });
    },
    debounceInput: _.debounce(function() {
      setTimeout(() => {
        this.getList();
      }, 200);
    }, 400),
    goExternal(url) {
      var win = window.open(url, "_blank");
      win.focus();
    },
    openPurchaseForm(item) {
      this.purchaseFormActive.open = true;
      this.purchaseFormActive.item = item;
    },
    onClosePurchaseForm() {
      this.purchaseFormActive.open = false;
      this.getList();
    },
    openLoanForm(item) {
      this.loanFormActive.open = true;
      this.loanFormActive.item = item;
    },
    onCloseLoanForm() {
      this.loanFormActive.open = false;
      this.getList();
    },
    openPurchaseView(item) {
      this.purchaseViewActive.open = true;
      this.purchaseViewActive.item = item;
    },
    onClosePurchaseView() {
      this.purchaseViewActive.open = false;
    },
    openGradeLoanView(item) {
      this.gradeLoanViewActive.open = true;
      this.gradeLoanViewActive.item = item;
    },
    onCloseGradeLoanView() {
      this.gradeLoanViewActive.open = false;
    },
    purchaseCheckupOpen(item) {
      this.purchaseCheckupActive.open = true;
      this.purchaseCheckupActive.item = item;
    },
    purchaseCheckupClose() {
      this.purchaseCheckupActive.open = false;
      this.getList();
    },
    purchaseInvoiceOpen(item) {
      this.purchaseInvoiceActive.open = true;
      this.purchaseInvoiceActive.item = item;
    },
    purchaseInvoiceClose() {
      this.purchaseInvoiceActive.open = false;
      this.getList();
    },
    openDialogDelete(item) {
      console.log("openDialogDelete", item);
      this.dialogDelete.open = true;
      this.dialogDelete.item = item;
    },
    filtrar() {
      this.filtro.menu = false;
      this.getList();
    }
  }
};
</script>
