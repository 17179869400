<template>
  <div>
    <v-container grid-list-md px-0>
      <notifications position="bottom center" />

      <v-toolbar flat color="transparent" class="elevation-1">
        <v-toolbar-title>Relátorio</v-toolbar-title>
        <v-divider class="mx-2" inset vertical></v-divider>
        <div>
          <v-select
            v-model="type"
            :items="[
              { text: 'Geral', value: '/calls/reports' },
              { text: 'Por Horário', value: '/calls/reports/byhorary' }
            ]"
            label="Tipo"
          ></v-select>
        </div>
        <v-btn
          fab
          small
          style="padding: 10px 0;"
          color="primary"
          @click="getBpa()"
        >
          <v-icon dark>fas fa-file-alt</v-icon>
        </v-btn>
        <v-spacer></v-spacer>
        <v-menu
          ref="menuStart"
          :close-on-content-click="false"
          v-model="range.menuStart"
          :nudge-right="40"
          :return-value.sync="range.start"
          lazy
          transition="scale-transition"
          offset-y
          full-width
          min-width="290px"
        >
          <v-text-field
            slot="activator"
            v-model="computedStart"
            label="Data inicial"
            prepend-icon="event"
            readonly
          ></v-text-field>
          <v-date-picker
            v-model="range.start"
            no-title
            scrollable
            locale="pt-br"
          >
            <v-spacer></v-spacer>
            <v-btn flat color="primary" @click="range.menuStart = false"
              >Cancel</v-btn
            >
            <v-btn
              flat
              color="primary"
              @click="$refs.menuStart.save(range.start)"
              >OK</v-btn
            >
          </v-date-picker>
        </v-menu>

        <v-menu
          ref="menuEnd"
          :close-on-content-click="false"
          v-model="range.menuEnd"
          :nudge-right="40"
          :return-value.sync="range.end"
          lazy
          transition="scale-transition"
          offset-y
          full-width
          min-width="290px"
          v-if="type != '/calls/reports/byhorary'"
        >
          <v-text-field
            slot="activator"
            v-model="computedEnd"
            label="Data Final"
            prepend-icon="event"
            readonly
          ></v-text-field>
          <v-date-picker v-model="range.end" no-title scrollable locale="pt-br">
            <v-spacer></v-spacer>
            <v-btn flat color="primary" @click="range.menuEnd = false"
              >Cancel</v-btn
            >
            <v-btn flat color="primary" @click="$refs.menuEnd.save(range.end)"
              >OK</v-btn
            >
          </v-date-picker>
        </v-menu>

        <v-btn fab small color="primary" @click="getReport()">
          <v-icon dark>search</v-icon>
        </v-btn>
      </v-toolbar>

      <v-layout row wrap v-if="reportUrl">
        <v-flex xs12>
          <iframe
            width="100%"
            height="550px"
            :src="reportUrl"
            frameborder="0"
            allowfullscreen
          ></iframe>
        </v-flex>
      </v-layout>

      <v-fab-transition>
        <v-btn
          dark
          fab
          fixed
          bottom
          right
          color="primary"
          @click="openReportsPrint()"
        >
          <v-icon>print</v-icon>
        </v-btn>
      </v-fab-transition>

      <dialog-loader :active="loading"></dialog-loader>
    </v-container>
  </div>
</template>

<script>
import moment from "moment";
export default {
  data() {
    return {
      range: {
        start: moment()
          .startOf("month")
          .format("YYYY-MM-DD"),
        end: moment()
          .endOf("month")
          .format("YYYY-MM-DD")
      },
      loading: false,
      reportUrl: "",
      type: "/calls/reports"
    };
  },
  computed: {
    computedStart() {
      return this.formatDate(this.range.start);
    },
    computedEnd() {
      return this.formatDate(this.range.end);
    }
  },
  created() {
    this.initialize();
  },
  methods: {
    initialize() {
      this.getReport();
    },
    getReport() {
      if (this.type == "/calls/reports/byhorary") {
        this.range.end = this.range.start;
      }
      this.reportUrl = `${this.type}?start=${this.range.start}&end=${this.range.end}`;
    },
    getBpa() {
      this.loading = true;

      window.location.href = "/reportbpa?ano=2021&mes=11";
      this.loading = false;
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    openReportsPrint() {
      window.open(this.reportUrl);
    }
  }
};
</script>
