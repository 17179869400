<template>
  <v-container grid-list-md text-xs-center px-0>
    <v-layout row wrap>
      <v-flex xs12>
        <v-toolbar flat color="white" class="elevation-1 mb-4">
          <v-toolbar-title>Relatório de valor de entrada</v-toolbar-title>
          <v-divider class="mx-2" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <v-menu
            v-model="menuFilter.menu"
            bottom
            left
            :close-on-content-click="false"
            max-width="400"
            min-width="400"
            offset-x
          >
            <v-btn slot="activator" color="primary" outline>OPÇÕES</v-btn>

            <v-card>
              <v-card-title class="px-1 py-1">
                FILTRO
                <v-spacer></v-spacer>

                <v-btn
                  outline
                  light
                  small
                  color="primary"
                  @click="menuFilter.menu = true; filtro = {};"
                >Limpar</v-btn>
              </v-card-title>

              <v-layout row wrap>
                <v-flex xs12 class="px-1">
                  <v-text-field v-model="filtro.start" type="date" label="De"></v-text-field>
                </v-flex>
                <v-flex xs12 class="px-1">
                  <v-text-field v-model="filtro.end" type="date" label="Até"></v-text-field>
                </v-flex>
                <v-flex xs12 class="px-1">
                  <select-custom
                    :url="'/providers/list?sortBy=name'"
                    v-model="filtro.provider_id"
                    label="Fornecedor"
                  />
                </v-flex>

                <v-flex xs12 class="px-1">
                  <v-select
                    v-model="filtro.operatiton"
                    label="Operação"
                    :items="['doação', 'emprestimo', 'fornecedor']"
                  />
                </v-flex>
              </v-layout>

              <v-card-actions>
                <v-spacer></v-spacer>

                <v-btn flat @click="menuFilter.menu = false">Fechar</v-btn>
                <v-btn color="primary" flat @click="filtrar()">Filtrar</v-btn>
              </v-card-actions>
            </v-card>
          </v-menu>

          <v-btn dark color="primary" @click="goReport()">RELATÓRIO</v-btn>
        </v-toolbar>

        <v-data-table
          :headers="columns"
          :items="itemsFilter"
          :loading="loading"
          hide-actions
          disable-initial-sort
          class="elevation-1"
        >
          <template slot="items" slot-scope="props">
            <tr>
              <td>{{ props.item.issue_date | formatDate}}</td>
              <td>{{ props.item.invoice}}</td>
              <td>{{ props.item.provider && props.item.provider.name}}</td>
              <td>
                <span v-if="props.item.loan">emprestimo</span>
                <span v-else-if="props.item.donation">doação</span>
                <span v-else>fornecedor</span>
              </td>
              <td>{{ props.item.price | formatMoney}}</td>
            </tr>
          </template>

          <template slot="no-data">Desculpe, nenhum dado para ser exibido!</template>
        </v-data-table>
      </v-flex>
      <v-flex xs12 class="text-xs-right">
        <h4>Valor total {{total() | formatMoney}}</h4>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import moment from "moment";
import _ from "lodash";
export default {
  data() {
    return {
      search: "",
      items: [],
      horary: false,
      itemsFilter: [],
      menuFilter: { menu: false },
      filtro: {
        start: moment().startOf("month").format("YYYY-MM-DD"),
        end: moment().endOf("month").format("YYYY-MM-DD"),
        provider_id: null,
        operatiton: null,
      },
      columns: [
        { value: "issue_date", text: "DATA EMISSÃO NF" },
        { value: "invoice", text: "Nº NF" },
        { value: "provider", text: "Fornecedor" },
        { value: "operatiton", text: "OPERAÇÃO", sortable: false },
        { value: "price", text: "VALOR TOTAL" },
      ],
      loading: false,
    };
  },
  watch: {
    search: function () {
      this.debounceInput();
    },
    "$route.params.type": {
      handler() {
        this.getList();
      },
    },
  },
  created() {
    this.initialize();
  },
  methods: {
    initialize() {
      this.getList();
    },
    getList() {
      this.loading = true;
      return new Promise((resolve, reject) => {
        const params = {
          ...this.filtro,
        };
        this.$http
          .get(`/purchases/report_price/${this.$route.params.office}`, {
            params,
          })
          .then((response) => response.data)
          .then((json) => {
            setTimeout(() => {
              this.loading = false;
              const aux = json.items;
              this.items = aux;
              this.itemsFilter = aux;
              //console.log(this.items);
            }, 1000);
          })
          .catch(() => (this.loading = false));
      });
    },
    getSectorDestinys() {
      this.$http
        .get(`/sectors/list_destiny`)
        .then((response) => response.data)
        .then((json) => {
          this.sector_destinys = json;
        });
    },
    goExternal(url) {
      var win = window.open(url, "_blank");
      win.focus();
    },
    filtrar() {
      this.menuFilter.menu = false;
      this.getList();
    },
    normalized(string) {
      string = string + "";
      string = string.trim().toLowerCase();
      const map = {
        a: /[\xE0-\xE6]/g,
        A: /[\xC0-\xC6]/g,
        e: /[\xE8-\xEB]/g,
        E: /[\xC8-\xCB]/g,
        i: /[\xEC-\xEF]/g,
        I: /[\xCC-\xCF]/g,
        o: /[\xF2-\xF6]/g,
        O: /[\xD2-\xD6]/g,
        u: /[\xF9-\xFC]/g,
        U: /[\xD9-\xDC]/g,
        c: /\xE7/g,
        C: /\xC7/g,
        n: /\xF1/g,
        N: /\xD1/g,
      };

      for (const key in map) {
        if (map.hasOwnProperty(key)) {
          string = string.replace(map[key], map);
        }
      }

      return string;
    },
    total() {
      return _.sumBy(this.itemsFilter, function (o) {
        return o.price;
      });
    },
    goReport() {
      this.menuFilter.menu = false;
      let provider_id = "";
      let operatiton = "";
      if (this.filtro.provider_id != null)
        provider_id = `&provider_id=${this.filtro.provider_id}`;
      if (this.filtro.operatiton)
        operatiton = `&operatiton=${this.filtro.operatiton}`;

      let url = `/purchases/report_price/${this.$route.params.office}?print=1&start=${this.filtro.start}
        &end=${this.filtro.end}${provider_id}${operatiton}`;
      window.open(url, "_blank");
    },
    debounceInput: _.debounce(function () {
      setTimeout(() => {
        const q = this.normalized(this.search);
        this.itemsFilter = this.items.filter((item) => {
          return this.normalized(item.name).match(q);
        });
      }, 200);
    }, 400),
  },
};
</script>
