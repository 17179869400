<template>
  <div>

    <dialog-loader :active="loading" />
    <v-container grid-list-md px-0>
      <v-card>
        <v-card-title>
          <span class="headline">GERAR RELATÓRIOS DE PLANILHAS</span>
        </v-card-title>

        <v-card-text>

          <v-container grid-list-md>
            <v-layout row wrap>
              <v-flex xs12 sm4>
                <v-menu
                  ref="menuStart"
                  :close-on-content-click="false"
                  v-model="range.menuStart"
                  :nudge-right="40"
                  :return-value.sync="range.start"
                  lazy
                  transition="scale-transition"
                  offset-y
                  full-width
                  min-width="290px"
                >
                  <v-text-field
                    slot="activator"
                    v-model="computedStart"
                    label="Data inicial"
                    prepend-icon="event"
                    readonly
                  ></v-text-field>
                  <v-date-picker v-model="range.start" no-title scrollable locale="pt-br">
                    <v-spacer></v-spacer>
                    <v-btn flat color="primary" @click="range.menuStart = false">Cancel</v-btn>
                    <v-btn flat color="primary" @click="$refs.menuStart.save(range.start)">OK</v-btn>
                  </v-date-picker>
                </v-menu>
              </v-flex>
              <v-flex xs12 sm4 offset-xs4>
                <v-menu
                  ref="menuEnd"
                  :close-on-content-click="false"
                  v-model="range.menuEnd"
                  :nudge-right="40"
                  :return-value.sync="range.end"
                  lazy
                  transition="scale-transition"
                  offset-y
                  full-width
                  min-width="290px"
                >
                  <v-text-field
                    slot="activator"
                    v-model="computedEnd"
                    label="Data Final"
                    prepend-icon="event"
                    readonly
                  ></v-text-field>
                  <v-date-picker v-model="range.end" no-title scrollable locale="pt-br">
                    <v-spacer></v-spacer>
                    <v-btn flat color="primary" @click="range.menuEnd = false">Cancel</v-btn>
                    <v-btn flat color="primary" @click="$refs.menuEnd.save(range.end)">OK</v-btn>
                  </v-date-picker>
                </v-menu>
              </v-flex>

              <v-flex xs12 sm4>
                <v-autocomplete
                  v-model="editedItem.contract_id"
                  :items="contracts"
                  label="Contrato"
                  required
                  :rules="[v => !!v || 'Contrato é obrigatório!']"
                ></v-autocomplete>
              </v-flex>

              <!--<v-flex
                xs12
                sm4
              >
                <v-autocomplete
                  v-model="editedItem.bank_id"
                  :items="banks"
                  label="Banco"
                  required
                  :rules="[v => !!v || 'Banco é obrigatório!']"
                ></v-autocomplete>
              </v-flex>-->

              <v-flex xs12 sm4>
                <div
                  class="v-input v-money v-text-field v-text-field--prefix v-input--is-label-active v-input--is-dirty theme--light"
                >
                  <div class="v-text-field__slot">
                    <label
                      aria-hidden="true"
                      class="v-label v-label--active theme--light"
                      style="left: 0px; right: auto; "
                    >Saldo Anterior</label>
                    <money
                      style="
                        margin: 1px 0 0 0;
                        padding: 13px 0;
                        border-bottom: 1.4px solid #999;"
                      v-model="editedItem.anter"
                      v-bind="money"
                    ></money>
                  </div>
                </div>
              </v-flex>

              <v-flex xs12 sm4>
                <div
                  class="v-input v-money v-text-field v-text-field--prefix v-input--is-label-active v-input--is-dirty theme--light"
                >
                  <div class="v-text-field__slot">
                    <label
                      aria-hidden="true"
                      class="v-label v-label--active theme--light"
                      style="left: 0px; right: auto; "
                    >Reembolso de Despesas</label>
                    <money
                      style="
                        margin: 1px 0 0 0;
                        padding: 13px 0;
                        border-bottom: 1.4px solid #999;"
                      v-model="editedItem.reemb"
                      v-bind="money"
                    ></money>
                  </div>
                </div>
              </v-flex>

              <v-flex xs12 sm4>
                <div
                  class="v-input v-money v-text-field v-text-field--prefix v-input--is-label-active v-input--is-dirty theme--light"
                >
                  <div class="v-text-field__slot">
                    <label
                      aria-hidden="true"
                      class="v-label v-label--active theme--light"
                      style="left: 0px; right: auto; "
                    >Saldo</label>
                    <money
                      style="
                        margin: 1px 0 0 0;
                        padding: 13px 0;
                        border-bottom: 1.4px solid #999;"
                      v-model="editedItem.saldo"
                      v-bind="money"
                    ></money>
                  </div>
                </div>
              </v-flex>

              <v-flex xs12 sm4>
                <div
                  class="v-input v-money v-text-field v-text-field--prefix v-input--is-label-active v-input--is-dirty theme--light"
                >
                  <div class="v-text-field__slot">
                    <label
                      aria-hidden="true"
                      class="v-label v-label--active theme--light"
                      style="left: 0px; right: auto; "
                    >Recursos Próprios</label>
                    <money
                      style="
                        margin: 1px 0 0 0;
                        padding: 13px 0;
                        border-bottom: 1.4px solid #999;"
                      v-model="editedItem.propr"
                      v-bind="money"
                    ></money>
                  </div>
                </div>
              </v-flex>

              <v-flex xs12 sm4>
                <div
                  class="v-input v-money v-text-field v-text-field--prefix v-input--is-label-active v-input--is-dirty theme--light"
                >
                  <div class="v-text-field__slot">
                    <label
                      aria-hidden="true"
                      class="v-label v-label--active theme--light"
                      style="left: 0px; right: auto; "
                    >Resgate</label>
                    <money
                      style="
                        margin: 1px 0 0 0;
                        padding: 13px 0;
                        border-bottom: 1.4px solid #999;"
                      v-model="editedItem.resgate"
                      v-bind="money"
                    ></money>
                  </div>
                </div>
              </v-flex>

              <v-flex xs12 sm4>
                <div
                  class="v-input v-money v-text-field v-text-field--prefix v-input--is-label-active v-input--is-dirty theme--light"
                >
                  <div class="v-text-field__slot">
                    <label
                      aria-hidden="true"
                      class="v-label v-label--active theme--light"
                      style="left: 0px; right: auto; "
                    >Aplicações</label>
                    <money
                      style="
                        margin: 1px 0 0 0;
                        padding: 13px 0;
                        border-bottom: 1.4px solid #999;"
                      v-model="editedItem.aplica"
                      v-bind="money"
                    ></money>
                  </div>
                </div>
              </v-flex>

              <v-flex xs12 sm4>
                <div
                  class="v-input v-money v-text-field v-text-field--prefix v-input--is-label-active v-input--is-dirty theme--light"
                >
                  <div class="v-text-field__slot">
                    <label
                      aria-hidden="true"
                      class="v-label v-label--active theme--light"
                      style="left: 0px; right: auto; "
                    >Rendimentos</label>
                    <money
                      style="
                        margin: 1px 0 0 0;
                        padding: 13px 0;
                        border-bottom: 1.4px solid #999;"
                      v-model="editedItem.rendi"
                      v-bind="money"
                    ></money>
                  </div>
                </div>
              </v-flex>

              <v-flex xs12 sm4>
                <div
                  class="v-input v-money v-text-field v-text-field--prefix v-input--is-label-active v-input--is-dirty theme--light"
                >
                  <div class="v-text-field__slot">
                    <label
                      aria-hidden="true"
                      class="v-label v-label--active theme--light"
                      style="left: 0px; right: auto; "
                    >Encargos</label>
                    <money
                      style="
                        margin: 1px 0 0 0;
                        padding: 13px 0;
                        border-bottom: 1.4px solid #999;"
                      v-model="editedItem.encar"
                      v-bind="money"
                    ></money>
                  </div>
                </div>
              </v-flex>

              <v-flex xs12 sm4>
                <div
                  class="v-input v-money v-text-field v-text-field--prefix v-input--is-label-active v-input--is-dirty theme--light"
                >
                  <div class="v-text-field__slot">
                    <label
                      aria-hidden="true"
                      class="v-label v-label--active theme--light"
                      style="left: 0px; right: auto; "
                    >Valor devolvido</label>
                    <money
                      style="
                        margin: 1px 0 0 0;
                        padding: 13px 0;
                        border-bottom: 1.4px solid #999;"
                      v-model="editedItem.devol"
                      v-bind="money"
                    ></money>
                  </div>
                </div>
              </v-flex>

              <v-flex xs12>
                <v-text-field
                  v-model="editedItem.importan"
                  label="Importancia recebida Ex. R$ 3.850.894,63 (Três milhões oitocentos e cinquenta mil oitocentos e noventa e quatro reais e sessenta e três centavos)"
                ></v-text-field>
              </v-flex>

              <v-flex xs12 class="text-xs-right">
                <h5 class="red__advise" v-if="showAdvise">Você selecionou o contrato?</h5>
                <v-btn color="primary" @click="createPlan()">
                  <v-icon dark>far fa-file-excel</v-icon>&nbsp; GERAR PLANILHAS
                </v-btn>
              </v-flex>
            </v-layout>

            <v-divider />

            <v-layout row wrap mt-5>
              <v-flex xs12 sm6 v-for="(item, i) in links" :key="i">
                <v-btn outline color="blue darken-4" @click="goPlan(item.url)">{{item.title}}</v-btn>
              </v-flex>
            </v-layout>
          </v-container>
        </v-card-text>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import moment from "moment";
import { Money } from "v-money";
export default {
  components: { Money },
  data() {
    return {
      showAdvise: false,
      range: {
        start: moment().startOf("month").format("YYYY-MM-DD"),
        end: moment().endOf("month").format("YYYY-MM-DD"),
      },
      editedItem: {
        anter: 0,
        reemb: 0,
        propr: 0,
        saldo: 0,
        resgate: 0,
        aplica: 0,
        rendi: 0,
        encar: 0,
        devol: 0,
        importan: "",
        contract_id: null,
        bank_id: null,
        exercise: "",
        other: 0,
      },
      loading: false,
      links: [],
      contracts: [],
      banks: [],
      money: {
        decimal: ",",
        thousands: ".",
        prefix: "R$ ",
        suffix: "",
        precision: 2,
        masked: false,
      },
    };
  },
  watch: {
    "editedItem.contract_id": function(v) {
      if(v != null) {
        this.showAdvise = false;
      }
    }
  },
  computed: {
    computedStart() {
      return this.formatDate(this.range.start);
    },
    computedEnd() {
      return this.formatDate(this.range.end);
    },
  },
  created() {
    this.initialize();
  },
  methods: {
    initialize() {
      this.getContracts();
      this.getBanks();
    },
    getBanks() {
      this.$http
        .get(`/banks/list`)
        .then((response) => response.data)
        .then((json) => {
          if (json && json.items) {
            const arr = [];
            json.items.forEach((obj) =>
              arr.push({ text: obj.name, value: obj.id })
            );
            this.banks = arr;
          }
        });
    },
    getContracts() {
      this.$http
        .get(`/contracts/list`)
        .then((response) => response.data)
        .then((json) => {
          if (json && json.items) {
            const arr = [];
            json.items.forEach((obj) =>
              arr.push({
                text: obj.name + " Ex. " + obj.exercise,
                value: obj.id,
              })
            );
            this.contracts = arr;
          }
        });
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    goPlan(url) {
      window.open(url);
    },
    createPlan() {
      if(!this.editedItem.contract_id) {
        this.showAdvise = true;
        return;
      } else {
        this.showAdvise = false;
      }
      const params = {
        start: this.range.start,
        end: this.range.end,
        ...this.editedItem,
      };

      this.loading = true;

      this.$http
        .post(`/bills/plan/plans_geral`, params)
        .then((response) => response.data)
        .then((json) => {
          this.$notify({
            title: `Planilhas geradas com sucesso!`,
            group: "notifications",
            type: "success",
          });

          this.links = json.links;
          this.loading = false;
        })
        .catch((err) => {
          this.$reqError(`Erro ao gerar Planilhas!`, err, this);
        });

      /*window.open(
        `/bills/plan/plans_geral?` +
          Object.entries(params)
            .map(e => e.join("="))
            .join("&")
      );*/
    },
  },
};
</script>


<style scoped>
.red__advise {
  color: red;
  font-size: 1.2rem;
}
</style>
