<template>
  <v-container grid-list-md text-xs-center px-0>
    <notifications position="bottom center" />
    <v-layout row wrap>
      <v-flex xs12>
        <v-toolbar flat color="white" class="elevation-1 mb-4">
          <v-toolbar-title>Relatório de Consumo Mensal</v-toolbar-title>
          <v-divider class="mx-2" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <v-text-field
            v-model.lazy="search"
            append-icon="search"
            label="Buscar"
            single-line
            hide-details
            class="hidden-md-and-down"
            @click:append="getList()"
              v-on:keyup.enter="getList"
          ></v-text-field>
          <v-spacer></v-spacer>
          <div>
            {{formatYear(range.year)}}
            <v-icon class="mr-2" @click="range.modal = true">edit</v-icon>
          </div>

          <v-menu
            v-model="menuFilter.menu"
            bottom
            left
            :close-on-content-click="false"
            max-width="400"
            min-width="400"
            offset-x
          >
            <v-btn slot="activator" color="primary">OPÇÕES</v-btn>

            <v-card>
              <v-card-title class="px-1 py-1">
                FILTRO
                <v-spacer></v-spacer>
                <v-btn
                  outline
                  light
                  small
                  color="primary"
                  @click="menuFilter.menu = true; filtro = {};"
                >Limpar</v-btn>
              </v-card-title>

              <v-layout row wrap>
                <v-flex xs12 class="px-1">
                  <select-custom
                    :url="'/categories/list?sortBy=name&office=' + $route.params.type"
                    v-model="filtro.category_id"
                    label="Categoria"
                  />
                </v-flex>
                <v-flex xs12 class="px-1">
                  <select-custom
                    :url="'/subcategories/list?sortBy=name&office=' + $route.params.type"
                    v-model="filtro.subcategory_id"
                    label="SubCategoria"
                  />
                </v-flex>
                <v-flex xs12 class="px-1">
                  <select-custom
                    :url="'/products/list?sortBy=name&office=' + $route.params.type"
                    v-model="filtro.product_id"
                    label="Produto"
                  />
                </v-flex>
                <v-flex xs12 class="px-1">
                  <select-custom
                    :url="`/sectors/listall?sortBy=name&office=${$route.params.type}`"
                    v-model="filtro.sector_id"
                    label="Estoque"
                  />
                </v-flex>
                <!--<v-flex xs12 class="px-1">
                  <v-autocomplete
                    label="Setor de destino"
                    v-model="filtro.sector_destiny"
                    :items="sector_destinys"
                  ></v-autocomplete>
                </v-flex>-->
              </v-layout>

              <v-card-actions>
                <v-spacer></v-spacer>

                <v-btn flat @click="menuFilter.menu = false">Fechar</v-btn>
                <v-btn color="primary" flat @click="filtrar()">Filtrar</v-btn>
              </v-card-actions>
            </v-card>
          </v-menu>

          <v-btn dark color="blue" @click="goReport()">IMPRIMIR</v-btn>
          <v-btn dark color="orange" @click="goExternal('/retiradas/report_position/' + $route.params.type)">POSIÇÃO DE ESTOQUE</v-btn>
        </v-toolbar>

        <v-data-table
          :headers="columns"
          :items="itemsFilter"
          :loading="loading"
          hide-actions
          disable-initial-sort
          class="elevation-1"
        >
          <template slot="items" slot-scope="props">
            <tr>
              <td>{{ props.item.name}}</td>
              <td>{{ props.item.code}}</td>
              <td>{{ props.item.jan || 0}}</td>
              <td>{{ props.item.fev || 0}}</td>
              <td>{{ props.item.mar || 0}}</td>
              <td>{{ props.item.abr || 0}}</td>
              <td>{{ props.item.mai || 0}}</td>
              <td>{{ props.item.jun || 0}}</td>
              <td>{{ props.item.jul || 0}}</td>
              <td>{{ props.item.ago || 0}}</td>
              <td>{{ props.item.set || 0}}</td>
              <td>{{ props.item.out || 0}}</td>
              <td>{{ props.item.nov || 0}}</td>
              <td>{{ props.item.dez || 0}}</td>
              <td>{{ props.item.med}} / {{ props.item.total}}</td>
            </tr>
          </template>

          <template slot="no-data">Desculpe, nenhum dado para ser exibido!</template>
        </v-data-table>
      </v-flex>
      <!--<v-flex xs12 class="text-xs-right">
        <h4>Média/Total Geral {{this.med}}/{{total}}</h4>
      </v-flex>-->
    </v-layout>

    <v-dialog v-model="range.modal" scrollable max-width="350px">
      <v-card>
        <v-card-title>
          <span class="headline">Período</span>
        </v-card-title>
        <v-card-text>
          <v-layout row wrap>
            <v-flex xs12>
              <v-menu
                ref="menuStart"
                :close-on-content-click="false"
                v-model="range.menuStart"
                :nudge-right="40"
                :return-value.sync="range.year"
                lazy
                transition="scale-transition"
                offset-y
                full-width
                min-width="290px"
              >
                <v-text-field
                  slot="activator"
                  v-model="computedYear"
                  label="Ano"
                  prepend-icon="event"
                  readonly
                ></v-text-field>
                <v-date-picker v-model="range.year" no-title scrollable locale="pt-br" type="month">
                  <v-spacer></v-spacer>
                  <v-btn flat color="grey" @click="range.menuStart = false">Fechar</v-btn>
                  <v-btn color="primary" @click="$refs.menuStart.save(range.year)">OK</v-btn>
                </v-date-picker>
              </v-menu>
            </v-flex>
          </v-layout>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="grey" flat @click="range.modal = false">Fechar</v-btn>
          <v-btn color="primary" @click="range.modal = false; getList()">OK</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import moment from "moment";
import _ from "lodash";
export default {
  data() {
    console.log(moment().startOf("month").format("YYYY-MM-DD"));
    return {
      range: {
        year: moment().startOf("month").format("YYYY-MM-DD"),
        modal: false,
      },
      search: "",
      items: [],
      itemsFilter: [],
      menuFilter: { menu: false },
      filtro: {
        category_id: null,
        subcategory_id: null,
        product_id: null,
        sector_id: null,
        sector_destiny: null,
      },
      sector_destinys: [],
      columns: [
        { value: "name", text: "Produto" },
        { value: "code", text: "Código" },
        {
          value: "jan",
          text: "Jan",
          class: ["py-0", "px-0", "text-xs-center"],
        },
        {
          value: "fev",
          text: "Fev",
          class: ["py-0", "px-0", "text-xs-center"],
        },
        {
          value: "mar",
          text: "Mar",
          class: ["py-0", "px-0", "text-xs-center"],
        },
        {
          value: "abr",
          text: "Abr",
          class: ["py-0", "px-0", "text-xs-center"],
        },
        {
          value: "mai",
          text: "Mai",
          class: ["py-0", "px-0", "text-xs-center"],
        },
        {
          value: "jun",
          text: "Jun",
          class: ["py-0", "px-0", "text-xs-center"],
        },
        {
          value: "jul",
          text: "Jul",
          class: ["py-0", "px-0", "text-xs-center"],
        },
        {
          value: "ago",
          text: "Ago",
          class: ["py-0", "px-0", "text-xs-center"],
        },
        {
          value: "set",
          text: "Set",
          class: ["py-0", "px-0", "text-xs-center"],
        },
        {
          value: "out",
          text: "Out",
          class: ["py-0", "px-0", "text-xs-center"],
        },
        {
          value: "nov",
          text: "Nov",
          class: ["py-0", "px-0", "text-xs-center"],
        },
        {
          value: "dez",
          text: "Dez",
          class: ["py-0", "px-0", "text-xs-center"],
        },
        {
          value: "total",
          text: "Méd/Total",
          class: ["py-0", "px-0", "text-xs-center"],
        },
      ],
      loading: false,
      total: 0,
      med: 0,
    };
  },
  watch: {
    "$route.params.type": {
      handler() {
        this.getList();
      },
    },
  },
  computed: {
    computedYear() {
      return this.formatYear(this.range.year);
    },
  },
  created() {
    this.initialize();
  },
  methods: {
    initialize() {
      this.getList();
      this.getSectorDestinys();
    },
    getSectorDestinys() {
      this.$http
        .get(`/sectors/list_destiny`)
        .then((response) => response.data)
        .then((json) => {
          this.sector_destinys = json;
        });
    },
    getList() {
      this.loading = true;
      return new Promise((resolve, reject) => {
        const params = {
          year: this.computedYear,
          ...this.filtro,
        };

        this.$http
          .get(`/retiradas/report_consumo/${this.$route.params.type}`, {
            params,
          })
          .then((response) => response.data)
          .then((json) => {
            setTimeout(() => {
              this.total = 0;
              this.med = 0;
              this.loading = false;
              json.items.forEach((i) => {
                i.total = 0;
                i.total += i.jan || 0;
                i.total += i.fev || 0;
                i.total += i.mar || 0;
                i.total += i.abr || 0;
                i.total += i.mai || 0;
                i.total += i.jun || 0;
                i.total += i.jul || 0;
                i.total += i.ago || 0;
                i.total += i.set || 0;
                i.total += i.out || 0;
                i.total += i.nov || 0;
                i.total += i.dez || 0;

                this.total += i.total;
                this.med += i.total / 12;

                i.med = parseFloat(i.total / 12)
                  .toFixed(2)
                  .replace(/[.,]00$/, "");
              });

              this.items = json.items;
              this.itemsFilter = json.items;
              //console.log(this.items);
            }, 1000);
          })
          .catch(() => (this.loading = false));
      });
    },
    goExternal(url) {
      var win = window.open(url, "_blank");
      win.focus();
    },
    filtrar() {
      this.menuFilter.menu = false;
      this.getList();
    },
    formatYear: (date) => (date ? moment(date).format("YYYY") : ""),
    normalized(string) {
      string = string + "";
      string = string.trim().toLowerCase();
      const map = {
        a: /[\xE0-\xE6]/g,
        A: /[\xC0-\xC6]/g,
        e: /[\xE8-\xEB]/g,
        E: /[\xC8-\xCB]/g,
        i: /[\xEC-\xEF]/g,
        I: /[\xCC-\xCF]/g,
        o: /[\xF2-\xF6]/g,
        O: /[\xD2-\xD6]/g,
        u: /[\xF9-\xFC]/g,
        U: /[\xD9-\xDC]/g,
        c: /\xE7/g,
        C: /\xC7/g,
        n: /\xF1/g,
        N: /\xD1/g,
      };

      for (const key in map) {
        if (map.hasOwnProperty(key)) {
          string = string.replace(map[key], map);
        }
      }

      return string;
    },
    goReport() {
      let category = "";
      if (this.filtro.category_id)
        category = `&category_id=${this.filtro.category_id}`;

      let subcategory = "";
      if (this.filtro.subcategory_id)
        subcategory = `&subcategory_id=${this.filtro.subcategory_id}`;

      let product = "";
      if (this.filtro.product_id)
        product = `&product_id=${this.filtro.product_id}`;

      let sector = "";
      if (this.filtro.sector_id) sector = `&sector_id=${this.filtro.sector_id}`;

      let sector_destiny = "";
      if (this.filtro.sector_destiny)
        sector_destiny = `&sector_destiny=${this.filtro.sector_destiny}`;

      let url = `/retiradas/report_consumo/${this.$route.params.type}?print=1&year=${this.computedYear}
        ${category}${subcategory}${product}${sector}${sector_destiny}`;
      window.open(url, "_blank");
    },
    goReportPosition(){
      let category = "";
      if (this.filtro.category_id)
        category = `&category_id=${this.filtro.category_id}`;

      let subcategory = "";
      if (this.filtro.subcategory_id)
        subcategory = `&subcategory_id=${this.filtro.subcategory_id}`;

      let product = "";
      if (this.filtro.product_id)
        product = `&product_id=${this.filtro.product_id}`;

      let sector = "";
      if (this.filtro.sector_id) sector = `&sector_id=${this.filtro.sector_id}`;

      let sector_destiny = "";
      if (this.filtro.sector_destiny)
        sector_destiny = `&sector_destiny=${this.filtro.sector_destiny}`;

      let url = `/retiradas/report_position/${this.$route.params.type}?print=1&year=${this.computedYear}
        ${category}${subcategory}${product}${sector}${sector_destiny}`;
      window.open(url, "_blank");
    },
    debounceInput: _.debounce(function () {
      setTimeout(() => {
        const q = this.normalized(this.search);
        this.itemsFilter = this.items.filter((item) => {
          return this.normalized(item.name).match(q);
        });
      }, 200);
    }, 400),
  },
};
</script>
