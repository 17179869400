<template>
  <div>
    <!---->
    <v-container grid-list-md text-xs-center px-0>
      <notifications position="bottom center" />
      <v-layout row wrap>
        <v-flex xs12>
          <v-toolbar flat color="white" class="elevation-1">
            <v-toolbar-title>Devoluções</v-toolbar-title>
            <v-divider class="mx-2" inset vertical></v-divider>
            <v-spacer></v-spacer>
            <v-text-field
              v-model.lazy="search"
              append-icon="search"
              label="Buscar"
              single-line
              hide-details
              class="hidden-md-and-down"
              @click:append="getList()"
              v-on:keyup.enter="getList"
            ></v-text-field>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              dark
              class="mb-2"
              @click="openDevolutionForm({office: $route.params.office})"
            >Nova</v-btn>
          </v-toolbar>

          <v-data-table
            :headers="columns"
            :items="items"
            :pagination.sync="pagination"
            :total-items="pagination.totalItems"
            :loading="loading"
            hide-actions
            class="elevation-1"
          >
            <template slot="items" slot-scope="props">
              <tr>
                <td>{{ props.item.id }}</td>
                <td>{{ props.item.user && props.item.user.name }}</td>
                <td>{{ props.item.sector && props.item.sector.name }}</td>
                <td>
                  <span v-for="(value, i) in props.item.products" :key="i">{{i}}({{value}});</span>
                </td>
                <td>{{ props.item.obs }}</td>
                <td>{{ props.item.created_at | formatDateTime}}</td>
                <td class="text-xs-center px-0">
                  <v-icon small @click="openDevolutionView(props.item)">fas fa-eye</v-icon>
                </td>
              </tr>
            </template>

            <template slot="no-data">Desculpe, nenhum dado para ser exibido!</template>
          </v-data-table>
          <div class="text-xs-center pt-2">
            <v-pagination v-model="pagination.page" :length="pages"></v-pagination>
          </div>
        </v-flex>
      </v-layout>
    </v-container>

    <div v-if="devolutionFormActive.open">
      <devolution-pack-form
        :active="devolutionFormActive.open"
        :item="devolutionFormActive.item"
        :finish="onCloseDevolutionForm"
      />
    </div>

    <div v-if="devolutionViewActive.open">
      <devolution-view
        :active="devolutionViewActive.open"
        :item="devolutionViewActive.item"
        :finish="onCloseDevolutionView"
      />
    </div>
  </div>
</template>

<script>
import _ from "lodash";
export default {
  data() {
    return {
      search: "",
      pagination: {
        totalItems: 0,
        rowsPerPage: 10,
        descending: true,
        page: 1,
        sortBy: "id",
      },
      items: [],
      columns: [
        { value: "id", text: "ID" },
        { value: "user", text: "Usuário", sortable: false },
        { value: "sector", text: "Estoque", sortable: false },
        { value: "products", text: "Produtos", sortable: false },
        { value: "obs", text: "Obs" },
        { value: "created_at", text: "Data" },
        { value: "", text: "", sortable: false },
      ],
      devolutionFormActive: {
        open: false,
        item: {},
      },
      devolutionViewActive: { open: false, item: {} },
      loading: false,
    };
  },
  computed: {
    pages() {
      if (
        this.pagination.rowsPerPage == null ||
        this.pagination.totalItems == null
      )
        return 0;

      return Math.ceil(
        this.pagination.totalItems / this.pagination.rowsPerPage
      );
    },
  },
  watch: {
    "pagination.page": {
      handler() {
        this.getList();
      },
      deep: true,
    },
  },
  created() {
    this.initialize();
  },
  methods: {
    initialize() {},
    getList() {
      this.loading = true;
      return new Promise((resolve, reject) => {
        const params = {
          ...this.pagination,
          totalItems: undefined,
          search: this.search,
          office: this.$route.params.office,
        };
        this.$http
          .get(`/devolution_packs/list`, { params })
          .then((response) => response.data)
          .then((json) => {
            setTimeout(() => {
              this.loading = false;

              this.items = json.items.map((o) => {
                var counts = o.devolutions.reduce((p, c) => {
                  var name = c.product.name;
                  if (!p.hasOwnProperty(name)) {
                    p[name] = 0;
                  }
                  p[name]++;
                  return p;
                }, {});

                return {
                  ...o,
                  products: counts,
                };
              });
              this.pagination.totalItems = json.total;
            }, 1000);
          })
          .catch(() => (this.loading = false));
      });
    },
    del(id) {},
    debounceInput: _.debounce(function () {
      setTimeout(() => {
        this.getList();
      }, 200);
    }, 400),
    openDevolutionForm(item) {
      this.devolutionFormActive.open = true;
      this.devolutionFormActive.item = item;
    },
    onCloseDevolutionForm() {
      this.devolutionFormActive.open = false;
      this.getList();
    },
    openDevolutionView(item) {
      this.devolutionViewActive.open = true;
      this.devolutionViewActive.item = item;
    },
    onCloseDevolutionView() {
      this.devolutionViewActive.open = false;
    },
  },
};
</script>
