<template>
  <div>
    <!---->
    <v-container grid-list-md text-xs-center fluid>
      <v-layout row wrap>
        <v-flex xs12>
          <v-toolbar flat color="white" class="elevation-1 mb-4">
            <v-toolbar-title>Lotes em vencimento</v-toolbar-title>
            <v-divider class="mx-2" inset vertical></v-divider>
            <v-spacer></v-spacer>
            <div>
              Com vencimento até {{formatDate(range.due_date)}}
              <v-icon class="mr-2" @click="range.modal = true">edit</v-icon>
            </div>

            <v-menu
              v-model="menuFilter.menu"
              bottom
              left
              :close-on-content-click="false"
              max-width="400"
              min-width="400"
              offset-x
            >
              <v-btn slot="activator" color="primary">OPÇÕES</v-btn>

              <v-card>
                <v-card-title class="px-1 py-1">
                  FILTRO
                  <v-spacer></v-spacer>
                  <v-btn
                    outline
                    light
                    small
                    color="primary"
                    @click="menuFilter.menu = true; filtro = {};"
                  >Limpar</v-btn>
                </v-card-title>

                <v-layout row wrap>
                  <v-flex xs12 class="px-1">
                    <select-custom
                      url="/categories/list?sortBy=name"
                      v-model="filtro.category_id"
                      label="Categoria"
                    />
                  </v-flex>
                  <v-flex xs12 class="px-1">
                    <select-custom
                      url="/subcategories/list?sortBy=name"
                      v-model="filtro.subcategory_id"
                      label="SubCategoria"
                    />
                  </v-flex>
                  <v-flex xs12 class="px-1">
                    <select-custom
                      url="/products/list?sortBy=name"
                      v-model="filtro.product_id"
                      label="Produto"
                    />
                  </v-flex>
                  <v-flex xs12 class="px-1">
                    <v-text-field
                      v-model="filtro.lote"
                      label="Lote"
                    />
                  </v-flex>
                </v-layout>

                <v-card-actions>
                  <v-spacer></v-spacer>

                  <v-btn flat @click="menuFilter.menu = false">Fechar</v-btn>
                  <v-btn color="primary" flat @click="filtrar()">Filtrar</v-btn>
                </v-card-actions>
              </v-card>
            </v-menu>
          </v-toolbar>

          <v-data-table
            :headers="columns"
            :items="items"
            :loading="loading"
            hide-actions
            disable-initial-sort
            class="elevation-1"
          >
            <template slot="items" slot-scope="props">
              <tr>
                <td>{{ props.item.product && props.item.product.name}}</td>
                <td>{{ props.item.due_date | formatDate}}</td>
                <td>{{ props.item.code}}</td>
                <td>{{ props.item.single_products_count }}</td>
                <td>{{ props.item.estoque }}</td>
                <td>{{ props.item.cc }}</td>
                <td>{{ props.item.ps }}</td>
              </tr>
            </template>

            <template slot="no-data">Desculpe, nenhum dado para ser exibido!</template>
          </v-data-table>
        </v-flex>
      </v-layout>

      <v-dialog v-model="range.modal" scrollable max-width="350px">
        <v-card>
          <v-card-title>
            <span class="headline">Período</span>
          </v-card-title>
          <v-card-text>
            <v-layout row wrap>
              <v-flex xs12>
                <v-menu
                  ref="menuEnd"
                  :close-on-content-click="false"
                  v-model="range.menuEnd"
                  :nudge-right="40"
                  :return-value.sync="range.due_date"
                  lazy
                  transition="scale-transition"
                  offset-y
                  full-width
                  min-width="290px"
                >
                  <v-text-field
                    slot="activator"
                    v-model="computedDueDate"
                    label="Data Final"
                    prepend-icon="event"
                    readonly
                  ></v-text-field>
                  <v-date-picker v-model="range.end" no-title scrollable locale="pt-br">
                    <v-spacer></v-spacer>
                    <v-btn flat color="grey" @click="range.menuEnd = false">Fechar</v-btn>
                    <v-btn color="primary" @click="$refs.menuEnd.save(range.end)">OK</v-btn>
                  </v-date-picker>
                </v-menu>
              </v-flex>
            </v-layout>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="grey" flat @click="range.modal = false">Fechar</v-btn>
            <v-btn color="primary" @click="range.modal = false; getList()">OK</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>
  </div>
</template>

<script>
import moment from "moment";
import _ from "lodash";
export default {
  data() {
    return {
      range: {
        due_date: moment()
          .add(1, "month")
          .format("YYYY-MM-DD"),
        modal: false
      },
      items: [],
      itemsEntrada: [],
      columns: [
        { value: "product.name", text: "Produto", sortable: false },
        { value: "due_date", text: "Vencimento" },
        { value: "code", text: "Lote", sortable: false },
        { value: "qty", text: "Total", sortable: false },
        { value: "estoque", text: "Estoque", sortable: false },
        { value: "cc", text: "F. CENTRO CIRÚRGICO", sortable: false },
        { value: "ps", text: "FARMÁCIA PS", sortable: false },
      ],
      loading: false,
      menuFilter: { menu: false },
      filtro: {
        category_id: null,
        subcategory_id: null,
        product_id: null,
        lote: null
      }
    };
  },
  computed: {
    computedDueDate() {
      return this.formatDate(this.range.due_date);
    }
  },
  created() {
    this.initialize();
  },
  methods: {
    initialize() {
      this.getList();
    },
    getList() {
      this.loading = true;
      return new Promise((resolve, reject) => {
        const params = {
          ...this.range,
          modal: undefined,
          ...this.filtro,
          office: this.$route.params.office
        };
        this.$http
          .get(`/lotes/report_expiration`, { params })
          .then(response => response.data)
          .then(json => {
            setTimeout(() => {
              this.loading = false;
              this.items = json.items;
            }, 1000);
          })
          .catch(() => (this.loading = false));
      });
    },
    filtrar() {
      this.menuFilter.menu = false;
      this.getList();
    },
    formatDate: date => (date ? moment(date).format("DD/MM/YYYY") : ""),
    debounceInput: _.debounce(function() {
      setTimeout(() => {
        this.getList();
      }, 200);
    }, 400)
  }
};
</script>
