<template>
  <div>
    <v-container grid-list-md text-xs-center px-0>
      <notifications position="bottom center" />
      <v-layout row wrap>
        <v-flex xs12>
          <v-toolbar flat color="white" class="elevation-1">
            <v-toolbar-title>Prestação de Serviços</v-toolbar-title>
            <v-divider class="mx-2" inset vertical></v-divider>
            <!-- <v-spacer></v-spacer> -->
            <v-text-field
              class="mr-5 mt-4"
              type="date"
              v-model.lazy="searchData"
              label="Buscar por Data"
              @click:append="getList()"
              v-on:keyup.enter="getList"
            ></v-text-field>
            <v-text-field
              v-model.lazy="search"
              append-icon="search"
              label="Buscar"
              single-line
              hide-details
              class="hidden-sm-and-down"
              @click:append="getList()"
              v-on:keyup.enter="getList"
            ></v-text-field>
            <v-spacer></v-spacer>
            <v-btn
              @click="serviceFormOpen({})"
              color="primary"
              dark
              class="mb-2"
              >Novo</v-btn
            >
          </v-toolbar>

          <v-layout wrap row class="hidden-md-and-up">
            <v-flex xs12>
              <v-text-field
                v-model.lazy="search"
                append-icon="search"
                label="Buscar"
                single-line
                hide-details
                @click:append="getList()"
                v-on:keyup.enter="getList"
              ></v-text-field>
            </v-flex>
          </v-layout>

          <v-data-table
            :headers="columns"
            :items="items"
            :pagination.sync="pagination"
            :total-items="pagination.totalItems"
            :loading="loading"
            hide-actions
            class="elevation-1"
          >
            <template slot="items" slot-scope="props">
              <td>{{ props.item.id }}</td>
              <td>{{ props.item.provider && props.item.provider.name }}</td>
              <td>{{ props.item.obs }}</td>
              <td>{{ props.item.document }}</td>
              <td>{{ props.item.price | formatMoney }}</td>
              <td>{{ props.item.installments }}</td>
              <td>{{ props.item.date | formatDate }}</td>
              <td class="text-xs-center px-0">
                <v-icon
                  small
                  class="mr-2"
                  v-if="props.item.bill && !props.item.bill.paid"
                  @click="serviceFormOpen(props.item)"
                >
                  edit
                </v-icon>
                <v-icon
                  small
                  v-if="
                    user.type == 1 && props.item.bill && !props.item.bill.paid
                  "
                  @click="del(props.item.id)"
                >
                  delete
                </v-icon>
              </td>
              <td
                class="text-xs-center px-0"
                v-if="
                  user.modules.includes('EDITAR_PRESTACAO_SERVICO') ||
                    user.type == 1
                "
              >
                <v-menu offset-y>
                  <template v-slot:activator="{ on }">
                    <v-btn icon v-on="on">
                      <v-icon small>fas fa-ellipsis-v</v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-tile @click="serviceFormOpen(props.item)">
                      <v-list-tile-avatar>
                        <v-icon>fas fa-edit</v-icon>
                      </v-list-tile-avatar>
                      <v-list-tile-title>Editar Nota</v-list-tile-title>
                    </v-list-tile>
                  </v-list>
                </v-menu>
              </td>
            </template>

            <template slot="no-data">
              Desculpe, nenhum dado para ser exibido!
            </template>
          </v-data-table>
          <div class="text-xs-center pt-2">
            <v-pagination
              v-model="pagination.page"
              :length="pages"
            ></v-pagination>
          </div>
        </v-flex>
      </v-layout>
    </v-container>

    <div v-if="serviceFormActive.open">
      <service-form
        :active="serviceFormActive.open"
        :item="serviceFormActive.item"
        :finish="serviceFormClose"
      ></service-form>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
export default {
  data() {
    return {
      dialog: false,
      search: "",
      searchData: "",
      user: USER,
      pagination: {
        totalItems: 0,
        rowsPerPage: 10,
        descending: true,
        page: 1,
        sortBy: "id"
      },
      items: [],
      columns: [
        { value: "id", text: "ID" },
        { value: "provider", text: "Fornecedor", sortable: false },
        { value: "obs", text: "Descrição", sortable: false },
        { value: "document", text: "Nº Nota Fiscal" },
        { value: "price", text: "Valor" },
        { value: "installments", text: "Meses" },
        { value: "date", text: "Data" },
        { value: "", text: "", sortable: false }
      ],
      serviceFormActive: { open: false, item: {} },
      loading: false
    };
  },
  computed: {
    pages() {
      if (
        this.pagination.rowsPerPage == null ||
        this.pagination.totalItems == null
      )
        return 0;
      return Math.ceil(
        this.pagination.totalItems / this.pagination.rowsPerPage
      );
    }
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
    "pagination.page": {
      handler() {
        this.getList();
      },
      deep: true
    },
    search: function() {
      this.debounceInput();
    },
    searchData: function() {
      this.debounceInput();
    }
  },
  created() {
    this.initialize();
  },
  methods: {
    initialize() {},
    getList() {
      this.loading = true;
      return new Promise((resolve, reject) => {
        const params = {
          ...this.pagination,
          totalItems: undefined,
          search: this.search,
          searchData: this.searchData
        };
        this.$http
          .get(`/services/list`, { params })
          .then(response => response.data)
          .then(json => {
            setTimeout(() => {
              this.loading = false;

              this.items = json.items;
              this.pagination.totalItems = json.total;
            }, 1000);
          })
          .catch(() => (this.loading = false));
      });
    },
    serviceFormOpen(item) {
      this.serviceFormActive.open = true;
      this.serviceFormActive.item = item;
    },
    serviceFormClose() {
      this.serviceFormActive.open = false;
      this.getList();
    },
    del(id) {
      if (confirm("Deseja realmente excluir este Serviço?")) {
        this.$http.delete(`/services/${id}`).then(response => {
          this.$notify({ title: `Serviço Excluido!`, type: "success" });
          this.getList();
        });
      }
    },
    debounceInput: _.debounce(function() {
      setTimeout(() => {
        this.getList();
      }, 200);
    }, 400)
  }
};
</script>
