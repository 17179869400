<template>
  <div>
    <!--<header-page />-->
    <v-container grid-list-md text-xs-center px-0>
      <v-layout row wrap>
        <v-flex xs12 class="px-0 py-0">
          <v-toolbar flat color="white" class="elevation-1">
            <v-toolbar-title>
              Relatório BPA
            </v-toolbar-title>
            <v-divider class="mx-2" inset vertical></v-divider>
            <v-spacer></v-spacer>
            <v-select
              v-model="year"
              :items="[
                { text: '2021' },
                { text: '2022' },
                { text: '2023' },
                { text: '2024' },
                { text: '2025' },
                { text: '2026' },
                { text: '2027' },
                { text: '2028' },
                { text: '2029' },
                { text: '2030' }
              ]"
              label="Ano"
              dense
            ></v-select>
            <v-spacer></v-spacer>
            <v-select
              v-model="month"
              :items="[
                { text: 'Janeiro', value: '01' },
                { text: 'Fevereiro', value: '02' },
                { text: 'Março', value: '03' },
                { text: 'Abril', value: '04' },
                { text: 'Maio', value: '05' },
                { text: 'Junho', value: '06' },
                { text: 'Julho', value: '07' },
                { text: 'Agosto', value: '08' },
                { text: 'Setembro', value: '09' },
                { text: 'Outubro', value: '10' },
                { text: 'Novembro', value: '11' },
                { text: 'Dezembro', value: '12' }
              ]"
              label="Mês"
              dense
            ></v-select>
            <v-spacer></v-spacer>
            <v-btn
              fab
              small
              style="padding: 10px 0;"
              color="primary"
              @click="getBpa()"
            >
              <v-icon dark>fas fa-file-alt</v-icon>
            </v-btn>
          </v-toolbar>
        </v-flex>
      </v-layout>
    </v-container>

    <v-dialog
      v-model="dialogPlace.open"
      scrollable
      max-width="400px"
      persistent
    >
      <v-card>
        <v-card-title>
          <span class="headline">Digite o número da sua sala</span>
        </v-card-title>
        <v-card-text>
          <v-form v-model="dialogPlace.valid" xs12>
            <v-layout row wrap>
              <v-flex xs12>
                <v-text-field
                  v-model="dialogPlace.place"
                  label="SALA"
                  :rules="[v => !!v || 'SALA é obrigatório!']"
                  required
                  v-on:keypress="isNumber"
                ></v-text-field>
              </v-flex>
            </v-layout>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            @click="savePlace()"
            :disabled="!dialogPlace.valid"
            >Salvar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import moment from "moment";
import { TIMES } from "../../constants";

export default {
  data() {
    return {
      USER: USER,
      search: "",
      time: moment().format("YYYY-MM-DD"),
      isToday: true,
      isThreeDays: false,
      times: TIMES,
      menu1: false,
      loading: false,
      dialogPlace: { open: false, valid: false, place: null },
      place: localStorage.getItem("place"),
      today: moment().format("YYYY-MM-DD"),
      month: moment().format("MM"),
      year: moment().format("YYYY"),
      items: ["Foo", "Bar", "Fizz", "Buzz"],
      pagination: {
        totalItems: 0,
        rowsPerPage: 30,
        descending: false,
        page: 1,
        sortBy: "created_at"
      },
      returnPagination: {
        totalItems: 0,
        rowsPerPage: 30,
        descending: false,
        page: 1,
        sortBy: "created_at"
      }
    };
  },
  watch: {
    "pagination.page": {
      handler() {},
      deep: true
    }
  },
  computed: {
    pages() {
      if (
        this.pagination.rowsPerPage == null ||
        this.pagination.totalItems == null
      )
        return 0;
      return Math.ceil(
        this.pagination.totalItems / this.pagination.rowsPerPage
      );
    }
  },
  created() {
    this.initialize();
  },
  methods: {
    initialize() {},
    getBpa() {
      this.loading = true;
      console.log(this.year, this.month);
      window.location.href = `/reportbpa?ano=${this.year}&mes=${this.month}`;
      this.loading = false;
    },
    getDate(date) {
      if (!date) return "";
      var date = new Date(date);
      return date.toLocaleTimeString("pt-BR", {
        timeZone: "America/Sao_Paulo"
      });
    },
    formatDate: date => moment(date).format("DD/MM/YYYY"),
    //today: () => moment().format("DD/MM/YYYY"),
    formatHorary(date) {
      if (date) {
        return moment(date).format("HH:mm");
      } else return "";
    },
    isNumber: function(evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    }
  }
};
</script>
