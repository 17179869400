<template>
  <div>
    <v-layout row wrap>
      <v-flex xs12>
        <h2>
          Relátorio Customizado -
          <small v-if="filtro.bank_id">
            {{banks.find(o => o.value == filtro.bank_id).text}} -
          </small>
          <small
            v-if="formatDate(range.start) == formatDate(range.end)"
          >{{formatDate(range.start)}}</small>
          <small v-else>{{formatDate(range.start)}} até {{formatDate(range.end)}}</small>
        </h2>
        <v-layout row wrap class="no-print" align-center justify-center>
          <v-flex xs4>
            {{formatDate(range.start)}} - {{formatDate(range.end)}}
            <v-icon class="mr-2" @click="range.modal = true">edit</v-icon>
          </v-flex>

          <v-flex xs8 class="text-xs-right">
            <v-menu
              v-model="menuFilter.menu"
              bottom
              left
              :close-on-content-click="false"
              max-width="400"
              min-width="400"
              offset-x
            >
              <v-btn slot="activator" color="primary"> OPÇÕES </v-btn>

              <v-card>
                <v-card-title class="px-1 py-1">FILTRO
                  <v-spacer></v-spacer>
                  <v-btn
                    outline
                    light
                    small
                    color="primary"
                    @click="menuFilter.menu = true; filtro = {};"
                  >Limpar</v-btn>
                </v-card-title>

                <v-layout row wrap>
                  <v-flex
                    xs12
                    class="px-1"
                  >
                    <v-autocomplete
                      v-model="filtro.contract_id"
                      :items="contracts"
                      label="Contrato"
                    ></v-autocomplete>
                  </v-flex>
                  <v-flex xs12 class="px-1">
                    <v-autocomplete
                      v-model="filtro.bank_id"
                      :items="banks"
                      label="Banco"
                    ></v-autocomplete>
                  </v-flex>
                  <v-flex xs12 class="px-1">
                  <v-select
                    v-model="filtro.paid"
                    :items="[{value: 1, text: 'Pago'}, {value: 0, text: 'Pendente'}]"
                    label="Status"
                  ></v-select>
                </v-flex>
                </v-layout>

                <v-card-actions>
                  <v-spacer></v-spacer>

                  <v-btn flat @click="menuFilter.menu = false">Fechar</v-btn>
                  <v-btn color="primary" flat @click="filtrar()">Filtrar</v-btn>
                </v-card-actions>
              </v-card>
            </v-menu>
          </v-flex>
        </v-layout>

        <v-layout row wrap class="mt-2 px-2">
          <v-flex xs6>
            <b>SALDO ANTERIOR</b>
          </v-flex>
          <v-flex xs6 class="text-xs-right">
            <b>{{anterior | formatMoney}}</b>
          </v-flex>
        </v-layout>

        <v-data-table
          :headers="columns"
          :items="items"
          :loading="loading"
          hide-actions
          disable-initial-sort
          class="elevation-1"
        >
          <template slot="items" slot-scope="props">
            <tr>
              <td v-if="tipo == 1">{{ props.item.paid_date | formatDate }}</td>
              <td v-else>{{ props.item.due_date | formatDate }}</td>
              <td>
                {{ props.item.provider && props.item.provider.name }}
              </td>
              <td>{{ props.item.description }}</td>
              <td>{{ props.item.bill_type ? props.item.bill_type.code + ' - ' + props.item.bill_type.name : '' }}</td>
              <td> {{ props.item.doc_number }} </td>
              <td>{{ props.item.price | formatMoney}} {{props.item.type == 'Receita' ? 'C' : 'D'}} </td>
              <td>{{ props.item.total | formatMoney}}</td>
            </tr>
          </template>

          <template slot="no-data">Desculpe, nenhum dado para ser exibido!</template>
        </v-data-table>
      </v-flex>
    </v-layout>
    <!--<v-layout row wrap v-if="items.length" class="py-2 px-2">
      <v-flex xs6>
        <b>TOTAL</b>
      </v-flex>

      <v-flex xs6 class="text-xs-right">
        <b>{{total | formatMoney}}</b>
      </v-flex>
    </v-layout>-->

    <v-dialog v-model="range.modal" scrollable max-width="350px">
      <v-card>
        <v-card-title>
          <span class="headline">Período</span>
        </v-card-title>
        <v-card-text>
          <v-layout row wrap>
            <v-flex xs12>
              <v-menu
                ref="menuStart"
                :close-on-content-click="false"
                v-model="range.menuStart"
                :nudge-right="40"
                :return-value.sync="range.start"
                lazy
                transition="scale-transition"
                offset-y
                full-width
                min-width="290px"
              >
                <v-text-field
                  slot="activator"
                  v-model="computedStart"
                  label="Data inicial"
                  prepend-icon="event"
                  readonly
                ></v-text-field>
                <v-date-picker v-model="range.start" no-title scrollable locale="pt-br">
                  <v-spacer></v-spacer>
                  <v-btn flat color="grey" @click="range.menuStart = false">Fechar</v-btn>
                  <v-btn color="primary" @click="$refs.menuStart.save(range.start)">OK</v-btn>
                </v-date-picker>
              </v-menu>

              <v-menu
                ref="menuEnd"
                :close-on-content-click="false"
                v-model="range.menuEnd"
                :nudge-right="40"
                :return-value.sync="range.end"
                lazy
                transition="scale-transition"
                offset-y
                full-width
                min-width="290px"
              >
                <v-text-field
                  slot="activator"
                  v-model="computedEnd"
                  label="Data Final"
                  prepend-icon="event"
                  readonly
                ></v-text-field>
                <v-date-picker v-model="range.end" no-title scrollable locale="pt-br">
                  <v-spacer></v-spacer>
                  <v-btn flat color="grey" @click="range.menuEnd = false">Fechar</v-btn>
                  <v-btn color="primary" @click="$refs.menuEnd.save(range.end)">OK</v-btn>
                </v-date-picker>
              </v-menu>
            </v-flex>
          </v-layout>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="grey" flat @click="range.modal = false">Fechar</v-btn>
          <v-btn color="primary" @click="range.modal = false; getList()">OK</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import _ from "lodash";
import moment from "moment";
export default {
  data() {
    return {
      menuFilter: { menu: false },
      range: {
        start: moment().startOf('month').format('YYYY-MM-DD'),
				end: moment().endOf('month').format('YYYY-MM-DD'),
        modal: false
      },
      items: [],
      columns: [
        { value: "paid_date", text: "Data" },
        {
          value: "provider_id",
          text: "Fornecedor",
          sortable: false
        },
        { value: "description", text: "Descrição" },
        { value: "type", text: "Tipo" },
        { value: "doc_number", text: "NF" },
        { value: "price", text: "Valor" },
        { value: "saldo", text: "Saldo" }
      ],
      filtro: {
        bank_id: null,
        paid: 1,
        contract_id: null,
      },
      tipo: 1,
      total: 0,
      anterior: 0,
      banks: [],
      contracts: [],
      loading: false
    };
  },
  computed: {
    computedStart() {
      return this.formatDate(this.range.start);
    },
    computedEnd() {
      return this.formatDate(this.range.end);
    },
  },
  watch: {
    dialog(val) {
      val || this.close();
    },

  },
  created() {
    this.initialize();
  },
  methods: {
    initialize() {
      this.getList();
      this.getBanks();
      this.getContracts();
    },
    getList() {
      this.loading = true;
      return new Promise((resolve, reject) => {
        const params = {
          ...this.range,
          ...this.filtro,
          modal: undefined,
          menuStart: undefined,
          menuEnd: undefined
        };
        this.$http
          .get(`/bills/report_bills`, { params })
          .then(response => response.data)
          .then(json => {
            this.tipo = this.filtro.paid;
            setTimeout(() => {
              this.loading = false;

              this.items = json.items;
              this.total = 0;
              this.anterior = json.saldo;

              this.items.forEach(item => {
                if (item.type === "Despesa") {
                  this.total -= parseFloat(item.price);
                } else {
                  this.total += parseFloat(item.price);
                }

                item.total = this.total + this.anterior;
              });
            }, 1000);
          })
          .catch(() => (this.loading = false));
      });
    },
    checkLast(item){
      //let filter = this.items.filter(o => {return moment(item.paid_date).format('YYYY-MM-DD') == moment(o.paid_date).format('YYYY-MM-DD');});
      return true;
    },
    getBanks() {
      this.$http.get(`/banks/list`)
        .then(response => response.data)
        .then(json => {
          if(json && json.items) {
            const arr = [];
            json.items.forEach(obj => arr.push({text: obj.name, value: obj.id}));
            this.banks = arr;
          }
        });
    },
    getContracts () {
      this.$http
        .get(`/contracts/list`)
        .then(response => response.data)
        .then(json => {
          if (json && json.items) {
            const arr = [];
            json.items.forEach(obj =>
              arr.push({ text: obj.name + ' Ex. ' + obj.exercise, value: obj.id })
            );
            this.contracts = arr;
          }
        });
    },
    filtrar() {
      this.menuFilter.menu = false;
      this.getList();
    },
    formatDate: date => (date ? moment(date).format("DD/MM/YYYY") : "")
  }
};
</script>


<style>
.application {
  background: #ffffff !important;
}
</style>

<style scoped>
.application {
  background: #ffffff !important;
}

table.v-table tbody td,
table.v-table tbody th {
  height: 35px;
}
table.v-table thead tr {
  height: 40px;
}
table td {
  font-size: 11px !important;
}
</style>
