<template>
<div>
	<v-container grid-list-md text-xs-center px-0>
		<notifications position="bottom center" />
		<v-layout row wrap>
			<v-flex xs12>

			<v-toolbar flat color="white" class="elevation-1">
				<v-toolbar-title>Senhas</v-toolbar-title>
				<v-divider class="mx-2" inset vertical></v-divider>
				<v-spacer></v-spacer>
				<v-text-field
					v-model.lazy="search"
					append-icon="search"
					label="Buscar"
					single-line
					hide-details
					class="hidden-md-and-down"
				></v-text-field>
				<v-spacer></v-spacer>
        <v-btn @click="openPasswordForm({})" color="primary" dark class="mb-2">Novo</v-btn>
			</v-toolbar>

				<v-data-table
					:headers="columns"
					:items="items"
      		:pagination.sync="pagination"
					:total-items="pagination.totalItems"
      		:loading="loading"
					hide-actions
					class="elevation-1"
				>
					<template slot="items" slot-scope="props">
						<tr>
							<td>{{ props.item.id }}</td>
							<td>{{ props.item.type }}</td>
							<td>{{ props.item.number }}</td>
							<td class="text-xs-center px-0">
                <router-link :to="'/app/passwords/' + props.item.id">
									<v-icon small class="mr-2">
										fas fa-eye
									</v-icon>
								</router-link>

								<v-icon small @click="openPasswordForm(props.item)">
									edit
								</v-icon>

								<v-icon small @click="del(props.item.id)">
									delete
								</v-icon>
							</td>
						</tr>
					</template>

					<template slot="no-data">
						Desculpe, nenhum dado para ser exibido!
					</template>
				</v-data-table>
				<div class="text-xs-center pt-2">
					<v-pagination v-model="pagination.page" :length="pages"></v-pagination>
				</div>

			</v-flex>
		</v-layout>

		<div v-if="passwordFormActive.open">
			<password-form
				:active="passwordFormActive.open"
				:item="passwordFormActive.item"
				:finish="onClosePasswordForm"
			></password-form>
		</div>

  </v-container>
</div>
</template>

<script>
import _ from 'lodash';
export default {

  data() {
		return {
			valid: false,
			dialogAddEvaluation: false,
			search: '',
      pagination: {
				totalItems: 0,
				rowsPerPage: 10,
  			descending: false,
  			page: 1,
  			sortBy: 'id'
			},
			items: [],
			columns: [
				{ value: 'id', text: 'ID' },
				{ value: 'type', text: 'Tipo', },
				{ value: 'number', text: 'Núumero'},
				{ text: '', value: '', sortable: false }
			],
			passwordFormActive: {
				open: false,
				item: {}
			},
			loading: false,
		}
	},
	computed: {
		pages () {
			if (this.pagination.rowsPerPage == null ||
				this.pagination.totalItems == null
			) return 0

			return Math.ceil(this.pagination.totalItems / this.pagination.rowsPerPage)
		}
	},
	watch: {
		"pagination.page": {
			handler () {
				this.getList();
			},
			deep: true
		},
		search: function () {
      this.debounceInput();
    }
	},
	created () {
		this.initialize();
	},
	methods: {
		initialize () {
			//console.log('this.notify', this.$notify);
		},
		getList() {
			this.loading = true
			return new Promise((resolve, reject) => {
				const params = {
					...this.pagination,
					totalItems: undefined,
					search: this.search
				};
				this.$http.get(`/passwords/list`, {params})
				.then(response => response.data).then(json => {
					setTimeout(() => {
            this.loading = false;

						this.items = json.items;
						this.pagination.totalItems = json.total;
          }, 1000);
				}).catch(() => this.loading = false);
			});
		},
		del(id) {
			if (confirm('Deseja realmente excluir este Senha?')) {
				this.$http.delete(`/passwords/${id}`)
				.then(response => {
					this.$notify({
						title: `Senha Excluido!`,
						type: 'success',
					});
					this.getList();
				});
			}
		},
		debounceInput: _.debounce(function () {
      setTimeout(() => {
        this.getList();
      }, 200);
		}, 400),
		openPasswordForm (item) {
			this.passwordFormActive.open = true;
			this.passwordFormActive.item = item;
		},
		onClosePasswordForm () {
			this.passwordFormActive.open = false;
			this.getList();
		}
	}
}
</script>
