<template>
  <div>
    <v-container grid-list-md text-xs-center px-0>
      <notifications position="bottom center" />
      <v-layout row wrap>
        <v-flex xs12>
          <v-toolbar flat color="white" class="elevation-1">
            <v-toolbar-title>Fluxo de Caixa</v-toolbar-title>
            <v-divider class="mx-2" inset vertical></v-divider>
            <v-spacer></v-spacer>
            <div class="hidden-sm-and-down">
              {{ formatDate(range.start) }} - {{ formatDate(range.end) }}
              <v-icon class="mr-2" @click="range.modal = true">edit</v-icon>
              {{ filtro.date_type == "issue_date" ? "Data Emissão" : "" }}
              {{ filtro.date_type == "due_date" ? "Data Vencimento" : "" }}
              {{ filtro.date_type == "paid_date" ? "Data Pagamento" : "" }}
            </div>
            <v-spacer></v-spacer>
            <v-menu
              v-model="menuFilter.menu"
              bottom
              left
              :close-on-content-click="false"
              max-width="400"
              min-width="400"
              offset-x
            >
              <v-btn slot="activator" color="primary" dark class="mb-2" outline>
                FILTROS
              </v-btn>

              <v-card>
                <v-card-title class="px-1 py-1"
                  >FILTRO
                  <v-spacer></v-spacer>
                  <v-btn
                    outline
                    light
                    small
                    color="primary"
                    @click="
                      menuFilter.menu = true;
                      filtro = {};
                    "
                    >Limpar</v-btn
                  >
                </v-card-title>

                <v-layout row wrap>
                  <v-flex xs12 class="px-1">
                    <v-autocomplete
                      v-model="filtro.contract_id"
                      :items="contracts"
                      label="Contrato"
                    ></v-autocomplete>
                  </v-flex>

                  <v-flex xs12 class="px-1">
                    <v-autocomplete
                      v-model="filtro.provider_id"
                      :items="providers"
                      label="Fornecedor"
                    ></v-autocomplete>
                  </v-flex>

                  <v-flex xs12 class="px-1">
                    <v-autocomplete
                      v-model="filtro.bill_type_id"
                      :items="billTypes"
                      label="Tipo"
                    ></v-autocomplete>
                  </v-flex>

                  <v-flex xs12 class="px-1">
                    <v-autocomplete
                      v-model="filtro.bank_id"
                      :items="banks"
                      label="Banco"
                    ></v-autocomplete>
                  </v-flex>

                  <v-flex xs12 class="px-1">
                    <v-text-field
                      v-model="filtro.doc_number"
                      label="Nota Fiscal"
                    ></v-text-field>
                  </v-flex>

                  <v-flex xs12 class="px-1">
                    <v-select
                      v-model="filtro.paid"
                      :items="[
                        { value: null, text: 'Todos' },
                        { value: 1, text: 'Pago' },
                        { value: 0, text: 'Pendente' }
                      ]"
                      label="Status"
                    ></v-select>
                  </v-flex>
                </v-layout>

                <v-card-actions>
                  <v-spacer></v-spacer>

                  <v-btn flat @click="menuFilter.menu = false">Fechar</v-btn>
                  <v-btn color="primary" flat @click="filtrar()">Filtrar</v-btn>
                </v-card-actions>
              </v-card>
            </v-menu>
            <v-btn
              v-if="user.type != 8 && user.type != 12"
              @click="billFormOpen({})"
              color="primary"
              dark
              class="mb-2"
              >Novo</v-btn
            >
          </v-toolbar>

          <v-layout wrap row class="hidden-md-and-up">
            <v-flex xs12>
              {{ formatDate(range.start) }} - {{ formatDate(range.end) }}
              <v-icon class="mr-2" @click="range.modal = true"> edit </v-icon>
            </v-flex>
          </v-layout>

          <v-data-table
            :headers="columns"
            :items="items"
            :loading="loading"
            hide-actions
            disable-initial-sort
            class="elevation-1"
          >
            <template slot="items" slot-scope="props">
              <tr
                :class="{
                  'green lighten-1': props.item.paid,
                  'yellow lighten-1':
                    !props.item.paid && dataDiff(props.item.due_date) >= 0,
                  'red lighten-1':
                    !props.item.paid && dataDiff(props.item.due_date) < 0
                }"
              >
                <td>{{ props.item.id }}</td>
                <td>{{ formatDate(props.item.issue_date) }}</td>
                <td>{{ formatDate(props.item.due_date) }}</td>
                <td>{{ props.item.provider && props.item.provider.name }}</td>
                <td>
                  <div>{{ props.item.description }}</div>
                  <div v-if="props.item.grade_id">
                    REF. Compra #{{ props.item.grade_id }}
                  </div>
                  <div v-else-if="props.item.bill_id">
                    REF. Conta #{{ props.item.bill_id }}
                  </div>
                </td>
                <td>
                  {{ props.item.doc_number }}
                </td>
                <td>
                  {{
                    props.item.bill_type
                      ? props.item.bill_type.code +
                        " - " +
                        props.item.bill_type.name
                      : ""
                  }}
                </td>
                <td>{{ props.item.price | formatMoney }}</td>
                <td>
                  {{
                    props.item.paid
                      ? "Pago em " +
                        formatDate(props.item.paid_date) +
                        " " +
                        (props.item.horary || "")
                      : "Pendente"
                  }}
                </td>
                <td class="text-xs-center px-0">
                  <v-menu bottom left v-if="user.type != 8 && user.type != 12">
                    <v-btn slot="activator" icon small>
                      <v-icon small>fas fa-ellipsis-v</v-icon>
                    </v-btn>

                    <v-list>
                      <v-list-tile
                        @click="dialogFinishOpen(props.item)"
                        v-if="!props.item.paid"
                      >
                        <v-list-tile-title>DAR BAIXA</v-list-tile-title>
                      </v-list-tile>

                      <v-list-tile
                        @click="dialogAbaterOpen(props.item)"
                        v-if="!props.item.paid"
                      >
                        <v-list-tile-title>ABATER VALOR</v-list-tile-title>
                      </v-list-tile>

                      <v-list-tile @click="billFormOpen(props.item)">
                        <v-list-tile-title>EDITAR</v-list-tile-title>
                      </v-list-tile>

                      <v-list-tile @click="del(props.item.id)">
                        <v-list-tile-title>EXCLUIR</v-list-tile-title>
                      </v-list-tile>
                    </v-list>
                  </v-menu>
                </td>
              </tr>
            </template>

            <template slot="no-data">
              Desculpe, nenhum dado para ser exibido!
            </template>
          </v-data-table>
        </v-flex>
      </v-layout>

      <v-layout row wrap v-if="user.type != 8">
        <v-flex xs6 sm4 md4 v-if="!filtro.bank_id">
          <v-card color="blue-grey">
            <v-toolbar flat color="blue-grey" class="elevation-1 white--text">
              <v-toolbar-title>Todas as Contas</v-toolbar-title>
              <v-spacer></v-spacer>
            </v-toolbar>
            <v-list dense>
              <v-list-tile>
                <v-list-tile-content>Total de Receitas</v-list-tile-content>
                <v-list-tile-content class="align-end">{{
                  totais.receitas | formatMoney
                }}</v-list-tile-content>
              </v-list-tile>
              <v-list-tile>
                <v-list-tile-content>Total de Despesas</v-list-tile-content>
                <v-list-tile-content class="align-end"
                  >- {{ totais.despesas | formatMoney }}
                </v-list-tile-content>
              </v-list-tile>
            </v-list>
            <v-footer
              class="pa-3 white--text"
              :color="totais.balanco < 0 ? 'red' : 'green'"
            >
              <v-spacer></v-spacer>
              <div class="headline">
                Total {{ totais.balanco | formatMoney }}
              </div>
              <br />
            </v-footer>
            <small class="white--text">Referente ao período selecionado</small>
          </v-card>
        </v-flex>

        <v-flex xs6 sm4 md4 v-for="(item, i) in banks" :key="i">
          <v-card
            color="blue-grey"
            v-if="!filtro.bank_id || filtro.bank_id == item.value"
          >
            <v-toolbar flat color="blue-grey" class="elevation-1 white--text">
              <v-toolbar-title>{{ item.text }}</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-btn
                flat
                icon
                dark
                small
                @click="
                  dialogTransferOpen({ name: item.text, bank_id: item.value })
                "
              >
                <v-icon dark small>fas fa-exchange-alt</v-icon>
              </v-btn>
            </v-toolbar>
            <v-list dense>
              <v-list-tile>
                <v-list-tile-content>Total de Receitas</v-list-tile-content>
                <v-list-tile-content class="align-end">{{
                  totaisByBank(item.value).receitas | formatMoney
                }}</v-list-tile-content>
              </v-list-tile>
              <v-list-tile>
                <v-list-tile-content>Total de Despesas</v-list-tile-content>
                <v-list-tile-content class="align-end"
                  >- {{ totaisByBank(item.value).despesas | formatMoney }}
                </v-list-tile-content>
              </v-list-tile>
            </v-list>
            <v-footer
              class="pa-3 white--text"
              :color="totaisByBank(item.value).balanco < 0 ? 'red' : 'green'"
            >
              <v-spacer></v-spacer>
              <div class="headline">
                Total {{ totaisByBank(item.value).balanco | formatMoney }}
              </div>
              <br />
            </v-footer>
            <small class="white--text">Referente ao período selecionado</small>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>

    <v-dialog v-model="range.modal" scrollable max-width="350px">
      <v-card>
        <v-card-title>
          <span class="headline">Período</span>
        </v-card-title>
        <v-card-text>
          <v-layout row wrap>
            <v-flex xs12>
              <v-select
                v-model="filtro.date_type"
                :items="[
                  {
                    text: 'Data Emissão',
                    value: 'issue_date'
                  },
                  {
                    text: 'Data Vencimento',
                    value: 'due_date'
                  },
                  {
                    text: 'Data Pagamento',
                    value: 'paid_date'
                  }
                ]"
                label="Tipo de Data"
              ></v-select>
            </v-flex>
            <v-flex xs12>
              <v-menu
                ref="menuStart"
                :close-on-content-click="false"
                v-model="range.menuStart"
                :nudge-right="40"
                :return-value.sync="range.start"
                lazy
                transition="scale-transition"
                offset-y
                full-width
                min-width="290px"
              >
                <v-text-field
                  slot="activator"
                  v-model="computedStart"
                  label="Data inicial"
                  prepend-icon="event"
                  readonly
                ></v-text-field>
                <v-date-picker
                  v-model="range.start"
                  no-title
                  scrollable
                  locale="pt-br"
                >
                  <v-spacer></v-spacer>
                  <v-btn flat color="grey" @click="range.menuStart = false"
                    >Fechar</v-btn
                  >
                  <v-btn
                    color="primary"
                    @click="$refs.menuStart.save(range.start)"
                    >OK</v-btn
                  >
                </v-date-picker>
              </v-menu>

              <v-menu
                ref="menuEnd"
                :close-on-content-click="false"
                v-model="range.menuEnd"
                :nudge-right="40"
                :return-value.sync="range.end"
                lazy
                transition="scale-transition"
                offset-y
                full-width
                min-width="290px"
              >
                <v-text-field
                  slot="activator"
                  v-model="computedEnd"
                  label="Data Final"
                  prepend-icon="event"
                  readonly
                ></v-text-field>
                <v-date-picker
                  v-model="range.end"
                  no-title
                  scrollable
                  locale="pt-br"
                >
                  <v-spacer></v-spacer>
                  <v-btn flat color="grey" @click="range.menuEnd = false"
                    >Fechar</v-btn
                  >
                  <v-btn color="primary" @click="$refs.menuEnd.save(range.end)"
                    >OK</v-btn
                  >
                </v-date-picker>
              </v-menu>
            </v-flex>
          </v-layout>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="grey" flat @click="range.modal = false">Fechar</v-btn>
          <v-btn
            color="primary"
            @click="
              range.modal = false;
              getList();
            "
            >OK</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogFinish.open" scrollable max-width="450px">
      <v-card>
        <v-card-title>
          <span class="headline">Dar baixa</span>
        </v-card-title>
        <v-card-text>
          <v-layout row wrap>
            <v-flex xs12 v-if="dialogFinish.item.obs">
              <b>Informações adicionais: </b> <br />
              {{ dialogFinish.item.obs }} <br />
            </v-flex>

            <v-flex xs12>
              <v-text-field
                v-model="dialogFinish.item.paid_date"
                type="date"
                label="Data do Pagamento"
              ></v-text-field>
            </v-flex>
            <v-flex xs12>
              <v-select
                v-model="dialogFinish.item.doc_type"
                label="Tipo de Documento"
                :items="[
                  { text: '', value: null },
                  { text: 'Boleto', value: 'Boleto' },
                  { text: 'Débito', value: 'Débito' },
                  { text: 'Transferência', value: 'Transferência' },
                  { text: 'Guias', value: 'Guias' }
                ]"
              ></v-select>
            </v-flex>
            <v-flex xs12>
              <v-text-field
                v-model="dialogFinish.item.paid_doc"
                type="text"
                label="Doc. de Pagamento"
              ></v-text-field>
            </v-flex>
            <v-flex xs12>
              <v-select
                v-model="dialogFinish.item.bank_id"
                :items="banks"
                label="Banco"
                :rules="[v => !!v || 'Banco é obrigatório!']"
                required
              ></v-select>
            </v-flex>
          </v-layout>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="grey" flat @click="dialogFinish.open = false"
            >Fechar</v-btn
          >
          <v-btn
            :disabled="
              !dialogFinish.item.bank_id && !dialogFinish.item.pay_credit
            "
            color="primary"
            @click="finishItem(dialogFinish.item)"
            >Sim</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogAbater.open" scrollable max-width="450px">
      <v-card>
        <v-card-title>
          <span class="headline">Abater Valor</span>
          <v-spacer></v-spacer>
          <span class="headline">{{
            dialogAbater.item.price | formatMoney
          }}</span>
        </v-card-title>
        <v-card-text>
          <v-layout row wrap>
            <v-flex xs12>
              <v-text-field
                v-model="dialogAbater.item.paid_date"
                type="date"
                label="Data do Pagamento"
              ></v-text-field>
            </v-flex>
            <v-flex xs12>
              <div
                class="v-input v-money v-text-field v-text-field--prefix v-input--is-label-active v-input--is-dirty theme--light"
              >
                <label style="margin: 3px 15px 0 0">Valor: </label>
                <money
                  style="
                    margin: 1px 0 0 0;
                    padding: 13px 0;
                    border-bottom: 1.4px solid #999;
                  "
                  v-model="dialogAbater.item.price_amortize"
                  v-bind="money"
                >
                </money>
              </div>
            </v-flex>
            <v-flex xs12>
              <v-text-field
                v-model="dialogAbater.item.paid_doc"
                type="text"
                label="Doc. de Pagamento"
              ></v-text-field>
            </v-flex>
            <v-flex xs12>
              <v-select
                v-model="dialogAbater.item.bank_id"
                :items="banks"
                label="Banco"
                :rules="[v => !!v || 'Banco é obrigatório!']"
                required
              ></v-select>
            </v-flex>
          </v-layout>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="grey" flat @click="dialogAbater.open = false"
            >Fechar</v-btn
          >
          <v-btn
            :disabled="!dialogAbater.item.bank_id"
            color="primary"
            @click="abaterItem(dialogAbater.item)"
            >Sim</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogTransfer.open" scrollable max-width="450px">
      <v-card>
        <v-card-title>
          <span class="headline">Transferência</span>
          <v-spacer></v-spacer>
          <span>{{ dialogTransfer.item.name }}</span>
        </v-card-title>
        <v-card-text>
          <v-layout row wrap>
            <v-flex xs12>
              <v-text-field
                v-model="dialogTransfer.item.paid_date"
                type="date"
                label="Data da Transferência"
              ></v-text-field>
            </v-flex>
            <v-flex xs12>
              <div
                class="v-input v-money v-text-field v-text-field--prefix v-input--is-label-active v-input--is-dirty theme--light"
              >
                <label style="margin: 3px 15px 0 0">Valor: </label>
                <money
                  style="
                    margin: 1px 0 0 0;
                    padding: 13px 0;
                    border-bottom: 1.4px solid #999;
                  "
                  v-model="dialogTransfer.item.price"
                  v-bind="money"
                >
                </money>
              </div>
            </v-flex>
            <v-flex xs12 v-if="dialogTransfer.item">
              <v-select
                v-model="dialogTransfer.item.to_bank_id"
                :items="
                  banks.filter(b => b.value != dialogTransfer.item.bank_id)
                "
                label="Banco"
                :rules="[v => !!v || 'Banco é obrigatório!']"
                required
              ></v-select>
            </v-flex>
          </v-layout>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="grey" flat @click="dialogTransfer.open = false"
            >Fechar</v-btn
          >
          <v-btn
            :disabled="!dialogTransfer.item.bank_id"
            color="primary"
            @click="transfer(dialogTransfer.item)"
            >Sim</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <div v-if="billFormActive.open">
      <bill-form
        :active="billFormActive.open"
        :item="billFormActive.item"
        :finish="billFormClose"
      ></bill-form>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import moment from "moment";
import { Money } from "v-money";
export default {
  components: { Money },
  data() {
    return {
      user: USER,
      menuFilter: { menu: false },
      range: {
        start: moment()
          .startOf("month")
          .format("YYYY-MM-DD"),
        end: moment()
          .endOf("month")
          .format("YYYY-MM-DD"),
        modal: false
      },
      items: [],
      columns: [
        { value: "id", text: "ID" },
        { value: "issue_date", text: "Data Emissão" },
        { value: "due_date", text: "Vencimento" },
        { value: "provider_id", text: "Fornecedor", sortable: false },
        { value: "description", text: "Descrição" },
        { value: "doc_number", text: "NF" },
        { value: "type", text: "Tipo" },
        { value: "price", text: "Valor" },
        { value: "status", text: "Status" },
        { value: "", text: "", sortable: false }
      ],
      billFormActive: { open: false, item: {} },
      dialogFinish: { open: false, item: {} },
      dialogAbater: { open: false, item: {} },
      dialogTransfer: { open: false, item: {} },
      loading: false,
      banks: [],
      providers: [],
      billTypes: [],
      contracts: [],
      filtro: {
        provider_id: null,
        bill_type_id: null,
        doc_number: null,
        paid: null,
        bank_id: null,
        date_type: "issue_date",
        contract_id: null
      },
      totais: {
        receitas: 0,
        despesas: 0,
        balanco: 0
      },
      money: {
        decimal: ",",
        thousands: ".",
        prefix: "R$ ",
        suffix: "",
        precision: 2,
        masked: false
      }
    };
  },
  computed: {
    computedStart() {
      return this.formatDate(this.range.start);
    },
    computedEnd() {
      return this.formatDate(this.range.end);
    }
  },
  watch: {
    dialog(val) {
      val || this.close();
    }
  },
  created() {
    this.initialize();
  },
  methods: {
    initialize() {
      this.getList();
      this.getBanks();
      this.getProviders();
      this.getBillTypes();
      this.getContracts();
    },
    getList() {
      this.loading = true;
      return new Promise((resolve, reject) => {
        const params = {
          ...this.range,
          ...this.filtro,
          modal: undefined,
          menuStart: undefined
        };
        this.$http
          .get(`/bills/list`, { params })
          .then(response => response.data)
          .then(json => {
            setTimeout(() => {
              this.loading = false;

              this.items = json.items;
              this.totais = {
                receitas: 0,
                despesas: 0,
                balanco: 0
              };

              this.items.forEach(item => {
                if (item.paid) {
                  if (item.type === "Despesa") {
                    this.totais.despesas += parseFloat(item.price);
                  } else if (item.type === "Receita") {
                    this.totais.receitas += parseFloat(item.price);
                  }
                }
              });

              this.totais.balanco = this.totais.receitas - this.totais.despesas;
            }, 1000);
          })
          .catch(() => (this.loading = false));
      });
    },
    getProviders() {
      this.$http
        .get(`/providers/list?sortBy=name`)
        .then(response => response.data)
        .then(json => {
          if (json && json.items) {
            const arr = [];
            json.items.forEach(obj =>
              arr.push({ text: obj.name, value: obj.id })
            );
            this.providers = arr;
          }
        });
    },
    getBillTypes() {
      this.$http
        .get(`/bill_types/list`)
        .then(response => response.data)
        .then(json => {
          if (json && json.items) {
            const arr = [];
            json.items.forEach(obj =>
              arr.push({
                text: this.getTree(obj.code) + obj.code + " - " + obj.name,
                name: obj.code + " - " + obj.name,
                id: obj.id,
                value: obj.code
              })
            );
            this.billTypes = arr;
          }
        });
    },
    getTree(code) {
      if (code.length < 2) {
        return "";
      }
      return "└─";
    },
    abaterItem(item) {
      if (parseFloat(item.price) < parseFloat(item.price_amortize)) {
        this.$notify({
          title: `Valor superior a dívida!`,
          group: "notifications",
          type: "error"
        });
        return;
      }
      this.dialogAbater.open = false;
      item.paid = true;
      item.finish = true;
      item.paid_date = item.paid_date + " " + moment().format("HH:mm:ss");
      this.$http.post(`/bills/${item.id}/amortize`, item).then(response => {
        this.$notify({
          title: `Abatimento realizado com Sucesso!`,
          group: "notifications",
          type: "success"
        });
        this.getList();
      });
    },
    transfer(item) {
      this.dialogTransfer.open = false;
      item.paid = true;
      item.finish = true;
      item.paid_date = item.paid_date + " " + moment().format("HH:mm:ss");
      this.$http.post(`/bills/transfer`, item).then(response => {
        this.$notify({
          title: `Transferência realizada com Sucesso!`,
          group: "notifications",
          type: "success"
        });
        this.getList();
      });
    },
    getBanks() {
      this.$http
        .get(`/banks/list`)
        .then(response => response.data)
        .then(json => {
          if (json && json.items) {
            const arr = [];
            json.items.forEach(obj =>
              arr.push({ text: obj.name, value: obj.id })
            );
            this.banks = arr;
          }
        });
    },
    getContracts() {
      this.$http
        .get(`/contracts/list`)
        .then(response => response.data)
        .then(json => {
          if (json && json.items) {
            const arr = [];
            json.items.forEach(obj =>
              arr.push({
                text: obj.name + " Ex. " + obj.exercise,
                value: obj.id
              })
            );
            this.contracts = arr;
          }
        });
    },
    totaisByBank(bank) {
      const totais = { receitas: 0, despesas: 0, balanco: 0 };

      this.items.forEach(item => {
        if (item.paid === 1 && item.bank_id === bank) {
          if (item.type === "Despesa") {
            totais.despesas += parseFloat(item.price);
          } else if (item.type === "Receita") {
            totais.receitas += parseFloat(item.price);
          }
        }
      });

      totais.balanco = totais.receitas - totais.despesas;
      return totais;
    },
    billFormOpen(item) {
      this.billFormActive.open = true;
      this.billFormActive.item = item;
    },
    billFormClose() {
      this.billFormActive.open = false;
      this.getList();
    },
    finishItem(item) {
      this.dialogFinish.open = false;
      item.paid = true;
      item.finish = true;
      item.paid_date = item.paid_date + " " + moment().format("HH:mm:ss");
      this.$http.put(`/bills/${item.id}`, item).then(response => {
        this.$notify({
          title: `Conta Atualizada com Sucesso!`,
          group: "notifications",
          type: "success"
        });
        this.getList();
      });
    },
    dialogFinishOpen(item) {
      this.dialogFinish.open = true;
      this.dialogFinish.item = item;
      this.dialogFinish.item.paid_date = this.dateNow();
    },
    dialogAbaterOpen(item) {
      this.dialogAbater.open = true;
      this.dialogAbater.item = JSON.parse(JSON.stringify(item));
      this.dialogAbater.item.paid_date = this.dateNow();
    },
    dialogTransferOpen(item) {
      this.dialogTransfer.open = true;
      item.paid_date = this.dateNow();
      item.due_date = this.dateNow();
      this.dialogTransfer.item = item;
    },
    filtrar() {
      this.menuFilter.menu = false;
      this.getList();
    },
    formatDate: date => (date ? moment(date).format("DD/MM/YYYY") : ""),
    dateNow: () => moment().format("YYYY-MM-DD"),
    dataDiff: date => moment(date).diff(moment(), "days"),
    del(id) {
      if (confirm("Deseja realmente excluir este Item?")) {
        this.$http.delete(`/bills/${id}`).then(response => {
          this.$notify({
            title: `Item Excluido!`,
            group: "notifications",
            type: "success"
          });
          this.getList();
        });
      }
    }
  }
};
</script>
