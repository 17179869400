<template>
  <div>

    <dialog-loader :active="loading" />
    <v-container grid-list-md px-0>
      <v-card>
        <v-card-title>
          <span class="headline">GERAR RELATÓRIO - RP08</span>
        </v-card-title>

        <v-card-text class="pt-0">
          <v-container grid-list-md class="pt-0">
            <v-form v-model="valid" xs12 sm12 md12>
              <v-layout row wrap>
                <v-flex xs12 sm4>
                  <v-text-field
                    v-model="editedItem.year"
                    required
                    type="number"
                    label="Ano"
                    :rules="[v => !!v || 'Ano é obrigatório!']"
                  />
                </v-flex>

                <v-flex xs12 sm4>
                  <v-select
                    v-model="editedItem.period"
                    :items="periods"
                    required
                    label="Período"
                    :rules="[v => !!v || 'Período é obrigatório!']"
                  />
                </v-flex>

                <v-flex xs12 sm4>
                  <v-autocomplete
                    v-model="editedItem.contract_id"
                    :items="contracts"
                    label="Contrato"
                    required
                    :rules="[v => !!v || 'Contrato é obrigatório!']"
                  ></v-autocomplete>
                </v-flex>

                <v-flex xs12 sm4>
                  <v-text-field v-model="editedItem.exercise" label="EXERCICIO"></v-text-field>
                </v-flex>

                <v-flex xs12 sm4>
                  <div
                    class="v-input v-money v-text-field v-text-field--prefix v-input--is-label-active v-input--is-dirty theme--light"
                  >
                    <div class="v-text-field__slot">
                      <label
                        aria-hidden="true"
                        class="v-label v-label--active theme--light"
                        style="left: 0px; right: auto; "
                      >Saldo Anterior</label>
                      <money
                        style="
                        margin: 1px 0 0 0;
                        padding: 13px 0;
                        border-bottom: 1.4px solid #999;"
                        v-model="editedItem.anter"
                        v-bind="money"
                      ></money>
                    </div>
                  </div>
                </v-flex>

                <v-flex xs12 sm4>
                  <div
                    class="v-input v-money v-text-field v-text-field--prefix v-input--is-label-active v-input--is-dirty theme--light"
                  >
                    <div class="v-text-field__slot">
                      <label
                        aria-hidden="true"
                        class="v-label v-label--active theme--light"
                        style="left: 0px; right: auto; "
                      >Reembolso de Despesas</label>
                      <money
                        style="
                        margin: 1px 0 0 0;
                        padding: 13px 0;
                        border-bottom: 1.4px solid #999;"
                        v-model="editedItem.reemb"
                        v-bind="money"
                      ></money>
                    </div>
                  </div>
                </v-flex>

                <v-flex xs12 sm4>
                  <div
                    class="v-input v-money v-text-field v-text-field--prefix v-input--is-label-active v-input--is-dirty theme--light"
                  >
                    <div class="v-text-field__slot">
                      <label
                        aria-hidden="true"
                        class="v-label v-label--active theme--light"
                        style="left: 0px; right: auto; "
                      >Saldo</label>
                      <money
                        style="
                        margin: 1px 0 0 0;
                        padding: 13px 0;
                        border-bottom: 1.4px solid #999;"
                        v-model="editedItem.saldo"
                        v-bind="money"
                      ></money>
                    </div>
                  </div>
                </v-flex>

                <v-flex xs12 sm4>
                  <div
                    class="v-input v-money v-text-field v-text-field--prefix v-input--is-label-active v-input--is-dirty theme--light"
                  >
                    <div class="v-text-field__slot">
                      <label
                        aria-hidden="true"
                        class="v-label v-label--active theme--light"
                        style="left: 0px; right: auto; "
                      >Recursos Próprios</label>
                      <money
                        style="
                        margin: 1px 0 0 0;
                        padding: 13px 0;
                        border-bottom: 1.4px solid #999;"
                        v-model="editedItem.propr"
                        v-bind="money"
                      ></money>
                    </div>
                  </div>
                </v-flex>

                <v-flex xs12 sm4>
                  <div
                    class="v-input v-money v-text-field v-text-field--prefix v-input--is-label-active v-input--is-dirty theme--light"
                  >
                    <div class="v-text-field__slot">
                      <label
                        aria-hidden="true"
                        class="v-label v-label--active theme--light"
                        style="left: 0px; right: auto; "
                      >Resgate</label>
                      <money
                        style="
                        margin: 1px 0 0 0;
                        padding: 13px 0;
                        border-bottom: 1.4px solid #999;"
                        v-model="editedItem.resgate"
                        v-bind="money"
                      ></money>
                    </div>
                  </div>
                </v-flex>

                <v-flex xs12 sm4>
                  <div
                    class="v-input v-money v-text-field v-text-field--prefix v-input--is-label-active v-input--is-dirty theme--light"
                  >
                    <div class="v-text-field__slot">
                      <label
                        aria-hidden="true"
                        class="v-label v-label--active theme--light"
                        style="left: 0px; right: auto; "
                      >Aplicações</label>
                      <money
                        style="
                        margin: 1px 0 0 0;
                        padding: 13px 0;
                        border-bottom: 1.4px solid #999;"
                        v-model="editedItem.aplica"
                        v-bind="money"
                      ></money>
                    </div>
                  </div>
                </v-flex>

                <v-flex xs12 sm4>
                  <div
                    class="v-input v-money v-text-field v-text-field--prefix v-input--is-label-active v-input--is-dirty theme--light"
                  >
                    <div class="v-text-field__slot">
                      <label
                        aria-hidden="true"
                        class="v-label v-label--active theme--light"
                        style="left: 0px; right: auto; "
                      >Rendimentos</label>
                      <money
                        style="
                        margin: 1px 0 0 0;
                        padding: 13px 0;
                        border-bottom: 1.4px solid #999;"
                        v-model="editedItem.rendi"
                        v-bind="money"
                      ></money>
                    </div>
                  </div>
                </v-flex>

                <v-flex xs12 sm4>
                  <div
                    class="v-input v-money v-text-field v-text-field--prefix v-input--is-label-active v-input--is-dirty theme--light"
                  >
                    <div class="v-text-field__slot">
                      <label
                        aria-hidden="true"
                        class="v-label v-label--active theme--light"
                        style="left: 0px; right: auto; "
                      >Encargos</label>
                      <money
                        style="
                        margin: 1px 0 0 0;
                        padding: 13px 0;
                        border-bottom: 1.4px solid #999;"
                        v-model="editedItem.encar"
                        v-bind="money"
                      ></money>
                    </div>
                  </div>
                </v-flex>

                <v-flex xs12 sm4>
                  <div
                    class="v-input v-money v-text-field v-text-field--prefix v-input--is-label-active v-input--is-dirty theme--light"
                  >
                    <div class="v-text-field__slot">
                      <label
                        aria-hidden="true"
                        class="v-label v-label--active theme--light"
                        style="left: 0px; right: auto; "
                      >Valor devolvido</label>
                      <money
                        style="
                        margin: 1px 0 0 0;
                        padding: 13px 0;
                        border-bottom: 1.4px solid #999;"
                        v-model="editedItem.devol"
                        v-bind="money"
                      ></money>
                    </div>
                  </div>
                </v-flex>

                <v-flex xs12 sm4>
                  <div
                    class="v-input v-money v-text-field v-text-field--prefix v-input--is-label-active v-input--is-dirty theme--light"
                  >
                    <div class="v-text-field__slot">
                      <label
                        aria-hidden="true"
                        class="v-label v-label--active theme--light"
                        style="left: 0px; right: auto; "
                      >(D) OUTRAS RECEITAS DECORRENTES DA EXECUÇÃO DO AJUSTE (3)</label>
                      <money
                        style="
                        margin: 1px 0 0 0;
                        padding: 13px 0;
                        border-bottom: 1.4px solid #999;"
                        v-model="editedItem.other"
                        v-bind="money"
                      ></money>
                    </div>
                  </div>
                </v-flex>


                <v-flex xs12>
                  <v-divider class="my-2" />
                  <h3>RECURSOS HUMANOS (6)</h3>

                  <v-layout row wrap>
                    <v-flex xs12 sm4>
                      <div
                        class="v-input v-money v-text-field v-text-field--prefix v-input--is-label-active v-input--is-dirty theme--light"
                      >
                        <div class="v-text-field__slot">
                          <label
                            aria-hidden="true"
                            class="v-label v-label--active theme--light"
                            style="left: 0px; right: auto; "
                          >D. CONTABILIZADAS NESTE EX.</label>
                          <money
                            style="
                            margin: 1px 0 0 0;
                            padding: 13px 0;
                            border-bottom: 1.4px solid #999;"
                            v-model="editedItem.rh_ref_total"
                            v-bind="money"
                          ></money>
                        </div>
                      </div>
                    </v-flex>
                    <v-flex xs12 sm4>
                      <div
                        class="v-input v-money v-text-field v-text-field--prefix v-input--is-label-active v-input--is-dirty theme--light"
                      >
                        <div class="v-text-field__slot">
                          <label
                            aria-hidden="true"
                            class="v-label v-label--active theme--light"
                            style="left: 0px; right: auto; "
                          >D. CONTABILIZADAS EM EX. ANTERIORES E PAGAS NESTE EX. (R$) (H)</label>
                          <money
                            style="
                            margin: 1px 0 0 0;
                            padding: 13px 0;
                            border-bottom: 1.4px solid #999;"
                            v-model="editedItem.rh_prev_paid"
                            v-bind="money"
                          ></money>
                        </div>
                      </div>
                    </v-flex>
                    <v-flex xs12 sm4>
                      <div
                        class="v-input v-money v-text-field v-text-field--prefix v-input--is-label-active v-input--is-dirty theme--light"
                      >
                        <div class="v-text-field__slot">
                          <label
                            aria-hidden="true"
                            class="v-label v-label--active theme--light"
                            style="left: 0px; right: auto; "
                          >D. CONTABILIZADAS NESTE EX. E PAGAS NESTE EX. (R$) (I)</label>
                          <money
                            style="
                            margin: 1px 0 0 0;
                            padding: 13px 0;
                            border-bottom: 1.4px solid #999;"
                            v-model="editedItem.rh_ref_paid"
                            v-bind="money"
                          ></money>
                        </div>
                      </div>
                    </v-flex>
                    <v-flex xs12 sm4>
                      <div
                        class="v-input v-money v-text-field v-text-field--prefix v-input--is-label-active v-input--is-dirty theme--light"
                      >
                        <div class="v-text-field__slot">
                          <label
                            aria-hidden="true"
                            class="v-label v-label--active theme--light"
                            style="left: 0px; right: auto; "
                          >D. CONTABILIZADAS NESTE EX. A PAGAR EM EX. SEGUINTES (R$)</label>
                          <money
                            style="
                            margin: 1px 0 0 0;
                            padding: 13px 0;
                            border-bottom: 1.4px solid #999;"
                            v-model="editedItem.rh_next_total"
                            v-bind="money"
                          ></money>
                        </div>
                      </div>
                    </v-flex>
                    <v-flex xs12>
                    <v-divider class="my-2" />
                    </v-flex>
                  </v-layout>
                </v-flex>

                <v-flex xs12>
                  <v-text-field
                    v-model="editedItem.importan"
                    label="Importancia recebida Ex. R$ 3.850.894,63 (Três milhões oitocentos e cinquenta mil oitocentos e noventa e quatro reais e sessenta e três centavos)"
                  ></v-text-field>
                </v-flex>

                <v-flex xs12 class="text-xs-right">
                  <v-btn color="primary" @click="createPlan()" :disabled="!valid">
                    <v-icon dark>far fa-file-excel</v-icon>&nbsp; GERAR PLANILHAS
                  </v-btn>
                </v-flex>
              </v-layout>

              <v-layout row wrap>
                <v-flex xs12 sm6 v-for="(item, i) in links" :key="i">
                  <v-btn outline color="blue darken-4" @click="goPlan(item.url)">{{item.title}}</v-btn>
                </v-flex>
              </v-layout>
            </v-form>
          </v-container>
        </v-card-text>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import moment from "moment";
import { Money } from "v-money";
export default {
  components: { Money },
  data() {
    return {
      valid: false,
      editedItem: {
        year: moment().format("YYYY"),
        anter: 0,
        reemb: 0,
        propr: 0,
        saldo: 0,
        resgate: 0,
        aplica: 0,
        rendi: 0,
        encar: 0,
        devol: 0,
        importan: "",
        contract_id: null,
        bank_id: null,
        exercise: "",
        other: 0,
      },
      loading: false,
      links: [],
      contracts: [],
      periods: [
        {
          text: "Anual",
          value: "Anual",
        },
        {
          text: "1º Quadrimestral",
          value: "Quadrimestral_1",
        },
        {
          text: "2º Quadrimestral",
          value: "Quadrimestral_2",
        },
        {
          text: "3º Quadrimestral",
          value: "Quadrimestral_3",
        },
      ],
      money: {
        decimal: ",",
        thousands: ".",
        prefix: "R$ ",
        suffix: "",
        precision: 2,
        masked: false,
      },
    };
  },
  created() {
    this.initialize();
  },
  methods: {
    initialize() {
      this.getContracts();
    },
    getContracts() {
      this.$http
        .get(`/contracts/list`)
        .then((response) => response.data)
        .then((json) => {
          if (json && json.items) {
            const arr = [];
            json.items.forEach((obj) =>
              arr.push({
                text: obj.name + " Ex. " + obj.exercise,
                value: obj.id,
              })
            );
            this.contracts = arr;
          }
        });
    },
    goPlan(url) {
      window.open(url);
    },
    createPlan() {
      const params = {
        ...this.editedItem,
      };

      this.loading = true;

      this.$http
        .post(`/bills/plan/report_rp08`, params)
        .then((response) => response.data)
        .then((json) => {
          this.$notify({
            title: `Relatório gerado com sucesso!`,
            group: "notifications",
            type: "success",
          });

          this.links = json.links;
          this.loading = false;
        })
        .catch((err) => {
          this.$reqError(`Erro ao gerar Relatório!`, err, this);
        });
    },
  },
};
</script>
