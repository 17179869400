var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-container',{attrs:{"grid-list-md":"","text-xs-center":"","px-0":""}},[_c('v-layout',{attrs:{"row":"","wrap":""}},[_c('v-flex',{staticClass:"px-0 py-0",attrs:{"xs12":""}},[_c('v-toolbar',{staticClass:"elevation-1",attrs:{"flat":"","color":"white"}},[_c('v-toolbar-title',[_vm._v("\n            Relatório BPA\n          ")]),_vm._v(" "),_c('v-divider',{staticClass:"mx-2",attrs:{"inset":"","vertical":""}}),_vm._v(" "),_c('v-spacer'),_vm._v(" "),_c('v-select',{attrs:{"items":[
              { text: '2021' },
              { text: '2022' },
              { text: '2023' },
              { text: '2024' },
              { text: '2025' },
              { text: '2026' },
              { text: '2027' },
              { text: '2028' },
              { text: '2029' },
              { text: '2030' }
            ],"label":"Ano","dense":""},model:{value:(_vm.year),callback:function ($$v) {_vm.year=$$v},expression:"year"}}),_vm._v(" "),_c('v-spacer'),_vm._v(" "),_c('v-select',{attrs:{"items":[
              { text: 'Janeiro', value: '01' },
              { text: 'Fevereiro', value: '02' },
              { text: 'Março', value: '03' },
              { text: 'Abril', value: '04' },
              { text: 'Maio', value: '05' },
              { text: 'Junho', value: '06' },
              { text: 'Julho', value: '07' },
              { text: 'Agosto', value: '08' },
              { text: 'Setembro', value: '09' },
              { text: 'Outubro', value: '10' },
              { text: 'Novembro', value: '11' },
              { text: 'Dezembro', value: '12' }
            ],"label":"Mês","dense":""},model:{value:(_vm.month),callback:function ($$v) {_vm.month=$$v},expression:"month"}}),_vm._v(" "),_c('v-spacer'),_vm._v(" "),_c('v-btn',{staticStyle:{"padding":"10px 0"},attrs:{"fab":"","small":"","color":"primary"},on:{"click":function($event){return _vm.getBpa()}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("fas fa-file-alt")])],1)],1)],1)],1)],1),_vm._v(" "),_c('v-dialog',{attrs:{"scrollable":"","max-width":"400px","persistent":""},model:{value:(_vm.dialogPlace.open),callback:function ($$v) {_vm.$set(_vm.dialogPlace, "open", $$v)},expression:"dialogPlace.open"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v("Digite o número da sua sala")])]),_vm._v(" "),_c('v-card-text',[_c('v-form',{attrs:{"xs12":""},model:{value:(_vm.dialogPlace.valid),callback:function ($$v) {_vm.$set(_vm.dialogPlace, "valid", $$v)},expression:"dialogPlace.valid"}},[_c('v-layout',{attrs:{"row":"","wrap":""}},[_c('v-flex',{attrs:{"xs12":""}},[_c('v-text-field',{attrs:{"label":"SALA","rules":[v => !!v || 'SALA é obrigatório!'],"required":""},on:{"keypress":_vm.isNumber},model:{value:(_vm.dialogPlace.place),callback:function ($$v) {_vm.$set(_vm.dialogPlace, "place", $$v)},expression:"dialogPlace.place"}})],1)],1)],1)],1),_vm._v(" "),_c('v-card-actions',[_c('v-spacer'),_vm._v(" "),_c('v-btn',{attrs:{"color":"primary","disabled":!_vm.dialogPlace.valid},on:{"click":function($event){return _vm.savePlace()}}},[_vm._v("Salvar")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }