<template>
  <div>
    <v-container grid-list-md text-xs-center px-0>
      <v-layout row wrap v-if="$route.params.exam_type_id">
        <v-flex>
          <v-card>
            <v-toolbar flat color="white" class="elevation-1">
              <v-toolbar-title>Exames</v-toolbar-title>
              <v-divider class="mx-2" inset vertical></v-divider>
              {{ exam_type && exam_type.name }}
              <v-divider class="mx-2" inset vertical></v-divider>
              <div>
                <!--<v-icon style="vertical-align: middle;" @click="prev()">arrow_back_ios</v-icon>
                <span>{{formatDate(time)}}</span>
                <v-icon style="vertical-align: middle;" @click="next()">arrow_forward_ios</v-icon>-->
                <v-menu
                  ref="menu1"
                  v-model="menu1"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  lazy
                  transition="scale-transition"
                  offset-y
                  full-width
                  max-width="290px"
                  min-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="time"
                      label="Data"
                      type="date"
                      readonly
                      prepend-icon="event"
                      v-on="on"
                      :loading="loading"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="time"
                    no-title
                    locale="pt"
                    @input="
                      menu1 = false;
                      getList();
                    "
                  ></v-date-picker>
                </v-menu>
              </div>
              <v-divider class="mx-2" inset vertical></v-divider>
              Disponivel: {{ avaliable }} Marcado: {{ marked }}
              <v-spacer></v-spacer>
              <v-menu
                v-if="!user.modules.includes('CONSULTAS_CONFIRMACAO')"
                v-model="menuFilter.menu"
                bottom
                left
                :close-on-content-click="false"
                max-width="400"
                min-width="400"
                offset-x
              >
                <v-btn outline slot="activator" color="primary">IMPRIMIR</v-btn>

                <v-card>
                  <v-card-title class="px-1 py-1">
                    FILTRO
                    <v-spacer></v-spacer>
                    <v-btn
                      outline
                      light
                      small
                      color="primary"
                      @click="
                        menuFilter.menu = true;
                        filtro = {};
                      "
                      >Limpar</v-btn
                    >
                  </v-card-title>

                  <v-layout row wrap>
                    <v-flex xs12 class="px-1">
                      <v-text-field
                        v-model="filtro.start"
                        type="date"
                        label="De"
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs12 class="px-1">
                      <v-text-field
                        v-model="filtro.end"
                        type="date"
                        label="Até"
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs12 class="px-1">
                      <v-select
                        v-model="filtro.arrival"
                        :items="[{value: null, text: 'Selecionar'}, {value: 1, text: 'SIM'}, {value: 0, text: 'NÃO'}]"
                        label="Presença"
                      ></v-select>
                    </v-flex>
                  </v-layout>

                  <v-card-actions>
                    <v-spacer></v-spacer>

                    <v-btn flat @click="menuFilter.menu = false">Fechar</v-btn>
                    <v-btn color="primary" flat @click="goReport()"
                      >IMPRIMIR</v-btn
                    >
                  </v-card-actions>
                </v-card>
              </v-menu>
              <v-btn
                v-if="!user.modules.includes('CONSULTAS_CONFIRMACAO')"
                @click="
                  examFormOpen({
                    exam_type_id: parseInt($route.params.exam_type_id),
                    radiology: exam_type.radiology,
                  })
                "
                color="primary"
                dark
                class="mb-2"
                >Novo</v-btn
              >
            </v-toolbar>
            <v-card-text>
              <table class="table-custom">
                <thead>
                  <tr>
                    <th style="width: 10%">Horário</th>
                    <th style="width: 52px">Chegada</th>
                    <th>Nome do Paciente</th>
                    <th style="width: 400px">Status Ligação</th>
                    <th style="text-align: right; width: 20%"></th>
                  </tr>
                </thead>
                <tbody>
                  <!--:class="{'d-none': !items[horary]}"-->
                  <tr v-for="(horary, i) in times" :key="i">
                    <th
                      style="
                        vertical-align: middle;
                        text-align: center;
                        padding: 5px;
                      "
                    >
                      {{ horary }}
                    </th>
                    <td colspan="5" style="padding: 0px">
                      <table class="table-custom" style="margin: 0px">
                        <tbody>
                          <tr
                            v-for="(item, i) in items[horary]"
                            :key="i"
                            :class="{
                              'green lighten-3': item.arrival,
                              'blue lighten-3':
                                !item.arrival &&
                                item.call_confirm &&
                                item.call_confirm.status == 'CONFIRMOU',
                              'red lighten-3':
                                !item.arrival &&
                                item.call_confirm &&
                                item.call_confirm.status == 'DESISTÊNCIA',
                              'yellow lighten-3':
                                !item.arrival &&
                                item.call_confirm &&
                                item.call_confirm.status != 'CONFIRMOU' &&
                                item.call_confirm.status != 'DESISTÊNCIA',
                            }"
                          >
                            <td
                              style="
                                padding: 5px;
                                width: 52px;
                                vertical-align: middle;
                                text-align: center;
                              "
                            >
                              <v-icon
                                v-if="!item.arrival && !user.modules.includes('CONSULTAS_CONFIRMACAO')"
                                small
                                @click="arrival(item)"
                              >fas fa-check</v-icon>

                              <v-tooltip top v-if="item.arrival && item.arrival_user">
                                <span slot="activator">{{item.arrival}}</span>
                                <span>Marcado por: {{item.arrival_user.name}}</span>
                              </v-tooltip>
                              <span v-if="item.arrival && !item.arrival_user">{{item.arrival}}</span>

                              <v-icon
                                dark
                                @click="arrival_clean(item)"
                                small
                                v-if="item.arrival && isToday && !user.modules.includes('CONSULTAS_CONFIRMACAO')"
                              >fas fa-trash-alt</v-icon>
                            </td>
                            <td style="padding: 5px; vertical-align: middle">
                              {{ item.patient.name }}

                              <v-tooltip top class="mr-2">
                                <v-icon
                                  slot="activator"
                                  color="grey"
                                  small
                                  @click="openPatientView(item.patient_id)"
                                  >fas fa-user</v-icon
                                >
                                <span>Perfil do Usuário</span>
                              </v-tooltip>

                              <v-icon
                                v-if="
                                  !user.modules.includes(
                                    'CONSULTAS_CONFIRMACAO'
                                  )
                                "
                                :color="
                                  !item.patient.rg || !item.patient.address_id
                                    ? 'red'
                                    : 'grey'
                                "
                                small
                                class="mr-2"
                                @click="patientFormOpen(item.patient)"
                                >edit</v-icon
                              >
                            </td>
                            <td
                              style="
                                width: 400px;
                                padding: 5px;
                                vertical-align: middle;
                              "
                            >
                              <div v-if="item.call_confirm">
                                {{ item.call_confirm.status }} /
                                {{
                                  item.call_confirm.created_at
                                    | formatDateTimeShort
                                }}
                                / {{ item.call_confirm.contact_name }}
                              </div>
                            </td>
                            <td
                              style="
                                text-align: right;
                                width: 22.5%;
                                padding: 5px;
                              "
                            >
                              <v-tooltip
                                top
                                v-if="
                                  !user.modules.includes(
                                    'CONSULTAS_CONFIRMACAO'
                                  )
                                "
                              >
                                <v-icon
                                  slot="activator"
                                  small
                                  class="mr-2"
                                  @click="examPrintOpen(item)"
                                  >print</v-icon
                                >
                                <span>Ficha</span>
                              </v-tooltip>

                              <v-icon
                                v-if="
                                  !user.modules.includes(
                                    'CONSULTAS_CONFIRMACAO'
                                  )
                                "
                                small
                                class="mr-2"
                                @click="examFormOpen(item)"
                                >edit</v-icon
                              >

                              <v-icon
                                v-if="
                                  !user.modules.includes(
                                    'CONSULTAS_CONFIRMACAO'
                                  ) &&
                                  ((!item.arrival &&
                                    (isToday || isThreeDays)) ||
                                    user.modules.includes('EXCLUIR CONSULTA'))
                                "
                                small
                                @click="del(item.id)"
                                >delete</v-icon
                              >

                              <v-icon
                                v-if="
                                  user.modules.includes('CONSULTAS_CONFIRMACAO')
                                "
                                small
                                class="mr-2"
                                @click="confirmItemOpen(item)"
                                >call</v-icon
                              >
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                </tbody>
              </table>
            </v-card-text>
          </v-card>
        </v-flex>
      </v-layout>
      <v-layout row wrap v-else>
        <v-flex xs12 sm6>
          <v-card>
            <v-toolbar flat color="white" class="elevation-1">
              <v-toolbar-title>Tipo de Exame</v-toolbar-title>
            </v-toolbar>
            <v-card-text>
              <v-layout row wrap>
                <v-flex xs12 sm6 v-for="(item, i) in exam_types" :key="i">
                  <v-list-tile exact :to="`/exams/${item.id}`">
                    <v-list-tile-content>
                      <v-list-tile-title v-text="item.name"></v-list-tile-title>
                    </v-list-tile-content>
                  </v-list-tile>
                </v-flex>
              </v-layout>
            </v-card-text>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>

    <div v-if="examFormActive.open">
      <exam-form
        :active="examFormActive.open"
        :item="examFormActive.item"
        :finish="examFormClose"
      />
    </div>

    <div v-if="patientFormActive.open">
      <patient-form
        :active="patientFormActive.open"
        :item="patientFormActive.item"
        :finish="patientFormClose"
      />
    </div>

    <div v-if="examPrint.open">
      <exam-print
        :active="examPrint.open"
        :item="examPrint.item"
        :finish="examPrintClose"
      />
    </div>

    <div v-if="confirmItem.open">
      <consultation-confirm
        :active="confirmItem.open"
        :item="confirmItem.item"
        :finish="confirmItemClose"
      />
    </div>

    <div v-if="patientViewActive.open">
      <patient-view
        :active="patientViewActive.open"
        :patient_id="patientViewActive.id"
        :finish="() => (patientViewActive.open = false)"
      ></patient-view>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import moment from "moment";
import { TIMES } from "../constants";
export default {
  data() {
    return {
      valid: false,
      dialog: false,
      time: moment().format("YYYY-MM-DD"),
      isToday: true,
      isThreeDays: false,
      times: TIMES,
      menu1: false,
      exam_type: null,
      user: USER,
      examPrint: { open: false, item: {} },
      pagination: {
        totalItems: 0,
        rowsPerPage: 10,
        descending: true,
        page: 1,
        sortBy: "id",
      },
      items: [],
      examFormActive: { open: false, item: {} },
      patientFormActive: { open: false, item: {} },
      confirmItem: { open: false, item: {} },
      patientViewActive: { open: false },
      menuFilter: { menu: false },
      filtro: {
        origin: null,
        start: moment().format("YYYY-MM-DD"),
        end: moment().format("YYYY-MM-DD"),
        arrival: null
      },
      loading: false,
      exam_types: [],
      examTypesDates: [],
    };
  },
  computed: {
    pages() {
      if (
        this.pagination.rowsPerPage == null ||
        this.pagination.totalItems == null
      )
        return 0;
      return Math.ceil(
        this.pagination.totalItems / this.pagination.rowsPerPage
      );
    },
    marked() {
      let fd = this.examTypesDates.find((o) => o.date == this.time);
      if (fd) {
        return fd.marked;
      }

      return 0;
    },
    avaliable() {
      let fd = this.examTypesDates.find((o) => o.date == this.time);
      if (fd) {
        return fd.limit - fd.marked;
      }

      return 0;
    },
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
    "pagination.page": {
      handler() {
        //this.getList();
        this.getExamType();
      },
      deep: true,
    },
    "$route.params.exam_type_id": {
      handler() {
        //this.getList();
        this.getExamType();
      },
      deep: true,
    },
    "$route.query.radiology": {
      handler() {
        //this.getList();
        this.getExamType();
      },
      deep: true,
    },
  },
  created() {
    this.initialize();
  },
  methods: {
    initialize() {
      this.getExamType();
    },
    getList() {
      if (!this.$route.params.exam_type_id) return;
      this.loading = true;
      return new Promise((resolve, reject) => {
        const params = {
          horary: this.time,
          exam_type_id: this.$route.params.exam_type_id,
          return: 0,
          radiology: this.exam_type.radiology,
        };

        this.isToday = this.time == moment().format("YYYY-MM-DD");
        const diff = moment().diff(this.time, "days");
        console.log("diff", diff);
        this.isThreeDays = USER.type == 13 && diff > -3 && diff < 1;

        console.log(params);
        this.$http
          .get(`/exams/list`, { params })
          .then((response) => response.data)
          .then((json) => {
            setTimeout(() => {
              this.loading = false;

              this.items = _.groupBy(json.items, (obj) => {
                return moment(obj.horary).format("HH:mm");
              });
            }, 300);
            this.getAllowedDates();
          })
          .catch(() => (this.loading = false));
      });
    },
    getExamType() {
      if (!this.$route.params.exam_type_id) {
        this.getExamTypes();
        return;
      }
      //this.getAllowedDates();
      this.$http
        .get(`/exam_types/${this.$route.params.exam_type_id}/show`)
        .then((response) => response.data)
        .then((json) => {
          this.exam_type = json;
          this.getList();
          this.getExamTypes();
        })
        .catch(() => (this.loading = false));
    },
    getExamTypes() {
      this.$http
        .get(
          `/exam_types/list?radiology=${
            this.$route.query.radiology || this.exam_type.radiology
          }`
        )
        .then((response) => response.data)
        .then((json) => {
          this.exam_types = json.items;
        })
        .catch(() => (this.loading = false));
    },
    getAllowedDates() {
      if (!this.$route.params.exam_type_id) return;
      const params = {
        start: moment().format("YYYY-MM-DD"),
        exam_type_id: this.$route.params.exam_type_id,
      };
      this.$http
        .get(`/exam_agendas/list`, { params })
        .then((response) => response.data)
        .then((json) => {
          this.examTypesDates = json.items.filter((o) => o.limit > o.marked);
        })
        .catch(() => (this.loading = false));
    },
    prev() {
      this.time.subtract(1, "days");
      this.getList();
    },
    next() {
      this.time.add(1, "days");
      this.getList();
    },
    examFormOpen(item) {
      this.examFormActive.open = true;
      this.examFormActive.item = item;
    },
    examFormClose() {
      this.examFormActive.open = false;
      this.getList();
    },
    patientFormOpen(item) {
      this.patientFormActive.open = true;
      this.patientFormActive.item = item;
    },
    patientFormClose() {
      this.patientFormActive.open = false;
      this.getList();
    },
    examPrintOpen(item) {
      window.scrollTo(0, 0);
      this.examPrint.open = true;
      this.examPrint.item = item;
    },
    examPrintClose() {
      this.examPrint.open = false;
    },
    confirmItemOpen(item) {
      this.confirmItem.open = true;
      if (!item.call_confirm)
        item.call_confirm = {
          exam_id: item.id,
          status: null,
          patient_id: item.patient_id,
        };
      this.confirmItem.item = item;
    },
    confirmItemClose() {
      this.confirmItem.open = false;
      this.getList();
    },
    openPatientView(id) {
      this.patientViewActive.id = id;
      this.patientViewActive.open = true;
    },
    arrival(item) {
      if (confirm("Deseja realmente marca a chegada?")) {
        this.$http
          .put(`/exams/${item.id}/arrival`, {})
          .then((response) => {
            this.$notify({
              title: `salvo com sucesso!`,
              type: "success",
            });
            this.getList();
          })
          .catch((err) => {
            this.$reqError(`Erro ao salvar!`, err, this);
          });
      }
    },
    arrival_clean(item) {
      if (confirm("Deseja realmente desmarca a chegada?")) {
        this.$http
          .put(`/exams/${item.id}/arrival_clean`, {})
          .then((response) => {
            this.$notify({
              title: `salvo com sucesso!`,
              type: "success",
            });
            this.getList();
          })
          .catch((err) => {
            this.$reqError(`Erro ao salvar!`, err, this);
          });
      }
    },
    allowedDates(val) {
      return this.examTypesDates.find((o) => o.date == val) != null;
    },
    formatDate: (date) => moment(date).format("DD/MM/YYYY"),
    today: () => moment().format("DD/MM/YYYY"),
    del(id) {
      if (confirm("Deseja realmente excluir este Consulta?")) {
        this.$http.delete(`/exams/${id}`).then((response) => {
          this.$notify({ title: `Consulta Excluido!`, type: "success" });
          this.getList();
        });
      }
    },
    goExternal(url) {
      var win = window.open(url, "_blank");
      win.focus();
    },
    goReport() {
      this.menuFilter.menu = false;
      let origin = "";
      if (this.filtro.origin) origin = `&origin=${this.filtro.origin}`;
      let arrival = "";
      if (this.filtro.arrival != null)
        arrival = `&arrival=${this.filtro.arrival}`;

      let exam_type_id = `&exam_type_id=${this.$route.params.exam_type_id}`;
      let url = `/exams/reports/?start=${this.filtro.start}&end=${this.filtro.end}${exam_type_id}${origin}${arrival}`;
      window.open(url, "_blank");
    },
    debounceInput: _.debounce(function () {
      setTimeout(() => {
        this.getList();
      }, 200);
    }, 400),
  },
};
</script>

<style lang="css" scoped>
table {
  font-size: 13px;
}
</style>
