<template>
  <div>
    <v-container grid-list-md text-xs-center px-0>
      <v-layout row wrap>
        <v-flex xs2></v-flex>
        <v-flex xs8>
          <v-form ref="form" v-model="valid">
            <v-text-field
              v-if="user.type == 3"
              v-model="name"
              label="Nome"
              disabled
            ></v-text-field>
            <v-text-field
              v-else
              v-model="name"
              label="Nome"
              required
            ></v-text-field>
            <v-text-field
              v-model="email"
              :rules="emailRules"
              label="Email"
              required
            ></v-text-field>
            <v-text-field
              v-model="password"
              label="Senha*"
              :rules="[
                v => !!v || 'Campo Obrigatório',
                v => (v && v.length >= 6) || 'Senha invalida'
              ]"
              type="password"
            ></v-text-field>

            <v-btn
              color="primary"
              :dark="valid"
              :light="!valid"
              class="mb-2"
              :disabled="!valid"
              @click="submit"
              >SALVAR</v-btn
            >
          </v-form>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>
<script>
import axios from "axios";

export default {
  data() {
    return {
      valid: true,
      user: USER,
      user_id: USER.id,
      name: USER.name,
      email: USER.email,
      emailRules: [
        v => !!v || "E-mail is required",
        v => /.+@.+/.test(v) || "E-mail must be valid"
      ],
      password: USER.password
    };
  },

  methods: {
    submit() {
      if (this.$refs.form.validate()) {
        // Native form submission is not yet supported

        this.$http
          .put(`/users/${this.user_id}`, {
            name: this.name,
            email: this.email,
            password: this.password
          })
          .then(response => {
            this.$notify({
              title: `salvo com sucesso!`,
              group: "notifications",
              type: "success"
            });
          })
          .catch(err => {
            this.$reqError(`Erro ao salvar!`, err, this);
          });
      }
    },
    clear() {
      this.$refs.form.reset();
    }
  }
};
</script>
