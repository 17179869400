<template>
  <div>
    <v-container grid-list-md text-xs-center px-0>
      <v-layout row wrap>
        <v-flex xs12>
          <v-toolbar flat color="white" class="elevation-1">
            <v-toolbar-title>Ligações - Relatório</v-toolbar-title>
            <v-divider class="mx-2" inset vertical></v-divider>
            <v-spacer></v-spacer>
            <v-text-field
              v-model.lazy="search"
              append-icon="search"
              label="Buscar"
              single-line
              hide-details
              class="hidden-md-and-down"
              @click:append="getList()"
              v-on:keyup.enter="getList"
            ></v-text-field>
            <v-spacer></v-spacer>
            <v-menu
              v-model="filtro.menu"
              bottom
              left
              :close-on-content-click="false"
              max-width="400"
              min-width="400"
              offset-x
            >
              <v-btn outline slot="activator" color="primary">OPÇÕES</v-btn>

              <v-card>
                <v-card-title class="px-1 py-1">
                  OPÇÕES
                  <v-spacer></v-spacer>
                  <v-btn
                    outline
                    light
                    small
                    color="primary"
                    @click="filtro.menu = true; filtro = {};"
                  >Limpar</v-btn>
                </v-card-title>

                <v-layout row wrap>
                  <v-flex xs12 class="px-1">
                    <v-text-field v-model="filtro.start" type="date" label="De"></v-text-field>
                  </v-flex>
                  <v-flex xs12 class="px-1">
                    <v-text-field v-model="filtro.end" type="date" label="Até"></v-text-field>
                  </v-flex>
                  <v-flex xs12 class="px-1">
                    <v-select
                      v-model="filtro.sector"
                      :items="[{value: null, text: 'Selecionar'}, {value: 'Radiologia', text: 'Radiologia'}]"
                      label="Setor"
                    ></v-select>
                  </v-flex>
                </v-layout>

                <v-card-actions>
                  <v-spacer></v-spacer>

                  <v-btn flat @click="menuFilter.menu = false">Fechar</v-btn>
                  <v-btn color="primary" flat @click="goReport()">IMPRIMIR</v-btn>
                  <v-btn color="primary" @click="filtrar()">FILTRAR</v-btn>
                </v-card-actions>
              </v-card>
            </v-menu>
          </v-toolbar>
        </v-flex>

        <v-flex xs12 v-for="(tb, i) in items" :key="i">
          <v-toolbar flat color="white" class="elevation-1">
            <v-toolbar-title>{{tb[0].created_at | formatDate}}</v-toolbar-title>
            <v-spacer></v-spacer>
            Total: {{tb.length}}
          </v-toolbar>

          <v-data-table
            :headers="columns"
            :items="tb"
            :loading="loading"
            hide-actions
            class="elevation-1"
          >
            <template slot="items" slot-scope="props">
              <tr>
                <td>{{ props.item.user && props.item.user.name}}</td>
                <td>{{ props.item.created_at | formatDateTimeShort }}</td>
                <td>{{ props.item.patient && props.item.patient.name}}</td>
                <td>{{ props.item.patient && props.item.patient.rh}}</td>
                <td>{{ props.item.patient && props.item.patient.phone | formatPhone}}</td>
                <td>{{ props.item.status}}</td>
                <td>{{ props.item.contact_name}}</td>
                <td>{{ props.item.obs}}</td>
              </tr>
            </template>

            <template slot="no-data">Desculpe, nenhum dado para ser exibido!</template>
          </v-data-table>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>

<script>
import _ from "lodash";
import moment from "moment";
export default {
  data() {
    return {
      valid: false,
      search: "",
      items: [],
      filtro: {
        start: moment()
          .startOf("month")
          .format("YYYY-MM-DD"),
        end: moment()
          .endOf("month")
          .format("YYYY-MM-DD"),
        sector: null,
        menu: false
      },
      columns: [
        { value: "user.name", text: "Usuário", sortable: false },
        { value: "created_at", text: "Data e Hora", sortable: false },
        { value: "patient.name", text: "Paciente", sortable: false },
        { value: "patient.rh", text: "Prontuário", sortable: false },
        { value: "patient.phone", text: "Telefone", sortable: false },
        { value: "status", text: "Status", sortable: false },
        { value: "contact_name", text: "Falou com quem?", sortable: false },
        { value: "obs", text: "obs", sortable: false }
      ],
      loading: false
    };
  },
  computed: {
    computedStart() {
      return this.formatDate(this.filtro.start);
    },
    computedEnd() {
      return this.formatDate(this.filtro.end);
    }
  },
  created() {
    this.initialize();
  },
  methods: {
    initialize() {
      this.getList();
    },
    getList() {
      this.loading = true;
      return new Promise((resolve, reject) => {
        const params = {
          search: this.search,
          ...this.filtro,
          modal: undefined
        };
        this.$http
          .get(`/call_confirms/list`, { params })
          .then(response => response.data)
          .then(json => {
            setTimeout(() => {
              this.loading = false;

              this.items = _.toArray(
                _.groupBy(json.items, obj => this.formatDate(obj.created_at))
              );
            }, 1000);
          })
          .catch(() => (this.loading = false));
      });
    },
    formatDate: date => (date ? moment(date).format("DD/MM/YYYY") : ""),
    filtrar() {
      this.filtro.menu = false;
      this.getList();
    },
    goReport() {
      this.filtro.menu = false;
      let sector = "";
      if (this.filtro.sector) sector = `&sector=${this.filtro.sector}`;
      let url = `/call_confirms/reports/?start=${this.filtro.start}&end=${this.filtro.end}${sector}`;
      window.open(url, "_blank");
    }
  }
};
</script>
