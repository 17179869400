<template>
  <div>
    <!--<header-page />-->
    <v-container grid-list-md text-xs-center px-0>
      <v-layout row wrap>
        <v-flex xs12>
          <v-toolbar flat color="white" class="elevation-1">
            <v-toolbar-title>Usuários</v-toolbar-title>
            <v-divider class="mx-2" inset vertical></v-divider>
            <v-spacer></v-spacer>
            <v-text-field
              v-model.lazy="search"
              append-icon="search"
              label="Buscar"
              single-line
              hide-details
              class="hidden-md-and-down"
            ></v-text-field>
            <v-spacer></v-spacer>
            <v-btn color="primary" dark class="mb-2" @click="openPatientForm({})">Novo</v-btn>
          </v-toolbar>

          <v-data-table
            :headers="columns"
            :items="items"
            :pagination.sync="pagination"
            :total-items="pagination.totalItems"
            :loading="loading"
            hide-actions
            class="elevation-1"
          >
            <template slot="items" slot-scope="props">
              <tr>
                <td>{{ props.item.rh }}</td>
                <td>{{ props.item.name }}</td>
                <td>{{ props.item.same_local }}</td>
                <td>{{ props.item.same }}</td>
                <td class="text-xs-center px-0">
                  <v-tooltip top class="mr-2">
                    <v-icon
                      slot="activator"
                      small
                      @click="goExternal('/patients/' + props.item.id + '/tag')"
                    >fas fa-barcode</v-icon>
                    <span>Imprimir Etiqueta</span>
                  </v-tooltip>

                  <v-tooltip top class="mr-2">
                    <v-icon
                      slot="activator"
                      small
                      @click="openPatientView(props.item.id)"
                    >fas fa-user</v-icon>
                    <span>Perfil do Usuário</span>
                  </v-tooltip>

                  <v-tooltip top class="mr-2">
                    <v-icon
                      slot="activator"
                      small
                      @click="patientUnionOpen(props.item)"
                    >fas fa-random</v-icon>
                    <span>Unir Cadastro</span>
                  </v-tooltip>

                  <v-icon small @click="openPatientForm(props.item)" class="mr-2">edit</v-icon>

                  <!--<v-icon small @click="del(props.item.id)">delete</v-icon>-->
                </td>
              </tr>
            </template>

            <template slot="no-data">Desculpe, nenhum dado para ser exibido!</template>
          </v-data-table>
          <div class="text-xs-center pt-2">
            <v-pagination v-model="pagination.page" :length="pages"></v-pagination>
          </div>
        </v-flex>
      </v-layout>
    </v-container>

    <div v-if="patientFormActive.open">
      <patient-form
        :active="patientFormActive.open"
        :item="patientFormActive.item"
        :finish="onClosePatientForm"
      ></patient-form>
    </div>

    <div v-if="patientViewActive.open">
      <patient-view
        :active="patientViewActive.open"
        :patient_id="patientViewActive.id"
        :finish="() => patientViewActive.open = false"
      ></patient-view>
    </div>

    <div v-if="patientUnionActive.open">
      <patient-union
        :active="patientUnionActive.open"
        :item="patientUnionActive.item"
        :finish="patientUnionClose"
      ></patient-union>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
export default {
  data() {
    return {
      search: "",
      pagination: {
        totalItems: 0,
        rowsPerPage: 10,
        descending: false,
        page: 1,
        sortBy: "rh"
      },
      items: [],
      columns: [
        { value: "rh", text: "Prontuário" },
        { value: "name", text: "Nome" },
        { value: "same_local", text: "arquivo" },
        { value: "same", text: "Atualmente", sortable: false },
        { text: "", value: "", sortable: false }
      ],
      patientFormActive: { open: false, item: {} },
      patientViewActive: { open: false },
      patientUnionActive: { open: false, item: {} },
      loading: false
    };
  },
  computed: {
    pages() {
      if (
        this.pagination.rowsPerPage == null ||
        this.pagination.totalItems == null
      )
        return 0;

      return Math.ceil(
        this.pagination.totalItems / this.pagination.rowsPerPage
      );
    }
  },
  watch: {
    "pagination.page": {
      handler() {
        this.getList();
      },
      deep: true
    },
    search: function() {
      this.debounceInput();
    }
  },
  created() {
    this.initialize();
  },
  methods: {
    initialize() {
      //console.log('this.notify', this.$notify);
    },
    getList() {
      this.loading = true;
      return new Promise((resolve, reject) => {
        const params = {
          ...this.pagination,
          totalItems: undefined,
          search: this.search
        };
        this.$http
          .get(`/patients/list`, { params })
          .then(response => response.data)
          .then(json => {
            setTimeout(() => {
              this.loading = false;

              this.items = json.items;
              this.pagination.totalItems = json.total;
            }, 1000);
          })
          .catch(() => (this.loading = false));
      });
    },
    del(id) {
      if (confirm("Deseja realmente excluir este usuário?")) {
        this.$http.delete(`/patients/${id}`).then(response => {
          this.$notify({
            title: `Usuário Excluido!`,
            group: "notifications",
            type: "success"
          });
          this.getList();
        });
      }
    },
    debounceInput: _.debounce(function() {
      setTimeout(() => {
        this.getList();
      }, 200);
    }, 400),
    goExternal(url) {
      var win = window.open(url, "_blank");
      win.focus();
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${month}/${day}/${year}`;
    },
    openPatientForm(item) {
      this.patientFormActive.open = true;
      this.patientFormActive.item = item;
    },
    onClosePatientForm(patient) {
      this.patientFormActive.open = false;
      this.getList();
    },
    openPatientView(id) {
      this.patientViewActive.id = id;
      this.patientViewActive.open = true;
    },
    patientUnionOpen(item) {
      this.patientUnionActive.open = true;
      this.patientUnionActive.item = item;
    },
    patientUnionClose() {
      this.patientUnionActive.open = false;
      this.getList();
    }
  }
};
</script>
