<template>
<div>

	<v-container grid-list-md text-xs-center px-0>
		<notifications position="bottom center" />
		<v-layout row wrap>
			<v-flex xs12>

				<v-toolbar flat color="white" class="elevation-1">
					<v-toolbar-title>Categorias</v-toolbar-title>
					<v-divider class="mx-2" inset vertical></v-divider>
					<v-spacer></v-spacer>
					<v-btn @click="billTypeFormOpen({})" color="primary" dark class="mb-2">Novo</v-btn>
				</v-toolbar>

				<v-data-table
					:headers="columns"
					:items="items"
      		:loading="loading"
					hide-actions
					class="elevation-1"
				>
					<template slot="items" slot-scope="props">
						<td>
							<div style="float: left" v-html="getTree(props.item.code )"></div>
              <div style="float: left">&nbsp;{{props.item.code }} - {{props.item.name }}</div>
              <div style="float: left" v-if="props.item.mark">&nbsp; - <b>Meta:</b> {{props.item.mark | formatMoney }}</div>
						</td>
						<td class="text-xs-right">
							<v-icon small class="mr-2" @click="billTypeFormOpen(props.item)" >
								edit
							</v-icon>
							<!--<v-icon small @click="del(props.item.id)" >
								delete
							</v-icon>-->
						</td>
					</template>

					<template slot="no-data">
						Desculpe, nenhum dado para ser exibido!
					</template>
				</v-data-table>
			</v-flex>
		</v-layout>
  </v-container>

	<div v-if="billTypeFormActive.open">
		<bill-type-form
			:active="billTypeFormActive.open"
			:item="billTypeFormActive.item"
			:finish="billTypeFormClose"
		></bill-type-form>
	</div>
</div>
</template>

<script>
import _ from 'lodash';
export default {

  data() {
		return {
			valid: false,
			dialog: false,
			items: [],
			columns: [
				{ value: 'id', text: 'Nome', sortable: false },
				{ text: '', value: '', sortable: false }
			],
			billTypeFormActive: { open: false, item: {} },
			loading: false
		}
	},
	watch: {
		dialog (val) {
			val || this.close()
		}
	},
	created () {
		this.initialize();
	},
	methods: {
		initialize () {
			this.getList();
		},
		getList() {
			this.loading = true
			return new Promise((resolve, reject) => {
				this.$http.get(`/bill_types/list`)
				.then(response => response.data).then(json => {
					setTimeout(() => {
            this.loading = false;

						this.items = json.items;
          }, 1000);
				}).catch(() => this.loading = false);
			});
		},
		billTypeFormOpen (item) {
			this.billTypeFormActive.open = true;
			this.billTypeFormActive.item = item;
		},
		billTypeFormClose () {
			this.billTypeFormActive.open = false;
			this.getList();
    },
    dialogAbaterOpen(item) {
      this.dialogAbater.open = true;
      this.dialogAbater.item = JSON.parse(JSON.stringify(item));
      this.dialogAbater.item.paid_date = this.dateNow();
    },
		del(id) {
			if (confirm('Deseja realmente excluir este Custo?')) {
				this.$http.delete(`/bill_types/${id}`)
				.then(response => {
					this.$notify({title: `Custo Excluido!`,group: "notifications", type: 'success'});
					this.getList();
				});
			}
		},
		getTree(code){
			if (code.length < 2) { return ''; }

			const sp = '&nbsp;&nbsp;&nbsp;&nbsp;';
			let s = sp;

			if (code.length > 4) { s = s + sp; }
			if (code.length > 6) { s = s + sp; }
			if (code.length > 8) { s = s + sp; }
			return s + '└─';
		},
		debounceInput: _.debounce(function () {
      setTimeout(() => {this.getList();}, 200);
		}, 400)
	}
}
</script>
