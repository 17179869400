<template>
  <v-container fluid>
    <v-layout row wrap>
      <v-flex xs12 sm3>
        <v-text-field
          class="mt-2"
          small
          dense
          outline
          single-line
          v-model="rh"
          label="Prontuário para transferência"
          append-icon="fas fa-plus"
          @click:append="transferByRh()"
        ></v-text-field>
      </v-flex>
      <v-flex xs12 sm4 offset-sm1>
        <v-text-field
          v-model.lazy="search"
          append-icon="search"
          label="Buscar"
          single-line
          hide-details
        ></v-text-field>
      </v-flex>
      <v-flex xs12 sm3 offset-sm1>
        <v-text-field
          class="mt-2"
          small
          dense
          outline
          single-line
          v-model="rhauto"
          label="RH com leitor de código"
        ></v-text-field>
      </v-flex>
    </v-layout>
    <v-layout row wrap>
      <v-flex xs12 class="px-1">
        <v-toolbar flat color="white" class="elevation-1">
          <v-toolbar-title>SOLICITAÇÕES AMBULATÓRIO</v-toolbar-title>
          <v-divider class="mx-2" inset vertical></v-divider>
          <v-menu
            ref="menu1"
            v-model="menu1"
            :close-on-content-click="false"
            :nudge-right="40"
            lazy
            transition="scale-transition"
            offset-y
            full-width
            max-width="290px"
            min-width="290px"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                v-model="time"
                type="date"
                readonly
                prepend-icon="event"
                v-on="on"
                :loading="loading"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="time"
              no-title
              locale="pt"
              @input="
                menu1 = false;
                getList();
              "
            ></v-date-picker>
          </v-menu>
          <v-spacer></v-spacer>
          <v-menu
            v-model="filtro.menu"
            bottom
            left
            :close-on-content-click="false"
            max-width="400"
            min-width="400"
            offset-x
          >
            <v-btn outline slot="activator" color="primary">Filtros</v-btn>

            <v-card>
              <v-card-title class="px-1 py-1">
                <v-spacer></v-spacer>
                <v-btn
                  outline
                  light
                  small
                  color="primary"
                  @click="
                    filtro.menu = true;
                    filtro = { menu: true };
                  "
                  >Limpar</v-btn
                >
              </v-card-title>

              <v-layout row wrap>
                <v-flex xs12 class="px-1">
                  <select-custom
                    url="/specialties/list?sortBy=name"
                    v-model="filtro.specialty_id"
                    label="Especialidade"
                  />
                </v-flex>

                <v-flex xs12 class="px-1">
                  <select-custom
                    :url="'/users/list?type=3sortBy=name'"
                    v-model="filtro.doctor_id"
                    label="Médico"
                  />
                </v-flex>

                <v-flex xs12 class="px-1">
                  <v-select
                    label="Pendente"
                    v-model="filtro.active"
                    :items="[
                      { text: 'SIM', value: 1 },
                      { text: 'NÃO', value: 0 },
                    ]"
                  ></v-select>
                </v-flex>

                <v-flex xs12 class="px-1">
                  <select-custom
                    url="/surgery_types/list?sortBy=name"
                    v-model="filtro.surgery_type_id"
                    label="Cirurgia"
                  />
                </v-flex>
              </v-layout>

              <v-card-actions>
                <v-spacer></v-spacer>

                <v-btn flat @click="filtro.menu = false">Fechar</v-btn>
                <v-btn color="primary" flat @click="getList()">Filtrar</v-btn>
              </v-card-actions>
            </v-card>
          </v-menu>
        </v-toolbar>

        <v-data-table
          :headers="columns"
          :items="items"
          :pagination.sync="pagination"
          :total-items="pagination.totalItems"
          :loading="loading"
          hide-actions
          class="elevation-1"
        >
          <template slot="items" slot-scope="props">
            <tr>
              <td>{{ props.item.user.name }}</td>
              <td>{{ props.item.horary | formatDateTimeShort }}</td>
              <td>{{ props.item.patient.name }}</td>
              <td>{{ props.item.patient.rh }}</td>
              <td>{{ props.item.patient.death ? "SIM" : "" }}</td>
              <td>
                {{
                  props.item.consultation &&
                  props.item.consultation.prescription
                    ? "SIM"
                    : ""
                }}
              </td>
              <td>
                {{ props.item.specialty && props.item.specialty.name }}
                {{ props.item.surgery_type && props.item.surgery_type.name }}
              </td>
              <td>{{ props.item.doctor && props.item.doctor.name }}</td>
              <td>{{ props.item.patient.same_local }}</td>
              <td>{{ props.item.patient.same }}</td>
              <td>{{ props.item.destiny }}</td>
              <td class="text-xs-center px-0">
                <v-icon
                  v-if="props.item.active"
                  small
                  @click="openTransfer(props.item)"
                  >fas fa-arrow-right</v-icon
                >
                <v-icon
                  v-if="props.item.active"
                  color="red"
                  small
                  @click="finishOpen(props.item)"
                  class="ml-3"
                  >fas fa-arrow-down</v-icon
                >
              </td>
            </tr>
          </template>

          <template slot="no-data"
            >Desculpe, nenhum dado para ser exibido!</template
          >
        </v-data-table>
        <div class="text-xs-center pt-2">
          <v-pagination
            v-model="pagination.page"
            :length="pages"
          ></v-pagination>
        </div>
      </v-flex>

      <v-flex xs12 class="px-1 mt-4">
        <v-toolbar flat color="white" class="elevation-1">
          <v-toolbar-title>SOLICITAÇÕES INTERNAÇÃO</v-toolbar-title>

          <v-spacer></v-spacer>
          <v-menu
            v-model="filtroHosp.menu"
            bottom
            left
            :close-on-content-click="false"
            max-width="400"
            min-width="400"
            offset-x
          >
            <v-btn outline slot="activator" color="primary">Filtros</v-btn>

            <v-card>
              <v-card-title class="px-1 py-1">
                <v-spacer></v-spacer>
                <v-btn
                  outline
                  light
                  small
                  color="primary"
                  @click="
                    filtroHosp.menu = true;
                    filtroHosp = { menu: true };
                  "
                  >Limpar</v-btn
                >
              </v-card-title>

              <v-layout row wrap>
                <v-flex xs12 class="px-1">
                  <select-custom
                    url="/specialties/list?sortBy=name"
                    v-model="filtroHosp.specialty_id"
                    label="Especialidade"
                  />
                </v-flex>

                <v-flex xs12 class="px-1">
                  <select-custom
                    :url="'/users/list?type=3sortBy=name'"
                    v-model="filtroHosp.doctor_id"
                    label="Médico"
                  />
                </v-flex>

                <v-flex xs12 class="px-1">
                  <v-select
                    label="Pendente"
                    v-model="filtroHosp.active"
                    :items="[
                      { text: 'SIM', value: 1 },
                      { text: 'NÃO', value: 0 },
                    ]"
                  ></v-select>
                </v-flex>
              </v-layout>

              <v-card-actions>
                <v-spacer></v-spacer>

                <v-btn flat @click="filtroHosp.menu = false">Fechar</v-btn>
                <v-btn color="primary" flat @click="getListHosp()"
                  >Filtrar</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-menu>
        </v-toolbar>

        <v-data-table
          :headers="columns"
          :items="itemsHosp"
          :pagination.sync="pagination_hosp"
          :total-items="pagination_hosp.totalItems"
          :loading="loadingHosp"
          hide-actions
          class="elevation-1"
        >
          <template slot="items" slot-scope="props">
            <tr>
              <td>{{ props.item.user.name }}</td>
              <td>{{ props.item.horary | formatDateTimeShort }}</td>
              <td>{{ props.item.patient.name }}</td>
              <td>{{ props.item.patient.rh }}</td>
              <td>{{ props.item.patient.death ? "SIM" : "" }}</td>
              <td>
                {{
                  props.item.consultation &&
                  props.item.consultation.prescription
                    ? "SIM"
                    : ""
                }}
              </td>
              <td>{{ props.item.specialty && props.item.specialty.name }}</td>
              <td>{{ props.item.doctor && props.item.doctor.name }}</td>
              <td>{{ props.item.patient.same_local }}</td>
              <td>{{ props.item.patient.same }}</td>
              <td>{{ props.item.destiny }}</td>
              <td class="text-xs-center px-0">
                <v-icon
                  v-if="props.item.active"
                  small
                  @click="openTransfer(props.item)"
                  class="mr-3"
                  >fas fa-arrow-right</v-icon
                >

                <v-icon
                  v-if="props.item.active"
                  color="red"
                  small
                  @click="finishOpen(props.item)"
                  class="ml-3"
                  >fas fa-arrow-down</v-icon
                >
              </td>
            </tr>
          </template>

          <template slot="no-data"
            >Desculpe, nenhum dado para ser exibido!</template
          >
        </v-data-table>
        <div class="text-xs-center pt-2">
          <v-pagination
            v-model="pagination_hosp.page"
            :length="pagesHosp"
          ></v-pagination>
        </div>
      </v-flex>

      <v-flex xs12 class="px-1 mt-4">
        <v-toolbar flat color="white" class="elevation-1">
          <v-toolbar-title>OUTRAS SOLICITAÇÕES</v-toolbar-title>

          <v-spacer></v-spacer>
          <v-menu
            v-model="filtroOutros.menu"
            bottom
            left
            :close-on-content-click="false"
            max-width="400"
            min-width="400"
            offset-x
          >
            <v-btn outline slot="activator" color="primary">Filtros</v-btn>

            <v-card>
              <v-card-title class="px-1 py-1">
                <v-spacer></v-spacer>
                <v-btn
                  outline
                  light
                  small
                  color="primary"
                  @click="
                    filtroOutros.menu = true;
                    filtroOutros = { menu: true };
                  "
                  >Limpar</v-btn
                >
              </v-card-title>

              <v-layout row wrap>
                <v-flex xs12 class="px-1">
                  <select-custom
                    url="/specialties/list?sortBy=name"
                    v-model="filtroOutros.specialty_id"
                    label="Especialidade"
                  />
                </v-flex>

                <v-flex xs12 class="px-1">
                  <select-custom
                    :url="'/users/list?type=3sortBy=name'"
                    v-model="filtroOutros.doctor_id"
                    label="Médico"
                  />
                </v-flex>

                <v-flex xs12 class="px-1">
                  <v-select
                    label="Pendente"
                    v-model="filtroOutros.active"
                    :items="[
                      { text: 'SIM', value: 1 },
                      { text: 'NÃO', value: 0 },
                    ]"
                  ></v-select>
                </v-flex>
                <v-flex xs12 class="px-1">
                  <select-custom
                    url="/surgery_types/list?sortBy=name"
                    v-model="filtroOutros.surgery_type_id"
                    label="Cirurgia"
                  />
                </v-flex>
              </v-layout>

              <v-card-actions>
                <v-spacer></v-spacer>

                <v-btn flat @click="filtroOutros.menu = false">Fechar</v-btn>
                <v-btn color="primary" flat @click="getListOutros()"
                  >Filtrar</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-menu>
        </v-toolbar>

        <v-data-table
          :headers="columns"
          :items="itemsOutros"
          :pagination.sync="pagination_outros"
          :total-items="pagination_outros.totalItems"
          :loading="loadingOutros"
          hide-actions
          class="elevation-1"
        >
          <template slot="items" slot-scope="props">
            <tr>
              <td>{{ props.item.user.name }}</td>
              <td>{{ props.item.horary | formatDateTimeShort }}</td>
              <td>{{ props.item.patient.name }}</td>
              <td>{{ props.item.patient.rh }}</td>
              <td>{{ props.item.patient.death ? "SIM" : "" }}</td>
              <td>
                {{
                  props.item.consultation &&
                  props.item.consultation.prescription
                    ? "SIM"
                    : ""
                }}
              </td>
              <td>
                {{ props.item.specialty && props.item.specialty.name }}
                {{ props.item.surgery_type && props.item.surgery_type.name }}
              </td>
              <td>{{ props.item.doctor && props.item.doctor.name }}</td>
              <td>{{ props.item.patient.same_local }}</td>
              <td>{{ props.item.patient.same }}</td>
              <td>{{ props.item.destiny }}</td>
              <td class="text-xs-center px-0">
                <v-icon
                  v-if="props.item.active"
                  small
                  @click="openTransfer(props.item)"
                  >fas fa-arrow-right</v-icon
                >
                <v-icon
                  v-if="props.item.active"
                  color="red"
                  small
                  @click="finishOpen(props.item)"
                  class="ml-3"
                  >fas fa-arrow-down</v-icon
                >
              </td>
            </tr>
          </template>

          <template slot="no-data"
            >Desculpe, nenhum dado para ser exibido!</template
          >
        </v-data-table>
        <div class="text-xs-center pt-2">
          <v-pagination
            v-model="pagination_hosp.page"
            :length="pagesHosp"
          ></v-pagination>
        </div>
      </v-flex>
    </v-layout>

    <!--<v-fab-transition>
      <v-btn dark fab fixed bottom right color="primary" @click="openPrint()">
        <v-icon>print</v-icon>
      </v-btn>
    </v-fab-transition>-->

    <v-dialog v-model="finishDialog.open" max-width="400px">
      <v-card>
        <v-card-title class="title">Dar Baixa</v-card-title>
        <v-card-text>
          <v-container>
            <v-layout row wrap>
              <v-flex xs12>
                <v-text-field
                  v-model="finishDialog.item.same_local"
                  label="Local"
                ></v-text-field>
              </v-flex>
            </v-layout>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click.native="finishItem">FINALIZAR</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import _ from "lodash";
import moment from "moment";
export default {
  data() {
    return {
      valid: false,
      search: "",
      pagination: {
        totalItems: 0,
        rowsPerPage: 10,
        descending: false,
        page: 1,
        sortBy: "id",
      },
      pagination_hosp: {
        totalItems: 0,
        rowsPerPage: 10,
        descending: false,
        page: 1,
        sortBy: "id",
      },
      pagination_outros: {
        totalItems: 0,
        rowsPerPage: 10,
        descending: false,
        page: 1,
        sortBy: "id",
      },
      filtro: {
        menu: false,
        doctor_id: null,
        specialty_id: null,
        surgery_type_id: null,
        active: 1,
      },
      filtroHosp: {
        menu: false,
        doctor_id: null,
        specialty_id: null,
        active: 1,
      },
      filtroOutros: {
        menu: false,
        doctor_id: null,
        specialty_id: null,
        surgery_type_id: null,
        active: 1,
      },
      items: [],
      itemsHosp: [],
      itemsOutros: [],
      columns: [
        { value: "user", text: "Solicitado por", sortable: false },
        { value: "horary", text: "Data e Hora" },
        { value: "patient.name", text: "Paciente", sortable: false },
        { value: "patient.rh", text: "Prontuário", sortable: false },
        { value: "patient.death", text: "Óbito", sortable: false },
        {
          value: "consultation.prescription",
          text: "Receita",
          sortable: false,
        },
        { value: "specialty.name", text: "Esp.", sortable: false },
        { value: "doctor.name", text: "Médico", sortable: false },
        { value: "patient.same_local", text: "Arquivo", sortable: false },
        { value: "patient.same", text: "Atual.", sortable: false },
        { value: "destiny", text: "Destino" },
        { text: "", value: "", sortable: false },
      ],
      menu1: false,
      time: moment().format("YYYY-MM-DD"),
      loading: false,
      loadingHosp: false,
      loadingOutros: false,
      selected: [],
      rh: "",
      rhauto: "",
      finishDialog: {
        open: false,
        item: {},
      },
    };
  },
  computed: {
    pages() {
      console.log("pages");
      if (
        this.pagination.rowsPerPage == null ||
        this.pagination.totalItems == null
      )
        return 0;

      return Math.ceil(
        this.pagination.totalItems / this.pagination.rowsPerPage
      );
    },
    pagesHosp() {
      console.log("pagesHosp");
      if (
        this.pagination_hosp.rowsPerPage == null ||
        this.pagination_hosp.totalItems == null
      )
        return 0;

      return Math.ceil(
        this.pagination_hosp.totalItems / this.pagination_hosp.rowsPerPage
      );
    },
    pagesOutros() {
      console.log("pagesOutros");
      if (
        this.pagination_outros.rowsPerPage == null ||
        this.pagination_outros.totalItems == null
      )
        return 0;

      return Math.ceil(
        this.pagination_outros.totalItems / this.pagination_outros.rowsPerPage
      );
    },
  },

  watch: {
    "pagination.page": {
      handler() {
        console.log("pagination.page");
        this.getList();
      },
      deep: true,
    },
    "pagination_hosp.page": {
      handler() {
        console.log("pagination_hosp.page");
        this.getListHosp();
      },
      deep: true,
    },
    "pagination_outros.page": {
      handler() {
        console.log("pagination_outros.page");
        this.getListOutros();
      },
      deep: true,
    },
    search: function () {
      this.debounceInput();
    },
    rhauto: function (n, o) {
      this.debounceInputRh();
    },
  },
  created() {
    this.initialize();
  },
  methods: {
    initialize() {
      this.getListHosp();
    },
    getList() {
      //this.getListHosp();
      this.loading = true;
      return new Promise((resolve, reject) => {
        const params = {
          ...this.pagination,
          ...this.filtro,
          totalItems: undefined,
          search: this.search,
          horary: this.time,
          done: 0,
          destiny: "AMBULATÓRIO",
        };
        this.$http
          .get(`/same/list`, { params })
          .then((response) => response.data)
          .then((json) => {
            setTimeout(() => {
              this.loading = false;
              this.filtro.menu = false;
              this.items = json.items;
              this.pagination.totalItems = json.total;
            }, 1000);
          })
          .catch(() => (this.loading = false));
      });
    },
    getListHosp() {
      this.loadingHosp = true;
      return new Promise((resolve, reject) => {
        const params = {
          ...this.pagination_hosp,
          ...this.filtroHosp,
          totalItems: undefined,
          search: this.search,
          done: 0,
          destiny: "INTERNAÇÃO",
        };
        this.$http
          .get(`/same/list`, { params })
          .then((response) => response.data)
          .then((json) => {
            setTimeout(() => {
              this.loadingHosp = false;
              this.filtroHosp.menu = false;
              this.itemsHosp = json.items;
              this.pagination_hosp.totalItems = json.total;
            }, 1000);
          })
          .catch(() => (this.loadingHosp = false));
      });
    },
    getListOutros() {
      this.loadingOutros = true;
      return new Promise((resolve, reject) => {
        const params = {
          ...this.pagination_outros,
          ...this.filtroOutros,
          totalItems: undefined,
          search: this.search,
          done: 0,
          except_destinys: ["INTERNAÇÃO", "AMBULATÓRIO"],
        };
        this.$http
          .get(`/same/list`, { params })
          .then((response) => response.data)
          .then((json) => {
            setTimeout(() => {
              this.loadingOutros = false;
              this.filtroOutros.menu = false;
              this.itemsOutros = json.items;
              this.pagination_outros.totalItems = json.total;
            }, 1000);
          })
          .catch(() => (this.loadingOutros = false));
      });
    },
    openTransfer(item) {
      console.log(item);
      if (confirm(`Deseja realmente transferir para ${item.destiny}?`)) {
        this.$http.put(`/same/${item.id}/transfer`, item).then((response) => {
          this.$notify({
            title: `${item.patient.name}, salvo com sucesso!`,
            type: "success",
            group: "notifications",
            duration: 15000,
          });
          this.getList();
          this.getListHosp();
          this.getListOutros();
        });
      }
    },
    finishOpen(item) {
      this.finishDialog.item = item;
      this.finishDialog.item.same_local = item.patient.same_local
        ? item.patient.same_local
        : "NÃO ENCONTRADO";
      this.finishDialog.open = true;
    },
    finishItem() {
      console.log(this.finishDialog.item);
      if (confirm(`Deseja realmente finalizar?`)) {
        this.$http
          .put(
            `/same/${this.finishDialog.item.id}/finish`,
            this.finishDialog.item
          )
          .then((response) => {
            this.$notify({
              title: `${this.finishDialog.item.patient.name}, salvo com sucesso!`,
              type: "success",
              group: "notifications",
              duration: 15000,
            });

            this.finishDialog.open = false;
            this.getList();
            this.getListHosp();
            this.getListOutros();
          });
      }
    },
    openPrint() {
      window.open(`/same/print_requests?horary=${this.time}`);
    },
    async transferByRh() {
      if (!this.rh && !this.rhauto) return;
      try {
        let rh = this.rh;
        if (this.rhauto) rh = this.rhauto;
        const json = await this.$http
          .put(`/same/transferbyrh/${rh}`, { date: this.time })
          .then((r) => r.data);
        this.$notify({
          title: `${json.name}, salvo com sucesso!`,
          group: "notifications",
          type: "success",
          duration: 15000,
        });
        this.rh = null;
        this.rhauto = null;
        this.getList();
        this.getListHosp();
      } catch (err) {
        console.log(err);
        let msg = `Não foi encontrado este Prontuário`;
        if (err.response && err.response.data && err.response.data.message)
          msg = err.response.data.message;
        this.$notify({
          title: msg,
          group: "notifications",
          type: "error",
        });
      }
    },
    debounceInputRh: _.debounce(function () {
      setTimeout(() => {
        if (this.rhauto.length > 0) {
          this.transferByRh();
        }
      }, 200);
    }, 500),
    debounceInput: _.debounce(function () {
      setTimeout(() => {
        this.getList();
        this.getListHosp();
      }, 200);
    }, 400),
  },
};
</script>
