var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-container',{attrs:{"grid-list-md":"","text-xs-center":"","px-0":""}},[(_vm.$route.params.specialty_id)?_c('v-layout',{attrs:{"row":"","wrap":""}},[_c('v-flex',[_c('v-card',[_c('v-toolbar',{staticClass:"elevation-1",attrs:{"flat":"","color":"white"}},[_c('v-toolbar-title',[_vm._v("Retornos")]),_vm._v(" "),_c('v-divider',{staticClass:"mx-2",attrs:{"inset":"","vertical":""}}),_vm._v("\n            "+_vm._s(_vm.specialty && _vm.specialty.name)+"\n            "),_c('v-divider',{staticClass:"mx-2",attrs:{"inset":"","vertical":""}}),_vm._v(" "),_c('div',[_c('v-menu',{ref:"menu1",attrs:{"close-on-content-click":false,"nudge-right":40,"lazy":"","transition":"scale-transition","offset-y":"","full-width":"","max-width":"290px","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-text-field',_vm._g({attrs:{"label":"Data","type":"date","readonly":"","prepend-icon":"event","loading":_vm.loading},model:{value:(_vm.time),callback:function ($$v) {_vm.time=$$v},expression:"time"}},on))]}}],null,false,2280004356),model:{value:(_vm.menu1),callback:function ($$v) {_vm.menu1=$$v},expression:"menu1"}},[_vm._v(" "),_c('v-date-picker',{attrs:{"no-title":"","locale":"pt"},on:{"input":function($event){_vm.menu1 = false;
                    _vm.getList();}},model:{value:(_vm.time),callback:function ($$v) {_vm.time=$$v},expression:"time"}})],1)],1),_vm._v(" "),_c('v-spacer'),_vm._v(" "),(!_vm.user.modules.includes('CONSULTAS_CONFIRMACAO'))?_c('v-menu',{attrs:{"bottom":"","left":"","close-on-content-click":false,"max-width":"400","min-width":"400","offset-x":""},model:{value:(_vm.menuFilter.menu),callback:function ($$v) {_vm.$set(_vm.menuFilter, "menu", $$v)},expression:"menuFilter.menu"}},[_c('v-btn',{attrs:{"slot":"activator","outline":"","color":"primary"},slot:"activator"},[_vm._v("IMPRIMIR")]),_vm._v(" "),_c('v-card',[_c('v-card-title',{staticClass:"px-1 py-1"},[_vm._v("\n                  IMPRIMIR\n                  "),_c('v-spacer'),_vm._v(" "),_c('v-btn',{attrs:{"outline":"","light":"","small":"","color":"primary"},on:{"click":function($event){_vm.menuFilter.menu = true;
                      _vm.filtro = {};}}},[_vm._v("Limpar")])],1),_vm._v(" "),_c('v-layout',{attrs:{"row":"","wrap":""}},[_c('v-flex',{staticClass:"px-1",attrs:{"xs12":""}},[_c('v-text-field',{attrs:{"type":"date","label":"De"},model:{value:(_vm.filtro.start),callback:function ($$v) {_vm.$set(_vm.filtro, "start", $$v)},expression:"filtro.start"}})],1),_vm._v(" "),_c('v-flex',{staticClass:"px-1",attrs:{"xs12":""}},[_c('v-text-field',{attrs:{"type":"date","label":"Até"},model:{value:(_vm.filtro.end),callback:function ($$v) {_vm.$set(_vm.filtro, "end", $$v)},expression:"filtro.end"}})],1),_vm._v(" "),_c('v-flex',{staticClass:"px-1",attrs:{"xs12":""}},[_c('select-custom',{attrs:{"url":'/users/list?type=3sortBy=name&specialty_id=' +
                          _vm.$route.params.specialty_id,"label":"Médico"},model:{value:(_vm.filtro.doctor_id),callback:function ($$v) {_vm.$set(_vm.filtro, "doctor_id", $$v)},expression:"filtro.doctor_id"}})],1),_vm._v(" "),_c('v-flex',{staticClass:"px-1",attrs:{"xs12":""}},[_c('v-select',{attrs:{"items":[
                        'RESIDÊNCIA',
                        'SAMU',
                        'UBS',
                        'AMBULÂNCIA',
                        'OUTROS'
                      ],"label":"Origem"},model:{value:(_vm.filtro.origin),callback:function ($$v) {_vm.$set(_vm.filtro, "origin", $$v)},expression:"filtro.origin"}})],1),_vm._v(" "),_c('v-flex',{staticClass:"px-1",attrs:{"xs12":""}},[_c('v-select',{attrs:{"items":[
                        { value: null, text: 'Selecionar' },
                        { value: 1, text: 'SIM' },
                        { value: 0, text: 'NÃO' }
                      ],"label":"Presença"},model:{value:(_vm.filtro.arrival),callback:function ($$v) {_vm.$set(_vm.filtro, "arrival", $$v)},expression:"filtro.arrival"}})],1)],1),_vm._v(" "),_c('v-card-actions',[_c('v-spacer'),_vm._v(" "),_c('v-btn',{attrs:{"flat":""},on:{"click":function($event){_vm.menuFilter.menu = false}}},[_vm._v("Fechar")]),_vm._v(" "),_c('v-btn',{attrs:{"color":"primary","flat":""},on:{"click":function($event){return _vm.goReport()}}},[_vm._v("IMPRIMIR")])],1)],1)],1):_vm._e(),_vm._v(" "),(_vm.user.modules.includes('ALTERAR AGENDA'))?_c('v-btn',{staticClass:"mb-2 mr-1",attrs:{"color":"primary","outline":""},on:{"click":function($event){_vm.doctorChangeDateOpen({
                  specialty_id: parseInt(_vm.$route.params.specialty_id),
                  start: _vm.time
                })}}},[_vm._v("Alterar Agenda")]):_vm._e(),_vm._v(" "),(!_vm.user.modules.includes('CONSULTAS_CONFIRMACAO'))?_c('v-btn',{staticClass:"mb-2",attrs:{"color":"primary","dark":""},on:{"click":function($event){_vm.consultationFormOpen({
                  specialty_id: parseInt(_vm.$route.params.specialty_id),
                  return: 1
                })}}},[_vm._v("Novo")]):_vm._e()],1),_vm._v(" "),_c('v-card-text',[_c('table',{staticClass:"table-custom"},[_c('thead',[_c('tr',[_c('th',{staticStyle:{"width":"10%"}},[_vm._v("Horário")]),_vm._v(" "),_c('th',{staticStyle:{"width":"52px"}},[_vm._v("Chegada")]),_vm._v(" "),_c('th',[_vm._v("Nome do Paciente")]),_vm._v(" "),_c('th',{staticStyle:{"width":"200px"}},[_vm._v("Médico")]),_vm._v(" "),_c('th',{staticStyle:{"width":"300px"}},[_vm._v("Status Ligação")]),_vm._v(" "),_c('th',{staticStyle:{"text-align":"right","width":"20%"}})])]),_vm._v(" "),_c('tbody',_vm._l((_vm.times),function(horary,i){return _c('tr',{key:i},[_c('th',{staticStyle:{"vertical-align":"middle","text-align":"center","padding":"5px"}},[_vm._v("\n                    "+_vm._s(horary)+"\n                  ")]),_vm._v(" "),_c('td',{staticStyle:{"padding":"0px"},attrs:{"colspan":"5"}},[_c('table',{staticClass:"table-custom",staticStyle:{"margin":"0px"}},[_c('tbody',_vm._l((_vm.items[horary]),function(item,i){return _c('tr',{key:i,class:{
                            'green lighten-3': item.arrival,
                            'blue lighten-3':
                              !item.arrival &&
                              item.call_confirm &&
                              item.call_confirm.status == 'CONFIRMOU',
                            'red lighten-3':
                              !item.arrival &&
                              item.call_confirm &&
                              item.call_confirm.status == 'DESISTÊNCIA',
                            'yellow lighten-3':
                              !item.arrival &&
                              item.call_confirm &&
                              item.call_confirm.status != 'CONFIRMOU' &&
                              item.call_confirm.status != 'DESISTÊNCIA'
                          }},[_c('td',{staticStyle:{"padding":"5px","width":"52px","vertical-align":"middle","text-align":"center"}},[(
                                !item.arrival &&
                                  !_vm.user.modules.includes(
                                    'CONSULTAS_CONFIRMACAO'
                                  )
                              )?_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.openCall(item)}}},[_vm._v("fas fa-check")]):_vm._e(),_vm._v(" "),(item.arrival && item.arrival_user)?_c('v-tooltip',{attrs:{"top":""}},[_c('span',{attrs:{"slot":"activator"},slot:"activator"},[_vm._v(_vm._s(item.arrival))]),_vm._v(" "),_c('span',[_vm._v("Marcado por:\n                                "+_vm._s(item.arrival_user.name))])]):_vm._e(),_vm._v(" "),(item.arrival && !item.arrival_user)?_c('span',[_vm._v(_vm._s(item.arrival))]):_vm._e()],1),_vm._v(" "),_c('td',{staticStyle:{"padding":"5px","vertical-align":"middle"}},[_vm._v("\n                            "+_vm._s(item.patient.name)+"\n                            "),(
                                !_vm.user.modules.includes(
                                  'CONSULTAS_CONFIRMACAO'
                                )
                              )?_c('v-icon',{staticClass:"mr-2",attrs:{"color":!item.patient.rg || !item.patient.address_id
                                  ? 'red'
                                  : 'grey',"small":""},on:{"click":function($event){return _vm.patientFormOpen(item.patient)}}},[_vm._v("edit")]):_vm._e()],1),_vm._v(" "),_c('td',{staticStyle:{"width":"200px","padding":"5px","vertical-align":"middle"}},[_vm._v("\n                            "+_vm._s(item.doctor ? item.doctor.name : "")+"\n                          ")]),_vm._v(" "),_c('td',{staticStyle:{"width":"300px","padding":"5px","vertical-align":"middle"}},[(item.call_confirm)?_c('div',[_vm._v("\n                              "+_vm._s(item.call_confirm.status)+" /\n                              "+_vm._s(_vm._f("formatDateTimeShort")(item.call_confirm.created_at))+"\n                              / "+_vm._s(item.call_confirm.contact_name)+"\n                            ")]):_vm._e()]),_vm._v(" "),_c('td',{staticStyle:{"text-align":"right","width":"22.5%","padding":"5px"}},[(
                                !_vm.user.modules.includes(
                                  'CONSULTAS_CONFIRMACAO'
                                )
                              )?_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.consultationFormOpen(item)}}},[_vm._v("edit")]):_vm._e(),_vm._v(" "),(
                                !_vm.user.modules.includes(
                                  'CONSULTAS_CONFIRMACAO'
                                ) &&
                                  ((!item.arrival &&
                                    (_vm.isToday || _vm.isThreeDays)) ||
                                    _vm.user.modules.includes('EXCLUIR CONSULTA'))
                              )?_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.del(item.id)}}},[_vm._v("delete")]):_vm._e(),_vm._v(" "),(
                                _vm.user.modules.includes('CONSULTAS_CONFIRMACAO')
                              )?_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.confirmItemOpen(item)}}},[_vm._v("call")]):_vm._e()],1)])}),0)])])])}),0)])])],1)],1)],1):_c('v-layout',{attrs:{"row":"","wrap":""}},[_c('v-flex',{attrs:{"xs12":"","sm6":""}},[_c('v-card',[_c('v-toolbar',{staticClass:"elevation-1",attrs:{"flat":"","color":"white"}},[_c('v-toolbar-title',[_vm._v("Especialidade")])],1),_vm._v(" "),_c('v-card-text',[_c('v-layout',{attrs:{"row":"","wrap":""}},_vm._l((_vm.specialties),function(item,i){return _c('v-flex',{key:i,attrs:{"xs12":"","sm6":""}},[_c('v-list-tile',{attrs:{"exact":"","to":`/consultations_return/${item.id}`}},[_c('v-list-tile-content',[_c('v-list-tile-title',{domProps:{"textContent":_vm._s(item.name)}})],1)],1)],1)}),1)],1)],1)],1)],1)],1),_vm._v(" "),(_vm.consultationFormActive.open)?_c('div',[_c('consultation-form',{attrs:{"active":_vm.consultationFormActive.open,"item":_vm.consultationFormActive.item,"finish":_vm.consultationFormClose}})],1):_vm._e(),_vm._v(" "),(_vm.patientFormActive.open)?_c('div',[_c('patient-form',{attrs:{"active":_vm.patientFormActive.open,"item":_vm.patientFormActive.item,"finish":_vm.patientFormClose}})],1):_vm._e(),_vm._v(" "),(_vm.callActive.open)?_c('div',[_c('call-consulta',{attrs:{"active":_vm.callActive.open,"item":_vm.callActive.item,"finish":_vm.onCloseCallActive}})],1):_vm._e(),_vm._v(" "),(_vm.consultationPrint.open)?_c('div',[_c('consultation-print',{attrs:{"active":_vm.consultationPrint.open,"item":_vm.consultationPrint.item,"finish":_vm.consultationPrintClose}})],1):_vm._e(),_vm._v(" "),(_vm.doctorChangeDate.open)?_c('div',[_c('doctor-change-date',{attrs:{"active":_vm.doctorChangeDate.open,"item":_vm.doctorChangeDate.item,"finish":_vm.doctorChangeDateClose}})],1):_vm._e(),_vm._v(" "),(_vm.confirmItem.open)?_c('div',[_c('consultation-confirm',{attrs:{"active":_vm.confirmItem.open,"item":_vm.confirmItem.item,"finish":_vm.confirmItemClose}})],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }