<template>
  <div>
    <v-container grid-list-md text-xs-center px-0>
      <notifications position="bottom center" />
      <v-layout row wrap>
        <v-flex xs12>
          <v-toolbar flat color="white" class="elevation-1">
            <v-toolbar-title>Operadores</v-toolbar-title>
            <v-divider class="mx-2" inset vertical></v-divider>
            <v-spacer></v-spacer>
            <v-text-field
              v-model.lazy="search"
              append-icon="search"
              label="Buscar"
              single-line
              hide-details
              class="hidden-md-and-down"
            ></v-text-field>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              dark
              class="mb-2"
              @click="openOperatorForm({})"
              >Novo</v-btn
            >
          </v-toolbar>

          <v-data-table
            :headers="columns"
            :items="items"
            :pagination.sync="pagination"
            :total-items="pagination.totalItems"
            :loading="loading"
            hide-actions
            class="elevation-1"
          >
            <template slot="items" slot-scope="props">
              <tr>
                <td>{{ props.item.id }}</td>
                <td>{{ props.item.name }}</td>
                <td>{{ props.item.email }}</td>
                <td>{{ props.item.unit.name }}</td>
                <td>{{ getType(props.item.type) }}</td>

                <td class="text-xs-center px-0">
                  <v-icon small @click="openOperatorForm(props.item)"
                    >edit</v-icon
                  >
                  <v-icon small @click="del(props.item.id)">delete</v-icon>
                </td>
              </tr>
            </template>

            <template slot="no-data"
              >Desculpe, nenhum dado para ser exibido!</template
            >
          </v-data-table>
          <div class="text-xs-center pt-2">
            <v-pagination
              v-model="pagination.page"
              :length="pages"
            ></v-pagination>
          </div>
        </v-flex>
      </v-layout>

      <div v-if="operatorFormActive.open">
        <operator-form
          :active="operatorFormActive.open"
          :item="operatorFormActive.item"
          :finish="onCloseOperatorForm"
        ></operator-form>
      </div>
    </v-container>
  </div>
</template>

<script>
import _ from "lodash";
import { maskJs } from "mask-js";
export default {
  data() {
    return {
      valid: false,
      search: "",
      pagination: {
        totalItems: 0,
        rowsPerPage: 10,
        descending: false,
        page: 1,
        sortBy: "id"
      },
      items: [],
      columns: [
        { value: "id", text: "ID" },
        { value: "name", text: "Nome" },
        { value: "email", text: "Email" },
        { value: "unit_id", text: "Unidade" },
        { value: "type", text: "Nivel" },
        { value: "", text: "", sortable: false }
      ],
      operatorFormActive: {
        open: false,
        item: {}
      },
      operatorViewActive: { open: false },
      loading: false,
      dialogOperatorTo: false,
      types: [
        { text: "Administrador", value: 1 },
        { text: "Atendimento", value: 2 },
        { text: "Equipe Médica", value: 3 },
        { text: "Equipe Enfermagem", value: 4 },
        { text: "Almoxarifado", value: 5 },
        { text: "Financeiro", value: 6 },
        { text: "Suporte", value: 7 },
        { text: "Compras", value: 8 },
        { text: "Jurídico", value: 9 },
        { text: "Supervisor de atendimento", value: 110 },
        { text: "Faturamento", value: 600 }
      ]
    };
  },
  computed: {
    pages() {
      if (
        this.pagination.rowsPerPage == null ||
        this.pagination.totalItems == null
      )
        return 0;

      return Math.ceil(
        this.pagination.totalItems / this.pagination.rowsPerPage
      );
    }
  },
  watch: {
    "pagination.page": {
      handler() {
        this.getList();
      },
      deep: true
    },
    search: function() {
      this.debounceInput();
    }
  },
  created() {
    this.initialize();
  },
  methods: {
    initialize() {},
    getList() {
      this.loading = true;
      return new Promise((resolve, reject) => {
        const params = {
          ...this.pagination,
          totalItems: undefined,
          search: this.search
        };
        this.$http
          .get(`/users/list`, { params })
          .then(response => response.data)
          .then(json => {
            setTimeout(() => {
              this.loading = false;

              this.items = json.items;
              this.pagination.totalItems = json.total;
            }, 1000);
          })
          .catch(() => (this.loading = false));
      });
    },
    del(id) {
      if (confirm("Deseja realmente excluir este usuário?")) {
        this.$http.put(`/users/del/${id}`).then(response => {
          this.$notify({
            title: `Usuário Excluido!`,
            type: "success"
          });
          this.getList();
        });
      }
    },
    debounceInput: _.debounce(function() {
      setTimeout(() => {
        this.getList();
      }, 200);
    }, 400),
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${month}/${day}/${year}`;
    },
    formatPhone(p) {
      if (!p) return null;
      return maskJs("(99) 9999?9-9999", p);
    },
    openOperatorForm(item) {
      this.operatorFormActive.open = true;
      this.operatorFormActive.item = item;
    },
    onCloseOperatorForm(operator) {
      this.operatorFormActive.open = false;
      this.getList();
    },
    getType(i) {
      return this.types.find(o => {
        if (o.value == i) return o.value.text;
        return "";
      });

      // return this.types.find(o => o.value == i).text || "";
    }
  }
};
</script>
