<template>
  <v-container grid-list-md text-xs-center px-0>
    <notifications position="bottom center" />
    <v-layout row wrap>
      <v-flex xs12>
        <v-toolbar flat color="white" class="elevation-1">
          <v-toolbar-title>Relatório de Compra de Produtos</v-toolbar-title>
          <v-divider class="mx-2" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <v-text-field
            v-model.lazy="search"
            append-icon="search"
            label="Buscar"
            single-line
            hide-details
            class="hidden-md-and-down"
            @click:append="getList()"
              v-on:keyup.enter="getList"
          ></v-text-field>
          <v-spacer></v-spacer>
        </v-toolbar>

        <v-data-table
          :headers="columns"
          :items="items"
          :pagination.sync="pagination"
          :total-items="pagination.totalItems"
          :loading="loading"
          hide-actions
          class="elevation-1"
        >
          <template slot="items" slot-scope="props">
            <tr>
              <td>{{ props.item.id }}</td>
              <td>{{ props.item.name }}</td>
              <td>{{ props.item.qty }}</td>
              <td>{{ props.item.qty_min }}</td>
              <td>
                <v-layout
                  row
                  wrap
                  v-for="(item, i) in props.item.last_prices"
                  :key="i"
                  class="text-md-center"
                >
                  <v-flex xs4>{{item.grade && item.grade.provider}}</v-flex>
                  <v-flex xs4>R$ {{item.price_unit | moeda}}</v-flex>
                  <v-flex xs4>{{item.qty}} Un. / R$ {{item.price_all | moeda}}</v-flex>
                </v-layout>
              </td>
              <td>R$ {{ priceMid(props.item.last_prices) }}</td>
            </tr>
          </template>

          <template slot="no-data">Desculpe, nenhum dado para ser exibido!</template>
        </v-data-table>
        <div class="text-xs-center pt-2">
          <v-pagination v-model="pagination.page" :length="pages"></v-pagination>
        </div>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import _ from "lodash";
export default {
  data() {
    return {
      search: "",
      pagination: {
        totalItems: 0,
        rowsPerPage: 10,
        descending: false,
        page: 1,
        sortBy: "id",
      },
      items: [],
      estoques: [],
      columns: [
        { value: "id", text: "ID" },
        { value: "name", text: "Nome" },
        { value: "qty", text: "Estoque" },
        { value: "qty_min", text: "Qtd. Mínima" },
        {
          value: "last_prices",
          text: "Últimas Compras",
          sortable: false,
          align: "center",
        },
        { value: "mid", text: "Preço Un. Média", sortable: false },
      ],
      loading: false,
    };
  },
  computed: {
    pages() {
      if (
        this.pagination.rowsPerPage == null ||
        this.pagination.totalItems == null
      )
        return 0;

      return Math.ceil(
        this.pagination.totalItems / this.pagination.rowsPerPage
      );
    },
  },
  watch: {
    "pagination.page": {
      handler() {
        this.getList();
      },
      deep: true,
    },
    "$route.params.type": {
      handler() {
        this.getList();
      },
    },
  },
  created() {
    this.initialize();
  },
  methods: {
    initialize() {},
    getList() {
      this.loading = true;
      return new Promise((resolve, reject) => {
        const params = {
          ...this.pagination,
          totalItems: undefined,
          search: this.search,
        };
        this.$http
          .get(`/products/reportprices/${this.$route.params.type}`, { params })
          .then((response) => response.data)
          .then((json) => {
            setTimeout(() => {
              this.loading = false;

              this.items = json.items;
              this.pagination.totalItems = json.total;
            }, 1000);
          })
          .catch(() => (this.loading = false));
      });
    },
    priceMid(arr) {
      if (!arr) return 0;
      let mid = 0;
      arr.forEach((obj) => {
        mid += parseFloat(obj.price_unit) / arr.length;
      });

      return mid.toFixed(2).replace(".", ",");
    },
    debounceInput: _.debounce(function () {
      setTimeout(() => {
        this.getList();
      }, 200);
    }, 400),
  },
  filters: {
    moeda: function (value) {
      if (!value) value = "0,00";
      return value.replace(".", ",");
    },
  },
};
</script>
