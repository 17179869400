<template>
  <div>
    <!---->
    <v-container grid-list-md text-xs-center px-0>
      <notifications position="bottom center" />
      <v-layout row wrap>
        <v-flex xs12>
          <v-toolbar flat color="white" class="elevation-1">
            <v-toolbar-title>Saídas</v-toolbar-title>

            <v-divider class="mx-2" inset vertical></v-divider>
            de {{ filtro.start | formatDate }} até {{ filtro.end | formatDate }}
            <v-spacer></v-spacer>
            <v-menu
              v-model="filtro.menu"
              bottom
              left
              :close-on-content-click="false"
              max-width="400"
              min-width="400"
              offset-x
            >
              <v-btn outline slot="activator" color="primary">OPÇÕES</v-btn>

              <v-card>
                <v-card-title class="px-1 py-1">
                  OPÇÕES
                  <v-spacer></v-spacer>
                  <v-btn
                    outline
                    light
                    small
                    color="primary"
                    @click="
                      filtro.menu = true;
                      filtro = {};
                    "
                    >Limpar</v-btn
                  >
                </v-card-title>

                <v-layout row wrap>
                  <v-flex xs12 class="px-1">
                    <v-text-field
                      v-model="filtro.start"
                      type="date"
                      label="De"
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 class="px-1">
                    <v-text-field
                      v-model="filtro.end"
                      type="date"
                      label="Até"
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 class="px-1">
                    <select-custom
                      :url="`/products/list_select?sortBy=name&office=${$route.params.office}`"
                      v-model="filtro.product_id"
                      label="Produto"
                    />
                  </v-flex>
                  <v-flex xs12 class="px-1">
                    <v-select
                      v-model="filtro.type"
                      :items="destinys"
                      label="Destino"
                    ></v-select>
                  </v-flex>
                  <v-flex xs12 class="px-1">
                    <select-custom
                      :url="`/sectors/listall?sortBy=name&office=${$route.params.office}`"
                      v-model="filtro.sector_id"
                      label="Setor"
                    />
                  </v-flex>
                </v-layout>

                <v-card-actions>
                  <v-spacer></v-spacer>

                  <v-btn flat @click="filtro.menu = false">Fechar</v-btn>
                  <v-btn color="primary" flat @click="goReport()"
                    >IMPRIMIR</v-btn
                  >
                  <v-btn color="primary" @click="filtrar()">FILTRAR</v-btn>
                </v-card-actions>
              </v-card>
            </v-menu>
            <v-btn
              color="primary"
              dark
              class="mb-2"
              @click="openRetiradaForm({ office: $route.params.office })"
              >Nova</v-btn
            >
          </v-toolbar>

          <v-data-table
            :headers="columns"
            :items="items"
            :pagination.sync="pagination"
            :total-items="pagination.totalItems"
            :loading="loading"
            hide-actions
            class="elevation-1"
          >
            <template slot="items" slot-scope="props">
              <tr>
                <td>{{ props.item.id }}</td>
                <td>{{ props.item.user && props.item.user.name }}</td>
                <td>{{ props.item.sector && props.item.sector.name }}</td>
                <td>
                  {{ props.item.type | TypeRetirada() }}
                  <span v-if="props.item.type == 5"
                    >- {{ props.item.sector_destiny }}</span
                  >

                  <span v-if="props.item.type == 6"
                    >- {{ props.item.miss_type }}</span
                  >
                </td>
                <td>
                  <span v-for="(value, i) in props.item.products" :key="i"
                    >{{ i }}({{ value }});</span
                  >
                </td>
                <td>{{ props.item.obs }}</td>
                <td>{{ props.item.created_at | formatDateTime }}</td>
                <td>{{ props.item.loan ? "SIM" : "NÃO" }}</td>
                <td class="text-xs-center px-0">
                  <v-icon
                    small
                    class="mr-2"
                    v-if="
                      !props.item.nursing_id &&
                      props.item.call &&
                      (props.item.call.medication_type ==
                        'Medicação Observação' ||
                        props.item.call.medication_type == 'Medicação Retorno')
                    "
                    @click="openNursing(props.item)"
                    >fas fa-capsules</v-icon
                  >

                  <v-icon small @click="openRetiradaView(props.item)"
                    >fas fa-eye</v-icon
                  >
                  <!--<v-icon small @click="del(props.item.id)">
									delete
                  </v-icon>-->
                </td>
              </tr>
            </template>

            <template slot="no-data"
              >Desculpe, nenhum dado para ser exibido!</template
            >
          </v-data-table>
          <div class="text-xs-center pt-2">
            <v-pagination
              v-model="pagination.page"
              :length="pages"
            ></v-pagination>
          </div>
        </v-flex>
      </v-layout>
    </v-container>

    <div v-if="retiradaFormActive.open">
      <retirada-form
        :active="retiradaFormActive.open"
        :item="retiradaFormActive.item"
        :finish="onCloseRetiradaForm"
      ></retirada-form>
    </div>

    <div v-if="retiradaViewActive.open">
      <retirada-view
        :active="retiradaViewActive.open"
        :item="retiradaViewActive.item"
        :finish="onCloseRetiradaView"
      ></retirada-view>
    </div>

    <v-dialog
      v-model="dialogNursing.open"
      scrollable
      max-width="400px"
      persistent
    >
      <v-card>
        <v-card-title>
          <span class="headline">Medicamento</span>
        </v-card-title>
        <v-card-text>
          <v-layout row wrap v-if="dialogNursing.item.patient">
            <v-flex xs12>
              <div v-if="dialogNursing.item.patient.gender == 'F'">
                A paciente
                <b>{{ dialogNursing.item.patient.name }}</b> tomou todo
                medicamento prescrito pelo Médico?
              </div>
              <div v-else>
                O paciente
                <b>{{ dialogNursing.item.patient.name }}</b> tomou todo
                medicamento prescrito pelo Médico?
              </div>
            </v-flex>
            <v-flex xs12 v-if="dialogNursing.item.call" class="mt-4">
              <b>Tipo de Medicação:</b>
              {{ dialogNursing.item.call.medication_type }}
              <br />
              <div
                v-if="
                  dialogNursing.item.call.medication_type ==
                  'Medicação Observação'
                "
              >
                <b>Horário de retorno:</b>
                {{ horary(dialogNursing.item.call) }}
              </div>
            </v-flex>
          </v-layout>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" flat @click="onCloseNursing">Não</v-btn>
          <v-btn color="primary" @click="saveNursing">SIM</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import _ from "lodash";
import moment from "moment";
export default {
  data() {
    return {
      search: "",
      pagination: {
        totalItems: 0,
        rowsPerPage: 10,
        descending: false,
        page: 1,
        sortBy: "id",
      },
      items: [],
      columns: [
        { value: "id", text: "ID" },
        { value: "user", text: "Usuário", sortable: false },
        { value: "sector", text: "Estoque", sortable: false },
        { value: "type", text: "Tipo" },
        { value: "products", text: "Remédios", sortable: false },
        { value: "obs", text: "Obs" },
        { value: "created_at", text: "Data" },
        { value: "loan", text: "Emprestimo" },
        { value: "", text: "", sortable: false },
      ],
      retiradaFormActive: {
        open: false,
        item: {},
      },
      destinys: [
        { value: 1, text: "Atendimento" },
        { value: 2, text: "Internação" },
        { value: 4, text: "Emprestimo" },
        { value: 5, text: "Setor" },
        { value: 6, text: "Perca" },
        { value: 3, text: "Outros" },
      ],
      retiradaViewActive: { open: false, item: {} },
      loading: false,
      dialogProductTo: false,
      dialogNursing: { open: false, item: {} },
      filtro: {
        menu: false,
        type: null,
        sector_id: null,
        product_id: null,
        start: moment().startOf("month").format("YYYY-MM-DD"),
        end: moment().endOf("month").format("YYYY-MM-DD"),
      },
    };
  },
  computed: {
    pages() {
      if (
        this.pagination.rowsPerPage == null ||
        this.pagination.totalItems == null
      )
        return 0;

      return Math.ceil(
        this.pagination.totalItems / this.pagination.rowsPerPage
      );
    },
  },
  watch: {
    "pagination.page": {
      handler() {
        this.getList();
      },
      deep: true,
    },
    search: function () {
      this.debounceInput();
    },
  },
  created() {
    this.initialize();
  },
  filters: {
    TypeRetirada: function (value) {
      switch (value) {
        case 1:
          return "Atendimento";
        case 2:
          return "Internação";
        case 3:
          return "Outros";
        case 4:
          return "Emprestimo";
        case 6:
          return "Perca";
        default:
          return "";
      }
    },
  },
  methods: {
    initialize() {},
    getList() {
      this.loading = true;
      return new Promise((resolve, reject) => {
        const params = {
          ...this.pagination,
          ...this.filtro,
          totalItems: undefined,
          menu: undefined,
          search: this.search,
          office: this.$route.params.office,
        };
        this.$http
          .get(`/retirada_packs/list`, { params })
          .then((response) => response.data)
          .then((json) => {
            setTimeout(() => {
              this.loading = false;

              this.items = json.items.map((o) => {
                var counts = o.retiradas.reduce((p, c) => {
                  var name = c.product.name;
                  if (!p.hasOwnProperty(name)) {
                    p[name] = 0;
                  }
                  p[name]++;
                  return p;
                }, {});

                return {
                  ...o,
                  products: counts,
                };
              });
              this.pagination.totalItems = json.total;
            }, 1000);
          })
          .catch(() => (this.loading = false));
      });
    },
    del(id) {},
    debounceInput: _.debounce(function () {
      setTimeout(() => {
        this.getList();
      }, 200);
    }, 400),
    openRetiradaForm(item) {
      this.retiradaFormActive.open = true;
      this.retiradaFormActive.item = item;
    },
    onCloseRetiradaForm() {
      this.retiradaFormActive.open = false;
      this.getList();
    },
    openRetiradaView(item) {
      this.retiradaViewActive.open = true;
      this.retiradaViewActive.item = item;
    },
    onCloseRetiradaView() {
      this.retiradaViewActive.open = false;
    },
    openNursing(item) {
      this.dialogNursing.open = true;
      this.dialogNursing.item = item;
    },
    onCloseNursing() {
      this.dialogNursing.open = false;
    },
    saveNursing() {
      this.dialogNursing.item.nursing_id = USER.id;
      this.$http
        .put(
          `/retiradas/${this.dialogNursing.item.id}/nursing`,
          this.dialogNursing.item
        )
        .then((response) => {
          this.$notify({
            title: `Medicamento salvo com sucesso!`,
            type: "success",
            group: "notifications",
          });
          this.onCloseNursing();
          this.getList();
        });
    },
    horary(call) {
      return moment(call.doctor_horary)
        .add(call.return_minutes, "minutes")
        .format("DD/MM/YYYY HH:mm");
    },
    filtrar() {
      this.filtro.menu = false;
      this.getList();
    },
  },
};
</script>
