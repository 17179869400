<template>
  <div>
    <dialog-loader :active="loading" />
    <v-container grid-list-md px-0>
      <v-card>
        <v-card-title>
          <span class="headline">GERAR RELATÓRIO - EXCEL</span>
        </v-card-title>

        <v-card-text class="pt-0">
          <v-container grid-list-md class="pt-0">
            <v-form v-model="valid" xs12 sm12 md12>
              <v-layout row wrap>
                <v-flex xs12 sm4>
                  <v-text-field
                    v-model="editedItem.year"
                    required
                    type="number"
                    label="Ano"
                    :rules="[v => !!v || 'Ano é obrigatório!']"
                  />
                </v-flex>

                <v-flex xs12 sm4>
                  <v-select
                    :items="months"
                    label="Mês"
                    v-model="editedItem.month"
                  ></v-select>
                </v-flex>

                <v-flex xs12 sm4>
                  <v-select
                    :items="reports"
                    label="Tipo do relatório"
                    item-text="label"
                    item-value="url"
                    v-model="editedItem.type"
                    required
                    :rules="[v => !!v || 'Tipo do relatório é obrigatório!']"
                  ></v-select>
                </v-flex>

                <v-flex xs12 sm4>
                  <v-select
                    :items="bill_type_id"
                    label="Centro de custo"
                    v-model="editedItem.bill_type_id"
                    v-show="showResumoFields"
                  ></v-select>
                </v-flex>

                <v-flex xs12 sm4>
                  <v-select
                    :items="bill_type_id"
                    label="Centro de custo"
                    v-model="editedItem.bill_type_id"
                    v-show="showResumoDetalhesFields"
                  ></v-select>
                </v-flex>

                <v-flex xs12 sm4>
                  <v-select
                    :items="bill_type_id"
                    label="Centro de custo"
                    v-model="editedItem.bill_type_id"
                    v-show="showResumo2DetalhesFields"
                  ></v-select>
                </v-flex>

                <v-flex xs12 sm4>
                  <v-autocomplete
                    v-model="editedItem.client"
                    :items="dados"
                    item-text="name"
                    :search-input.sync="search"
                    item-value="id"
                    label="Cliente Fornecedor"
                    v-show="showClienteFornecedorFields"
                    :loading="loadingAutocomplete"
                  ></v-autocomplete>
                </v-flex>

                <v-flex xs12 class="text-xs-right">
                  <v-btn color="primary" @click="getList()" :disabled="!valid">
                    <v-icon dark>far fa-file-excel</v-icon>&nbsp; GERAR
                    RELATÓRIO
                  </v-btn>
                </v-flex>
              </v-layout>
            </v-form>
          </v-container>
        </v-card-text>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import moment from "moment";
import { Money } from "v-money";
export default {
  components: { Money },
  data() {
    return {
      dados: [],
      valid: false,
      showResumoFields: false,
      showResumoDetalhesFields: false,
      showResumo2DetalhesFields: false,
      showClienteFornecedorFields: false,
      year: moment().format("YYYY"),
      items: [],
      totais: {
        mark: 0,
        m01: 0,
        m02: 0,
        m03: 0,
        m04: 0,
        m05: 0,
        m06: 0,
        m07: 0,
        m08: 0,
        m09: 0,
        m010: 0,
        m011: 0,
        m012: 0
      },
      reports: [
        {
          url: "/excel/previsto-realizado",
          label: "Previsto vs Realizado"
        },
        {
          url: "/excel/resumo",
          label: "Resumo"
        },
        {
          url: "/excel/resumo-detalhado",
          label: "Resumo Detalhado"
        },
        {
          url: "/excel/resumo-detalhado-relatorio2",
          label: "Resumo Detalhado Relatório 2"
        },
        {
          url: "/excel/cliente-fornecedor",
          label: "Cliente Fornecedor"
        },
        {
          url: "/excel/receber-pagar",
          label: "A Receber A Pagar"
        }
      ],
      months: ["", 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
      client: [
        "BANCO DO BRASIL",
        "MINISTÉRIO DA FAZENDA",
        "CLEAN MEDICAL MANUTENÇÃO E ASSIST TEC EQUIP MEDICO HOSPIT LTDA"
      ],
      bill_type_id: [
        // "Despesa",
        // "PREFEITURA DO MUNICÍPIO DE GUARULHOS",
        // "Transferência",
        "Todos"
      ],
      editedItem: {
        year: moment().format("YYYY"),
        month: "",
        type: null,
        despesa: null
      },
      loading: false,
      loadingAutocomplete: false,
      search: "",
      pagination: {
        totalItems: 0,
        rowsPerPage: 10,
        descending: true,
        page: 1,
        sortBy: "id"
      },
      result: null
    };
  },
  created() {
    this.initialize();
    //
  },
  watch: {
    "editedItem.type": function(newValue, oldValue) {
      // console.log("mudado tipo de relatorio", newValue, oldValue);
      this.checkAditionalFields();
    },
    search: function() {
      this.loadProviders();
    }
  },
  methods: {
    initialize() {
      this.loadProviders();
    },
    checkAditionalFields() {
      switch (this.editedItem.type) {
        case "/excel/resumo":
          this.showResumoFields = true;
          this.showResumoDetalhesFields = false;
          this.showResumo2DetalhesFields = false;
          this.showClienteFornecedorFields = false;
          break;
        case "/excel/resumo-detalhado":
          this.showResumoFields = false;
          this.showResumoDetalhesFields = true;
          this.showResumo2DetalhesFields = false;
          this.showClienteFornecedorFields = false;
          break;
        case "/excel/resumo-detalhado-relatorio2":
          this.showResumoFields = false;
          this.showResumoDetalhesFields = false;
          this.showResumo2DetalhesFields = true;
          this.showClienteFornecedorFields = false;
          break;
        case "/excel/cliente-fornecedor":
          this.showResumoFields = false;
          this.showResumoDetalhesFields = false;
          this.showResumo2DetalhesFields = false;
          this.showClienteFornecedorFields = true;
          break;
        case "/excel/previsto-realizado":
          this.showResumoFields = false;
          this.showResumoDetalhesFields = false;
          this.showResumo2DetalhesFields = false;
          this.showClienteFornecedorFields = false;
          break;
        case "/excel/receber-pagar":
          this.showResumoFields = false;
          this.showResumoDetalhesFields = false;
          this.showResumo2DetalhesFields = false;
          this.showClienteFornecedorFields = false;
          break;
        default:
          this.showResumoFields = false;
          break;
      }
    },
    loadProviders() {
      this.loadingAutocomplete = true;
      const params = {
        ...this.pagination,
        totalItems: undefined,
        search: this.search
      };
      axios
        .get(`/providers/list`, { params })
        .then(resp => resp.data.items)
        .then(json => {
          this.loadingAutocomplete = false;
          // console.log(json),
          this.dados = json;
        })
        .catch(err => {
          this.loadingAutocomplete = false;
          this.$reqError(`Erro ao salvar Plantão!`, err, this);
        });

      setTimeout(() => {
        //        console.log(this.dados);
      }, 500);
    },
    async getList() {
      this.loading = true;
      await this.$http
        .get(`/bill_types/marks?year=${this.editedItem.year}`)
        .then(response => response.data)
        .then(json => {
          setTimeout(() => {
            this.loading = false;
            this.totais = {
              mark: 0,
              m01: 0,
              m02: 0,
              m03: 0,
              m04: 0,
              m05: 0,
              m06: 0,
              m07: 0,
              m08: 0,
              m09: 0,
              m10: 0,
              m11: 0,
              m12: 0
            };

            this.items = json.items;

            this.items.forEach((item, index) => {
              this.totais.mark += parseFloat(item.mark);
              this.totais.m01 += parseFloat(item.m01);
              this.totais.m02 += parseFloat(item.m02);
              this.totais.m03 += parseFloat(item.m03);
              this.totais.m04 += parseFloat(item.m04);
              this.totais.m05 += parseFloat(item.m05);
              this.totais.m06 += parseFloat(item.m06);
              this.totais.m07 += parseFloat(item.m07);
              this.totais.m08 += parseFloat(item.m08);
              this.totais.m09 += parseFloat(item.m09);
              this.totais.m10 += parseFloat(item.m10);
              this.totais.m11 += parseFloat(item.m11);
              this.totais.m12 += parseFloat(item.m12);
              // console.log("mark then", this.totais.mark);
              if (index == this.items.length - 1) {
                this.goPlan();
              }
            });
          }, 1000);
        })
        .catch(() => (this.loading = false));
    },
    async goPlan() {
      // console.log(this.editedItem.type);

      if (this.editedItem.type == "/excel/previsto-realizado") {
        this.editedItem.despesa = this.totais.mark;
        const queryString = new URLSearchParams(this.editedItem).toString();
        const url = `${this.editedItem.type}?${queryString}`;
        // console.log("to aqui", this.totais.mark);
        // console.log(this.editedItem);
        // window.open(url);
      }

      const queryString = new URLSearchParams(this.editedItem).toString();
      const url = `${this.editedItem.type}?${queryString}`;
      // console.log("Send to URL", url);
      window.open(url);
    }
  }
};
</script>
